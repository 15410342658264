import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import AUX from '../../../hoc/Aux_';
import orderData from '../../../helper/sampledata/orders';
import customFunctions from '../../../helper/customFunctions';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import './orderView.css';

class OrderView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderDetails: {},
      changeStatus: false,
      orderStatus: '',
      newDate: null,

    };
  }

  componentDidMount = async () => {
    const { orderDetails } = this.state
    customFunctions.setTitle('Order details')
    const orderID = this.props.match.params.orderId;
    if (orderID) {
      const { data } = await AuthApi.getDataFromServer(`${Api.getShipmentDetails}/${orderID}`);
      if (data && data.status !== "Fail") {
        await this.setState({ orderDetails: data.data[0] });
      } else {
        this.props.history.push('/orders');
      }
      // const orderDetails = orderData.filter((order) => { return order.id === parseInt(orderID) });
      // if (orderDetails.length) {
      //   this.setState({ orderDetails: orderDetails[0], orderStatus: orderDetails[0].status });
      // } else {
      //   this.props.history.push('/orders');
      // }
    } else {
      this.props.history.push('/orders');
    }
  }

  changeOrderStatus = async (e) => {
    e.preventDefault();
    this.setState({ changeStatus: false })
  }

  getDate = (unixDate) => {
    if (!unixDate) return '';
    return customFunctions.getFormatedDate(unixDate);
  }

  render() {
    const { orderDetails, changeStatus, orderStatus, changedDate } = this.state;
    return (
      <AUX>
        <div className="page-content-wrapper order-detail-view">
          <div className="container-fluid">
            <div className="row main-block">
              <div className="col-6">
                <div className="card">
                  <div className="card-header">
                    Order Details
                    </div>
                  <div className="card-body">
                    <div className="row">
                      <label for="example-text-input" className="col-sm-4 col-form-label">Order Id</label>
                      <div className="col-sm-8">
                        <p>{orderDetails && orderDetails.shipmentid}</p>
                      </div>
                    </div>
                    <div className="row">
                      <label for="example-text-input" className="col-sm-4 col-form-label">AWB Number</label>
                      <div className="col-sm-8">
                        <p>{orderDetails && orderDetails.awbnumber}</p>
                      </div>
                    </div>
                    <div className="row">
                      <label for="example-search-input" className="col-sm-4 col-form-label">Merchant</label>
                      <div className="col-sm-8">
                        <p>{orderDetails && orderDetails.storename}</p>
                      </div>
                    </div>
                    <div className="row">
                      <label for="example-search-input" className="col-sm-4 col-form-label">Merchant No</label>
                      <div className="col-sm-8">
                        <p>{orderDetails && orderDetails.vendornumber}</p>
                      </div>
                    </div>
                    <div className="row">
                      <label for="example-search-input" className="col-sm-4 col-form-label">Store order Id</label>
                      <div className="col-sm-8">
                        <p>{orderDetails && orderDetails.storeorderid}</p>
                      </div>
                    </div>
                    <div className="row">
                      <label for="example-search-input" className="col-sm-4 col-form-label">Created By</label>
                      <div className="col-sm-8">
                        <p>{orderDetails && orderDetails.orderfirstname}&nbsp;{orderDetails.orderlastname}</p>
                      </div>
                    </div>
                    <div className="row">
                      <label for="example-search-input" className="col-sm-4 col-form-label">Email</label>
                      <div className="col-sm-8">
                        <p>{orderDetails && orderDetails.ordermail}</p>
                      </div>
                    </div>
                    <div className="row">
                      <label for="example-search-input" className="col-sm-4 col-form-label">Mobile Number</label>
                      <div className="col-sm-8">
                        <p>{orderDetails && orderDetails.ordermobile}</p>
                      </div>
                    </div>

                    <div className="row">
                      <label for="example-search-input" className="col-sm-4 col-form-label">Created Date</label>
                      <div className="col-sm-8">
                        <p>{orderDetails && this.getDate(orderDetails.createddate)}</p>
                      </div>
                    </div>
                    {
                      (orderDetails && orderDetails.invoicelink) && (
                        <div className="row">
                          <label for="example-search-input" className="col-sm-4 col-form-label">Invoice</label>
                          <div className="col-sm-8">
                            <a href={orderDetails.invoicelink} target="_blank">Preview</a>
                          </div>
                        </div>
                      )
                    }

                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="card">
                  <div className="card-header">
                    Customer Details
                    </div>
                  <div className="card-body">
                    <div className="row">
                      <label for="example-text-input" className="col-sm-4 col-form-label">Customer Name</label>
                      <div className="col-sm-8">
                        <p>{orderDetails && orderDetails.customername}</p>
                      </div>
                    </div>
                    <div className="row">
                      <label for="example-text-input" className="col-sm-4 col-form-label">Customer No</label>
                      <div className="col-sm-8">
                        <p>{orderDetails && orderDetails.shipmobilenumber}</p>
                      </div>
                    </div>
                    {
                      (orderDetails && orderDetails.customeremail) && (
                        <div className="row">
                          <label for="example-text-input" className="col-sm-4 col-form-label">Customer Email</label>
                          <div className="col-sm-8">
                            <p>{orderDetails && orderDetails.customeremail}</p>
                          </div>
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="card">
                  <div className="card-header">
                    Driver Details
                    </div>
                  <div className="card-body">
                    <div className="row">
                      <label for="example-search-input" className="col-sm-4 col-form-label">Driver</label>
                      <div className="col-sm-8">
                        <p>{orderDetails && orderDetails.drivername}</p>
                      </div>
                    </div>
                    <div className="row">
                      <label for="example-search-input" className="col-sm-4 col-form-label">Driver No</label>
                      <div className="col-sm-8">
                        <p>{orderDetails && orderDetails.drivercontact}</p>
                      </div>
                    </div>
                    {
                      (orderDetails && orderDetails.drivertip) && (
                        <div className="row">
                          <label for="example-search-input" className="col-sm-4 col-form-label">Driver Tip</label>
                          <div className="col-sm-8">
                            <p>{orderDetails && orderDetails.pickupcurrencysymbol}{orderDetails && orderDetails.drivertip}</p>
                          </div>
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="card">
                  <div className="card-header">
                    Shipment Details
                    </div>
                  <div className="card-body">
                    <div className="row">
                      <label for="example-email-input" className="col-sm-4 col-form-label">Pickup address</label>
                      <div className="col-sm-8">
                        <p>{orderDetails && orderDetails.pickuplocationaddress1} {orderDetails && orderDetails.pickuplocationaddress2} {orderDetails && orderDetails.pickuplocationaddress3} {orderDetails && orderDetails.pickupcity} {orderDetails && orderDetails.pickupcountry}</p>
                      </div>
                    </div>
                    <div className="row">
                      <label className="col-sm-4 col-form-label">Drop off address</label>
                      <div className="col-sm-8">
                        <p>{orderDetails && orderDetails.deliverylocationaddress1} {orderDetails && orderDetails.deliverylocationaddress2} {orderDetails && orderDetails.deliverylocationaddress3}</p>
                      </div>
                    </div>
                    {
                      (orderDetails && orderDetails.pickupusername) && (
                        <div className="row">
                          <label for="example-url-input" className="col-sm-4 col-form-label">Picked from</label>
                          <div className="col-sm-8">
                            <p>{orderDetails && orderDetails.pickupusername}</p>
                          </div>
                        </div>
                      )
                    }
                    {
                      (orderDetails && orderDetails.preferedtimeslot) && (
                        <div className="row">
                          <label for="example-url-input" className="col-sm-4 col-form-label">Pickup Time Slot</label>
                          <div className="col-sm-8">
                            <p>{orderDetails && orderDetails.preferedtimeslot}</p>
                          </div>
                        </div>
                      )
                    }
                    <div className="row">
                      <label for="example-url-input" className="col-sm-4 col-form-label">Delivery Time Slot</label>
                      <div className="col-sm-8">
                        <p>{orderDetails && orderDetails.timeslot}</p>
                      </div>
                    </div>
                    {
                      (orderDetails && orderDetails.deliverynotes) && (
                        <div className="row">
                          <label for="example-url-input" className="col-sm-4 col-form-label">Delivery Note</label>
                          <div className="col-sm-8">
                            <p>{orderDetails && orderDetails.deliverynotes}</p>
                          </div>
                        </div>
                      )
                    }
                    <div className="row">
                      <label for="example-search-input" className="col-sm-4 col-form-label">Delivery Date</label>
                      <div className="col-sm-8">
                        <p>{orderDetails && this.getDate(orderDetails.deliverydate)}</p>
                      </div>
                    </div>
                    {
                      (orderDetails && orderDetails.poddeliveryimage) && (
                        <div className="row">
                          <label for="example-tel-input" className="col-sm-4 col-form-label">POD Image</label>
                          <div className="col-sm-8">
                            <a href={orderDetails.poddeliveryimage} target="_blank">Preview</a>
                          </div>
                        </div>
                      )
                    }
                    {/* {
                      (orderDetails && orderDetails.deliveryoption) && (
                        <div className="row">
                          <label for="example-tel-input" className="col-sm-4 col-form-label">Delivered to</label>
                          <div className="col-sm-8">
                            <p>{orderDetails && orderDetails.deliveryoption}</p>
                          </div>
                        </div>
                      )
                    } */}
                    <div className="row">
                      <label for="example-tel-input" className="col-sm-4 col-form-label">Status</label>
                      <div className="col-sm-8">
                        <p>{orderDetails && orderDetails.deliverystatus}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX >
    );
  }
}

export default withRouter(OrderView);