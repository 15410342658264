import React, { Component } from 'react';


class BankDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    const { hasDetails, bankDetails } = this.props;
    return (
      <div className="modal fade" id="bank-details" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Bank details</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {
                !hasDetails && (
                  <p>No bank details found</p>
                )
              }
              {
                hasDetails && (
                  <React.Fragment>
                    {
                      (bankDetails && bankDetails.name) && (
                        <div className="row">
                          <label for="example-text-input" className="col-sm-4 col-form-label">Name</label>
                          <div className="col-sm-8">
                            <p>{bankDetails.name}</p>
                          </div>
                        </div>
                      )
                    }
                    {
                      (bankDetails && bankDetails.transitno) && (
                        <div className="row">
                          <label for="example-text-input" className="col-sm-4 col-form-label">Transit Number</label>
                          <div className="col-sm-8">
                            <p>{bankDetails.transitno}</p>
                          </div>
                        </div>
                      )
                    }
                    {
                      (bankDetails && bankDetails.finacialinstitutionno) && (
                        <div className="row">
                          <label for="example-text-input" className="col-sm-4 col-form-label">Financial intitution number</label>
                          <div className="col-sm-8">
                            <p>{bankDetails.finacialinstitutionno}</p>
                          </div>
                        </div>
                      )
                    }
                    {
                      (bankDetails && bankDetails.accountno) && (
                        <div className="row">
                          <label for="example-text-input" className="col-sm-4 col-form-label">Account number</label>
                          <div className="col-sm-8">
                            <p>{bankDetails.accountno}</p>
                          </div>
                        </div>
                      )
                    }
                  </React.Fragment>
                )
              }
            </div>
            <div className="modal-footer d-block pt0 mb15">
              <button type="button" className="btn btn-secondary select-location" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BankDetails;