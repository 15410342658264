import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { MDBDataTable } from 'mdbreact';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import Pagination from '../../../components/Pagination';
import SweetAlert from 'react-bootstrap-sweetalert';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import customFunctions from '../../../helper/customFunctions';

const columns = [
  {
    label: 'Name',
    field: 'name',
    sort: 'disabled',
    // width: 150
  },
  {
    label: 'Country',
    field: 'country',
    sort: 'disabled',
    // width: 270
  },
  {
    label: 'Auto allocation',
    field: 'autoallocate',
    sort: 'disabled'
  },
  // {
  //   label: 'Status',
  //   field: 'status',
  //   sort: 'disabled',
  //   // width: 200
  // },
  {
    label: 'Action',
    field: 'actions',
    sort: 'disabled'
  }
];

class City extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cities: [],
      rows: [],
      cityid: null,
      showDelete: false,
      keyword: '',
      page: 1,
      limit: 10,
      totalCount: 0,
      currentPage: 1,
      countries: [],
      selectedCountry: ''
    }
  }

  componentDidMount = async () => {
    customFunctions.setTitle('All City');
    this.getCountries();
    this.getCityList(1);
  }

  getCountries = async () => {
    const { data } = await AuthApi.getDataFromServer(Api.getCountries);
    if (data && data.status !== "Fail") {
      this.setState({ countries: data.data });
    }
  }

  getCityList = async (currentPage) => {
    const { keyword, selectedCountry, limit } = this.state;
    const pageNo = currentPage || 1;
    const isSearch = (keyword || selectedCountry) ? true : false;
    const endPoint = (isSearch) ? `${Api.searchCity}` : `${Api.getAllCityList}/${pageNo}/${limit}`;
    const searchPayload = {
      "cityname": keyword,
      "skip": pageNo,
      "limit": limit
    };
    if (selectedCountry) searchPayload.countryid = Number(selectedCountry);
    const callback = isSearch ? AuthApi.postDataToServer : AuthApi.getDataFromServer;
    const { data, message } = await callback(endPoint, searchPayload);
    if (data) {
      this.setState({
        cities: data.data,
        totalCount: data.totalcount,
        currentPage: pageNo
      }, () => {
        this.setTableData();
      });
    } else {
      NotificationManager.error(message);
    }
  }

  changeCitiestatus = async (city) => {
    const { currentPage } = this.state;
    const payload = {
      "cityid": city.cityid,
      "userstatus": city.isactive ? 0 : 1
    };

    const { data, message } = await AuthApi.postDataToServer(Api.changecitiestatus, payload);
    if (data && data.status !== 'Fail') {
      NotificationManager.success('city status changed');
      this.getCityList(currentPage);
    } else {
      NotificationManager.error((data && data.message) || message || 'Please try again later');
    }
  }

  changeAllocationStatus = async (city) => {
    console.log('city', city);
    const { currentPage } = this.state;
    const payload = {
      cityname_eng: String(city.cityname_eng),
      cityname_arabic: String(city.cityname_arabic),
      countryid: parseInt(city.countryid),
      autoallocate_shipment: city.autoallocate_shipment ? 0 : 1,
      cityid: city.cityid,
      isactive: city.isactive
    };

    const { data, message } = await AuthApi.postDataToServer(Api.updatecity, payload);
    if (data && data.status !== 'Fail') {
      NotificationManager.success('Auto allocation status changed');
      this.getCityList(currentPage);
    } else {
      NotificationManager.error((data && data.message) || message || 'Please try again later');
    }
  }

  setTableData = () => {
    const { cities } = this.state;
    const rows = [];
    cities.map((city, i) => { // eslint-disable-line
      rows.push({
        name: `${city.cityname_eng}`,
        country: `${city.countryname_eng}`,
        status: (
          <React.Fragment>
            <input type="checkbox" id={`switch${city.cityid}`} switch="info" checked={city.isactive ? "checked" : ""} onChange={() => {
              this.changeCitiestatus(city);
            }} />
            <label for={`switch${city.cityid}`} data-on-label="" data-off-label=""></label>
          </React.Fragment>
        ),
        autoallocate: (
          <React.Fragment>
            <input type="checkbox" id={`allocation${city.cityid}`} switch="info" checked={city.autoallocate_shipment ? "checked" : ""} onChange={() => {
              this.changeAllocationStatus(city);
            }} />
            <label for={`allocation${city.cityid}`} data-on-label="" data-off-label=""></label>
          </React.Fragment>
        ),
        actions: (
          <React.Fragment>
            <a href="/" onClick={(e) => {
              e.preventDefault(); e.stopPropagation();
              this.props.history.push(`/edit-city/${city.cityid}`)
            }}>
              <i className="mdi mdi-lead-pencil" title="Edit City"></i>
            </a>
            {/* <a href="/" onClick={(e) => {
              e.preventDefault(); e.stopPropagation();
              this.props.history.push(`/cities/address/${city.cityid}`)
            }}>
              <i className="mdi mdi-note-text" title="View city"></i>
            </a>  */}
            <a href="/" className="m-l-10" onClick={(e) => {
              e.preventDefault(); e.stopPropagation();
              this.setState({ showDelete: true, cityid: city.cityid })
            }}>
              <i className="mdi mdi-delete text-danger" title="Delete City"></i>
            </a>
          </React.Fragment>
        )
      })
    })
    this.setState({ showLoader: false, rows });
    this.forceUpdate();
  }

  deleteCity = async () => {
    const { cityid } = this.state;
    const { data, message } = await AuthApi.deleteDataFromServer(`${Api.deleteCity}${cityid}`);
    if (data) {
      this.setState({ showDelete: false, cityid: null }, () => {
        this.getCityList(1);
      });
      NotificationManager.success('City deleted');
    } else {
      NotificationManager.error(message);
    }
  }

  onSearchSubmit = (e) => {
    e.preventDefault();
    this.getCityList(1);
  }

  clearSearch = () => {
    this.setState({ keyword: '', selectedCountry: '' }, () => {
      this.getCityList(1);
    })
  }

  render() {
    const { rows, showDelete, keyword, limit, totalCount, currentPage, countries, selectedCountry } = this.state;
    return (
      <AUX>
        <div className="page-content-wrapper">
          <NotificationContainer />
          <div className="container-fluid">
            {
              showDelete && (
                <SweetAlert
                  title="Are you sure, you want to delete?"
                  error
                  showCancel
                  confirmBtnBsStyle="success"
                  cancelBtnBsStyle="danger"
                  onConfirm={() => { this.deleteCity() }}
                  onCancel={() => { this.setState({ showDelete: false, cityid: null }) }} >
                  You won't be able to revert this!
                </SweetAlert>
              )
            }
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <form onSubmit={this.onSearchSubmit}>
                      <div className="form-group row">
                        <div className="col-sm-4">
                          <select className="form-control" onChange={(e) => {
                            this.setState({ selectedCountry: e.target.value });
                          }}>
                            <option value="">Select country</option>
                            {
                              countries.map((country) => (
                                <option value={country.countryid}>{country.countryname_eng}</option>
                              ))
                            }
                          </select>
                        </div>
                        <div className="col-sm-4">
                          <input className="form-control" value={keyword} type="text" placeholder="Search here" id="example-text-input" onChange={(e) => { this.setState({ keyword: e.target.value }) }} required={!selectedCountry} />
                        </div>
                        <div className="col-sm-3">
                          <button type="submit" className="btn btn-primary waves-effect waves-light">
                            Search
                        </button>
                          <button type="reset" onClick={() => { this.clearSearch(); }} className="btn btn-secondary waves-effect m-l-10">
                            Cancel
                        </button>
                        </div>
                      </div>
                    </form>
                    <MDBDataTable
                      // striped
                      noBottomColumns={true}
                      paging={false}
                      bordered
                      hover
                      data={{ columns, rows }}
                      searching={false}
                    />
                    <div className="pagination">
                      <Pagination
                        totalCount={totalCount}
                        currentPage={currentPage}
                        limit={limit}
                        getData={this.getCityList}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </AUX>
    );
  }
}

export default withRouter(City);   