import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { withRouter } from 'react-router-dom';
import customFunctions from '../../../helper/customFunctions';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';

class MerchantAddresses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addressList: [],
      merchantDetails: {}
    };
  }

  componentDidMount = () => {
    customFunctions.setTitle('Addresses');
    const vendorId = this.props.match.params.vendorId;
    if (!vendorId) {
      this.props.history.push('/merchants');
      return;
    }
    this.getMerchantDetails(vendorId);
    this.getMerchantAddresses(vendorId);
  }

  getMerchantDetails = async (vendorId) => {
    const { data } = await AuthApi.getDataFromServer(`${Api.getVendor}${vendorId}`);
    if (data && data.data && data.status !== "Fail") {
      this.setState({ merchantDetails: data.data });
    } else {
      this.props.history.push('/merchants');
    }
  }

  getMerchantAddresses = async (vendorId) => {
    const { data } = await AuthApi.getDataFromServer(`${Api.getVendorAddress}/${vendorId}`);
    if (data && data.status !== "Fail") {
      this.setState({ addressList: data.data });
    }
  }

  render() {
    const { merchantDetails, addressList } = this.state;
    return (
      <AUX>
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <div className="card">
              <div className="card-body">
                <h6 className="">Customer details</h6>
                <div className="form-group row">
                  <div className="col-sm-2">
                    <label>Name:</label>
                  </div>
                  <div className="col-sm-10">
                    <p>{merchantDetails.first_name} {merchantDetails.last_name}</p>
                  </div>
                  <div className="col-sm-2">
                    <label>Number:</label>
                  </div>
                  <div className="col-sm-10">
                    <p>{merchantDetails.mobile}</p>
                  </div>
                  <div className="col-sm-2">
                    <label>Email:</label>
                  </div>
                  <div className="col-sm-10">
                    <p>{merchantDetails.email}</p>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            {
              (addressList.length > 0) ? (
                <div className="row">
                  {
                    addressList.map((address) => (
                      <div className="col-sm-6">
                        <div className="card">
                          <div className="card-body">
                            <div>{address.pickuplocationname}</div>
                            <div>{address.pickuplocationaddress1} {address.pickuplocationaddress2} {address.pickuplocationaddress3}</div>
                            <div>{address.cityname_eng} {address.countryname_eng} {address.pickuplocationpostcode}</div>
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </div>
              ) : (
                  <div className="card">
                    <div className="card-body">
                      <h6 className="text-center">No addresses available</h6>
                    </div>
                  </div>
                )
            }
          </div>
        </div>
      </AUX>
    );
  }
}

export default withRouter(MerchantAddresses);