import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import customFunctions from '../../../helper/customFunctions';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';


class AddCommission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countryid: '',
      countries: [],
      cityid: '',
      cities: [],
      commission: '',
      chargeId: null,
      commissionid: '',
      currencies: ['USD', 'KD'],
      currency: 'USD',
      commissionErr: ''
    }
  }

  componentDidMount = async () => {
    const chargeId = this.props.match.params.commissionId;
    this.setState({ chargeId });
    this.getCountries();
    if (chargeId) {
      customFunctions.setTitle('Edit commission');
      this.getGetDeliveryCharge(chargeId);
    } else {
      customFunctions.setTitle('Add commission');
    }
  }

  getGetDeliveryCharge = async (chargeId) => {
    const { data, message } = await AuthApi.getDataFromServer(`${Api.getCommissionByCity}/${chargeId}`);
    if (data && data.status !== "Fail") {
      this.getCities(data.data.countryid)
      this.setState({
        countryid: data.data.countryid,
        cityid: data.data.cityid,
        commission: data.data.drivercommission,
        commissionid: data.data.drivercommissionid
      });
    } else {
      this.props.history.push('/driver-commission');
    }

  }

  getCountries = async () => {
    const { data } = await AuthApi.getDataFromServer(Api.getCountries);
    if (data && data.status !== "Fail") {
      this.setState({ countries: data.data });
    }
  }

  getCities = async (countryid) => {
    const { data } = await AuthApi.getDataFromServer(`${Api.getCities}/${countryid}`);
    if (data && data.status !== "Fail") {
      this.setState({ cities: data.data });
    }
  }

  validateForm = (e) => {
    e.preventDefault();
    const {
      commission, cityid, countryid,
    } = this.state;
    let commissionErr = '', cityErr = '', countryErr = '', isValid = true;

    if (commission.toString().trim() === '') {
      commissionErr = 'Commission is required'; isValid = false;
    } else if (!customFunctions.validateAmount(commission)) {
      commissionErr = 'Invalid input'; isValid = false;
    }

    if (cityid.toString().trim() === '') {
      cityErr = 'City is required'; isValid = false;
    }

    if (countryid.toString().trim() === '') {
      countryErr = 'Country is required'; isValid = false;
    }

    this.setState({ commissionErr, cityErr, countryErr });
    if (isValid) this.addDeliveryCharges();

  }

  addDeliveryCharges = async () => {
    const { commission, cityid, chargeId, commissionid } = this.state;
    const payload = {
      commission,
      cityid
    };
    if (chargeId) payload.commissionid = commissionid;
    const endpoint = chargeId ? Api.updateCommission : Api.addCommission;
    const method = chargeId ? AuthApi.putDataToServer : AuthApi.postDataToServer;
    const { data, message } = await method(endpoint, payload);
    if (data && data.status !== "Fail") {
      if (chargeId) {
        var $this = this;
        NotificationManager.success('Driver commission updated', '', 1000);
        setTimeout(() => {
          $this.props.history.push('/driver-commission');
        }, 1000);
        return;
      }
      NotificationManager.success('Commission added');
      this.resetData();
    } else {
      NotificationManager.error((data && data.message) || message || 'Please try again later');
    }
  }

  resetData = () => {
    this.setState({
      commission: '', cityid: '', countryid: '', cities: []
    });
  }

  render() {
    const {
      cityid, countryid, countries, cities,
      cityErr, countryErr, commission, commissionErr
    } = this.state;
    return (
      <AUX>
        <div className="page-content-wrapper">
          <NotificationContainer />
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <form onSubmit={this.validateForm}>
                      <div className="form-group row">
                        <label for="example-text-input" className="col-sm-2 col-form-label">Country*</label>
                        <div className="col-sm-5">
                          <select className="form-control" value={countryid} onChange={(e) => {
                            this.setState({ countryid: e.target.value, cityid: '' });
                            this.getCities(e.target.value);
                          }}>
                            <option value=''>Select country</option>
                            {
                              countries.map((country) => (
                                <option value={country.countryid}>{country.countryname_eng}</option>
                              ))
                            }
                          </select>
                          <span id="err">{countryErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label for="example-text-input" className="col-sm-2 col-form-label">City*</label>
                        <div className="col-sm-5">
                          <select className="form-control" value={cityid} onChange={(e) => {
                            this.setState({ cityid: e.target.value });
                            // this.getCities(e.target.value);
                          }}>
                            <option value=''>Select city</option>
                            {
                              cities.map((city) => (
                                <option value={city.cityid}>{city.cityname_eng}</option>
                              ))
                            }
                          </select>
                          <span id="err">{cityErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label for="example-text-customer-charges" className="col-sm-2 col-form-label">Driver commission*</label>
                        <div className="col-sm-5">
                          <div className="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text" id="basic-addon1">$</span>
                            </div>
                            <input className="form-control" value={commission} type="text" placeholder="50" id="example-text-customer-charges" onChange={(e) => {
                              if (customFunctions.validateAmount(e.target.value) || e.target.value === '') {
                                this.setState({ commission: e.target.value })
                              }
                            }} />
                          </div>
                          <span id="err">{commissionErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-sm-3">
                          <button type="submit" className="btn btn-primary waves-effect waves-light">
                            Submit
                        </button>
                          <button type="reset" onClick={() => {
                            this.props.history.push('/driver-commission');
                          }} className="btn btn-secondary waves-effect m-l-10">
                            Cancel
                        </button>
                        </div>
                      </div>

                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

export default AddCommission;