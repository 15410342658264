import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { MDBDataTable } from 'mdbreact';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import Pagination from '../../../components/Pagination';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import customFunctions from '../../../helper/customFunctions';

class Orders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      rows: [],
      driverid: null,
      showDelete: false,
      keyword: '',
      page: 1,
      limit: 10,
      totalCount: 1,
      currentPage: 1,
      userData: {},
      columns: [],
      drivers: [],
      totalEarnings: 0,
      paymentMethod: '',
      startDate: '',
      endDate: ''
    };
  }

  componentDidMount = async () => {
    customFunctions.setTitle('Driver Orders');
    const userData = await customFunctions.getUserData();
    if (!userData) {
      this.props.history.push('/login');
      return;
    }
    this.setState({ userData }, async () => {
      await this.getOrders(1);
      await this.getDrivers();
      this.setTableColumns();
    });
  }

  getDrivers = async () => {
    const endPoint = `${Api.getDrivers}/1/200`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data) {
      this.setState({
        drivers: data.data,
      });
    } else {
      NotificationManager.error(message);
    }
  }

  getOrders = async (currentPage) => {
    const { limit, userData, startDate, endDate, paymentMethod, driverid } = this.state;
    const pageNo = currentPage || 1;

    if (!driverid) {
      this.setState({
        orders: [],
        totalCount: 0,
        currentPage: 1
      }, () => {
        this.setTableData();
      });
      return false;
    }

    const payload = {
      driverid,
      skip: pageNo,
      limit
    }
    if (startDate) payload.startdate = startDate;
    if (endDate) payload.enddate = endDate;
    if (paymentMethod) payload.paymentmethod = paymentMethod;
    const endPoint = `${Api.driverBooking}`;
    const { data } = await AuthApi.postDataToServer(endPoint, payload);
    if (data && data.data) {
      this.setState({
        orders: data.data,
        totalCount: data.totalcount,
        currentPage: pageNo
      }, () => {
        this.setTableData();
      });
    }
  }

  setTableColumns = () => {
    const columns = [
      {
        label: "No",
        field: 'indexno',
        sort: 'disabled',
        // width: 150
      },
      {
        label: "Booking No",
        field: 'oid',
        sort: 'disabled',
        // width: 150
      },
      {
        label: "Customer Name",
        field: 'customer',
        sort: 'disabled',
        // width: 150
      },
      {
        label: "Date",
        field: 'date',
        sort: 'disabled',
        // width: 150
      },
      {
        label: "Delivery Charges",
        field: 'deliverycharges',
        sort: 'disabled',
        // width: 150
      },
      {
        label: "Kurbi Service Fee",
        field: 'servicefee',
        sort: 'disabled',
        // width: 270
      },
      {
        label: "Earnings",
        field: 'earnings',
        sort: 'disabled',
        // width: 200
      },

    ];
    this.setState({ showLoader: false, columns });
    this.forceUpdate();
  }

  setTableData = () => {
    const { orders, currentPage } = this.state;
    const rows = [];
    let totalEarnings = 0;
    orders.map((order, index) => { // eslint-disable-line
      rows.push({
        indexno: ((currentPage - 1) * 10) + (index + 1),
        oid: `#${order.awbnumber}`,
        customer: order.customername,
        date: order.createddate,
        deliverycharges: `${order.currencysymbol || ''}${order.shipmentcharges || ''}`,
        servicefee: `${order.currencysymbol || ''}${order.kurbiservicefee || ''}`,
        // pickup_addr: order.pickup_addr,
        // drop_addr: order.drop_addr,
        // slot: order.time_slot,
        earnings: `${order.currencysymbol || ''}${order.Totalearning || ''}`,
      });
      totalEarnings = totalEarnings + order.Totalearning;
    })
    this.setState({ showLoader: false, rows, totalEarnings });
    this.forceUpdate();
  }

  filterData = (e) => {
    e.preventDefault();
    this.getOrders();
  }

  clearFilter = () => {
    this.setState({ startDate: '', endDate: '', paymentMethod: '', driverid: '' }, () => {
      this.getOrders();
    });
  }

  render() {
    const { rows, showDelete, keyword, totalCount, currentPage, limit, columns, paymentMethod, startDate, endDate, driverid, drivers } = this.state;

    return (
      <AUX>
        <div className="page-content-wrapper">
          <NotificationContainer />
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <form onSubmit={this.filterData}>
                      <div className="form-group row">
                        <div className="col-sm-3 d-flex input-group">
                          <span className="mr-2">Filter By</span>
                          {/* <input className="form-control" value={keyword} type="text" placeholder="Search here" id="example-text-input" onChange={(e) => { this.setState({ keyword: e.target.value }) }} required={true} /> */}
                          <select className="form-control" placeholder="Select driver" required={true} value={driverid} onChange={(e) => {
                            this.setState({ driverid: e.target.value })
                          }} >
                            <option value="">Select driver</option>
                            {
                              drivers.map(driver => (
                                <option value={driver.driverid}>{`${driver.first_name} ${driver.last_name}`}</option>
                              ))
                            }
                          </select>
                        </div>
                        <div className="col-sm-3 d-flex input-group">
                          <span className="mr-2">From</span>
                          <input id="ArrivalDate" type="date" className="input-sm form-control" placeholder="Start date" name="start" value={startDate} onChange={(e) => {
                            this.setState({ startDate: e.target.value });
                          }} max={endDate} />
                        </div>
                        <div className="col-sm-3 d-flex input-group">
                          <span className="mr-2">to</span>
                          <input id="DepartDate" type="date" className="input-sm form-control" placeholder="End date" name="end" value={endDate} onChange={(e) => {
                            this.setState({ endDate: e.target.value });
                          }} min={startDate} />
                        </div>
                        <div className="col-sm-3">
                          <select className="form-control custom-select" id="exampleFormControlSelect1" value={paymentMethod} onChange={(e) => {
                            this.setState({ paymentMethod: e.target.value });
                          }}>
                            <option value="">Select payment method</option>
                            <option value="alreadyPaid">Already Paid</option>
                            <option value="cod">Kurbi</option>
                          </select>
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-sm-2">
                          <button type="submit" className="btn btn-primary waves-effect waves-light">
                            Search
                        </button>
                          <button type="reset" onClick={(e) => {
                            e.preventDefault(); e.stopPropagation();
                            this.clearFilter();
                          }} className="btn btn-secondary waves-effect m-l-10">
                            Cancel
                        </button>
                        </div>
                      </div>
                    </form>
                    <MDBDataTable
                      // striped
                      paging={false}
                      bordered
                      hover
                      data={{ columns, rows }}
                      searching={false}
                      noBottomColumns={true}
                      noRecordsFoundLabel={'No Records Found'}
                    />
                    <div className="pagination">
                      <Pagination
                        totalCount={totalCount}
                        currentPage={currentPage}
                        limit={limit}
                        getData={this.getOrders}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

export default withRouter(Orders);
