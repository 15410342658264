import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import customFunctions from '../../../helper/customFunctions';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';


class AddCommission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      driverid: '',
      amount: '',
      paymentdate: '',
      comment: '',
      refno: '',
      paymentid: null,
      drivers: [],
      currency: '$'
    }
  }

  componentDidMount = async () => {
    const paymentid = this.props.match.params.paymentid;
    this.setState({ paymentid });
    this.getDrivers();
    if (paymentid) {
      customFunctions.setTitle('Edit Payment');
      this.getPaymentDetails(paymentid);
    } else {
      customFunctions.setTitle('Add Payment');
    }
  }

  getDrivers = async () => {
    const { data } = await AuthApi.getDataFromServer(`${Api.getDrivers}/1/1000`);
    if (data && data.status !== "Fail") {
      this.setState({ drivers: data.data });
    }
  }

  getPaymentDetails = async (chargeId) => {
    const { data, message } = await AuthApi.getDataFromServer(`${Api.getPaymentDetails}/${chargeId}`);
    if (data && data.status !== "Fail") {
      this.setState({
        paymentid: data.data.paymentid,
        amount: data.data.amount.toString(),
        comment: data.data.comment,
        refno: data.data.refno,
        driverid: data.data.userid,
        paymentdate: data.data.paymentdate
      });
    } else {
      this.props.history.push('/driver-payment-list');
    }

  }

  getCountries = async () => {
    const { data } = await AuthApi.getDataFromServer(Api.getCountries);
    if (data && data.status !== "Fail") {
      this.setState({ countries: data.data });
    }
  }

  getCities = async (countryid) => {
    const { data } = await AuthApi.getDataFromServer(`${Api.getCities}/${countryid}`);
    if (data && data.status !== "Fail") {
      this.setState({ cities: data.data });
    }
  }

  validateForm = (e) => {
    e.preventDefault();
    const {
      driverid, comment, paymentdate, amount
    } = this.state;
    let driverErr = '', commentErr = '', dateErr = '', amountErr = '', isValid = true;

    if (amount.toString().trim() === '') {
      amountErr = 'Amount is required'; isValid = false;
    } else if (!customFunctions.validateAmount(amount)) {
      amountErr = 'Invalid input'; isValid = false;
    }

    if (driverid.toString().trim() === '') {
      driverErr = 'Driver is required'; isValid = false;
    }

    if (comment.toString().trim() === '') {
      commentErr = 'Comment is required'; isValid = false;
    }

    if (paymentdate.toString().trim() === '') {
      dateErr = 'Date is required'; isValid = false;
    }


    this.setState({ driverErr, commentErr, dateErr, amountErr });
    if (isValid) this.addPayment();

  }

  addPayment = async () => {
    const { driverid, comment, paymentdate, amount, refno, paymentid } = this.state;
    const userData = await customFunctions.getUserData();
    const payload = {
      paytouserid: driverid,
      amount,
      paymentdate,
      comment,
      refno,
      paidbyuserid: userData.data.id
    };
    console.log('payload', payload);
    if (paymentid) payload.paymentid = paymentid;
    const endpoint = paymentid ? Api.updatePayment : Api.addPayment;
    const method = paymentid ? AuthApi.putDataToServer : AuthApi.postDataToServer;
    const { data, message } = await method(endpoint, payload);
    if (data && data.status !== "Fail") {
      if (paymentid) {
        var $this = this;
        NotificationManager.success('Driver payment updated', '', 1000);
        setTimeout(() => {
          $this.props.history.push('/driver-payment-list');
        }, 1000);
        return;
      }
      NotificationManager.success('Payment added');
      this.resetData();
    } else {
      NotificationManager.error((data && data.message) || message || 'Please try again later');
    }
  }

  resetData = () => {
    this.setState({
      driverid: '', comment: '', paymentdate: '', refno: '', amount: ''
    });
  }

  render() {
    const {
      drivers, driverid, amount, paymentdate, refno, currency, comment,
      driverErr, amountErr, dateErr, commentErr,
    } = this.state;
    return (
      <AUX>
        <div className="page-content-wrapper">
          <NotificationContainer />
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <form onSubmit={this.validateForm}>
                      <div className="form-group row">
                        <label for="example-text-input" className="col-sm-2 col-form-label">Driver*</label>
                        <div className="col-sm-5">
                          <select className="form-control" value={driverid} onChange={(e) => {
                            const selectedDriver = drivers.filter((d) => {
                              return Number(d.userid) === Number(e.target.value);
                            });
                            let selectedCurrency = '$';
                            if (selectedDriver && selectedDriver.length > 0) {
                              selectedCurrency = selectedDriver[0].currencysymbol ? selectedDriver[0].currencysymbol : '$';
                            }
                            this.setState({ driverid: e.target.value, currency: selectedCurrency });
                          }}>
                            <option value=''>Select driver</option>
                            {
                              drivers.map((driver) => (
                                <option value={driver.userid}>{driver.first_name} {driver.last_name}</option>
                              ))
                            }
                          </select>
                          <span id="err">{driverErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label for="example-text-customer-charges" className="col-sm-2 col-form-label">Amount*</label>
                        <div className="col-sm-5">
                          <div className="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text" id="basic-addon1">{currency}</span>
                            </div>
                            <input className="form-control" value={amount} type="text" placeholder="50" id="example-text-customer-charges" onChange={(e) => {
                              if (customFunctions.validateAmount(e.target.value) || e.target.value === '') {
                                this.setState({ amount: e.target.value })
                              }
                            }} />
                          </div>
                          <span id="err">{amountErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label for="example-text-customer-charges" className="col-sm-2 col-form-label">Date*</label>
                        <div className="col-sm-5">
                          <input type="date" className="form-control" value={paymentdate} onChange={(e) => {
                            this.setState({ paymentdate: e.target.value });
                          }} />
                          <span id="err">{dateErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label for="example-text-customer-charges" className="col-sm-2 col-form-label">Ref No</label>
                        <div className="col-sm-5">
                          <input type="text" className="form-control" value={refno} onChange={(e) => {
                            this.setState({ refno: e.target.value });
                          }} />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label for="example-text-customer-charges" className="col-sm-2 col-form-label">Comment*</label>
                        <div className="col-sm-5">
                          <textarea className="form-control" rows={2} value={comment} onChange={(e) => {
                            this.setState({ comment: e.target.value });
                          }}></textarea>
                          <span id="err">{commentErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-sm-3">
                          <button type="submit" className="btn btn-primary waves-effect waves-light">
                            Submit
                        </button>
                          <button type="reset" onClick={() => {
                            this.props.history.push('/driver-payment-list');
                          }} className="btn btn-secondary waves-effect m-l-10">
                            Cancel
                        </button>
                        </div>
                      </div>

                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

export default AddCommission;