import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { MDBDataTable } from 'mdbreact';
import customFunctions from '../../../helper/customFunctions';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import SweetAlert from 'react-bootstrap-sweetalert';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Pagination from '../../../components/Pagination';
import SharedService from '../../../helper/SharedService';

const columns = [
  {
    label: 'Time',
    field: 'time',
    sort: 'disabled',
    // width: 150
  },
  {
    label: 'Country',
    field: 'country',
    sort: 'disabled',
    // width: 150
  },
  {
    label: 'City',
    field: 'city',
    sort: 'disabled',
    // width: 150
  },
  {
    label: 'Action',
    field: 'action',
    sort: 'disabled',
    // width: 150
  },

];


class Timeslots extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allSlots: [],
      rows: [],
      showDelete: false
    }
  }

  componentDidMount = async () => {
    customFunctions.setTitle('Time slots');
    const countryid = await localStorage.getItem('admincountry') || '';
    const cityid = await localStorage.getItem('admincity') || '';
    this.setState({ countryid, cityid }, () => {
      this.getTimeSlots(1);
    })
    this.subscribed = SharedService.cityChanged.subscribe(this.checkAppliedFilter);
  }

  componentWillUnmount = () => {
    if (this.subscribed) this.subscribed.unsubscribe();
  }

  checkAppliedFilter = async () => {
    const countryid = await localStorage.getItem('admincountry') || '';
    const cityid = await localStorage.getItem('admincity') || '';
    this.setState({ countryid, cityid }, () => {
      this.getTimeSlots(1);
    })
  }

  getTimeSlots = async (currentPage) => {
    const { cityid } = this.state;
    const payload = {};
    if (cityid) payload.cityid = cityid;
    const { data } = await AuthApi.postDataToServer(Api.getTimeSlots, payload);
    if (data && data.status !== "Fail") {
      this.setState({
        allSlots: data.data
      }, () => {
        this.setTableData();
      });
    }
  }

  setTableData = () => {
    const { allSlots } = this.state;
    const rows = [];

    allSlots.map((slot) => {
      rows.push({
        time: slot.timeslot,
        country: slot.countryname_eng,
        city: slot.cityname_eng,
        action: (
          <React.Fragment>
            <a href="/" onClick={(e) => {
              e.preventDefault(); e.stopPropagation();
              this.props.history.push(`/edit-timeslot/${slot.id}`)
            }}>
              <i className="mdi mdi-lead-pencil" id="editDriver" title="Edit timeslot"></i>
            </a>
            <a href="/" className="ml1" onClick={(e) => {
              e.preventDefault(); e.stopPropagation();
              this.setState({ showDelete: true, slotId: slot.id })
            }}>
              <i className="mdi mdi-delete text-danger" title="Delete timeslot"></i>
            </a>
          </React.Fragment>
        )
      });
    });
    this.setState({ showLoader: false, rows });
    this.forceUpdate();
  }

  deteleOrder = async () => {
    const { slotId } = this.state;
    const { data, message } = await AuthApi.deleteDataFromServer(`${Api.deleteTimeSlots}/${slotId}`);
    if (data && data.status !== "Fail") {
      this.setState({ showDelete: false, slotId: null }, () => {
        this.getTimeSlots(1);
      });
      NotificationManager.success('Timeslot deleted');
    } else {
      NotificationManager.error(message);
    }
  }

  render() {
    const { rows, totalCount, currentPage, limit, showDelete } = this.state;
    return (
      <AUX>
        <div className="page-content-wrapper">
          <NotificationContainer />
          <div className="container-fluid">
            {
              showDelete && (
                <SweetAlert
                  title="Are you sure, you want to delete?"
                  error
                  showCancel
                  confirmBtnBsStyle="success"
                  cancelBtnBsStyle="danger"
                  onConfirm={() => { this.deteleOrder() }}
                  onCancel={() => { this.setState({ showDelete: false, slotId: null }) }} >
                  You won't be able to revert this!
                </SweetAlert>
              )
            }
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <MDBDataTable
                      // striped
                      noBottomColumns={true}
                      paging={false}
                      bordered
                      hover
                      data={{ columns, rows }}
                      searching={false}
                    />
                    <div className="pagination">
                      <Pagination
                        totalCount={totalCount}
                        currentPage={currentPage}
                        limit={limit}
                        getData={this.getOrders}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

export default Timeslots;