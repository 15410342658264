import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { MDBDataTable } from 'mdbreact';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import Pagination from '../../../components/Pagination';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import customFunctions from '../../../helper/customFunctions';

class PaymentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payments: [],
      rows: [],
      vendoruid: null,
      showDelete: false,
      keyword: '',
      page: 1,
      limit: 10,
      totalCount: 1,
      currentPage: 1,
      userData: {},
      columns: [],
      vendors: [],
      totalEarnings: 0,
      startDate: '',
      endDate: ''
    };
  }

  componentDidMount = async () => {
    customFunctions.setTitle('Merchants Transaction List');
    const userData = await customFunctions.getUserData();
    if (!userData) {
      this.props.history.push('/login');
      return;
    }
    this.setState({ userData }, async () => {
      await this.getPayments(1);
      await this.getVendors();
      this.setTableColumns();
    });
  }

  getVendors = async () => {
    const endPoint = `${Api.getMerchants}/1/1000`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data) {
      this.setState({
        vendors: data.data,
      });
    } else {
      NotificationManager.error(message);
    }
  }

  getPayments = async (currentPage) => {
    const { limit, userData, startDate, endDate, paymentMethod, vendoruid, keyword } = this.state;
    const pageNo = currentPage || 1;

    if (!userData) {
      this.setState({
        payments: [],
        totalCount: 0,
        currentPage: 1
      }, () => {
        this.setTableData();
      });
      return false;
    }

    const payload = {
      skip: pageNo,
      limit,
    }
    if (startDate) payload.todate = startDate;
    if (endDate) payload.fromdate = endDate;
    if (vendoruid) payload.vendoruid = vendoruid;
    if (keyword) payload.searchfield = keyword;

    const endPoint = `${Api.getVendorPaymentList}`;
    const { data } = await AuthApi.postDataToServer(endPoint, payload);
    if (data && data.data) {
      this.setState({
        payments: data.data,
        totalCount: data.totalcount,
        currentPage: pageNo
      }, () => {
        this.setTableData();
      });
    }
  }

  setTableColumns = () => {
    const columns = [
      {
        label: "No",
        field: 'indexno',
        sort: 'disabled',
        // width: 150
      },
      {
        label: "Name",
        field: 'name',
        sort: 'disabled',
        // width: 150
      },
      {
        label: "Amount",
        field: 'amount',
        sort: 'disabled',
        // width: 150
      },
      {
        label: "Ref No",
        field: 'refno',
        sort: 'disabled',
        // width: 150
      },
      {
        label: "Date",
        field: 'paymentdate',
        sort: 'disabled',
        // width: 150
      },
      {
        label: "Comment",
        field: 'comment',
        sort: 'disabled',
        // width: 150
      },
      {
        label: 'Action',
        field: 'actions',
        sort: 'disabled'
      }

    ];
    this.setState({ showLoader: false, columns });
    this.forceUpdate();
  }

  setTableData = () => {
    const { payments, currentPage } = this.state;
    const rows = [];
    payments.map((item, index) => { // eslint-disable-line
      rows.push({
        indexno: ((currentPage - 1) * 10) + (index + 1),
        amount: `${item.currencysymbol || '$'}${item.amount || '0'}`,
        name: item.payto_username || '',
        comment: item.comment || '',
        refno: item.refno || '',
        paymentdate: item.paymentdate || '',
        actions: (
          <React.Fragment>
            <a href="/" onClick={(e) => {
              e.preventDefault(); e.stopPropagation();
              this.props.history.push(`/edit-vendor-payment/${item.paymentid}`)
            }}>
              <i className="mdi mdi-lead-pencil"  title="Edit Payment"></i>
            </a>
          </React.Fragment>
        )
      });
    })
    this.setState({ showLoader: false, rows });
    this.forceUpdate();
  }

  filterData = (e) => {
    e.preventDefault();
    this.getPayments();
  }

  clearFilter = () => {
    this.setState({ startDate: '', endDate: '', keyword: '', vendoruid: '' }, () => {
      this.getPayments();
    });
  }

  render() {
    const { rows, showDelete, keyword, totalCount, currentPage, limit, columns, startDate, endDate, vendoruid, vendors } = this.state;

    return (
      <AUX>
        <div className="page-content-wrapper">
          <NotificationContainer />
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <form onSubmit={this.filterData}>
                      <div className="form-group row">
                        <div className="col-sm-3 d-flex input-group">
                          <span className="mr-2">Filter By</span>
                          {/* <input className="form-control" value={keyword} type="text" placeholder="Search here" id="example-text-input" onChange={(e) => { this.setState({ keyword: e.target.value }) }} required={true} /> */}
                          <select className="form-control" placeholder="Select driver" value={vendoruid} onChange={(e) => {
                            this.setState({ vendoruid: e.target.value })
                          }} >
                            <option value="">Select Merchant</option>
                            {
                              vendors.map(vendor => (
                                <option value={vendor.id}>{`${vendor.vendorname}`}</option>
                              ))
                            }
                          </select>
                        </div>
                        <div className="col-sm-3 d-flex input-group">
                          <span className="mr-2">From</span>
                          <input id="ArrivalDate" type="date" className="input-sm form-control" placeholder="Start date" name="start" value={startDate} onChange={(e) => {
                            this.setState({ startDate: e.target.value });
                          }} max={endDate} />
                        </div>
                        <div className="col-sm-3 d-flex input-group">
                          <span className="mr-2">to</span>
                          <input id="DepartDate" type="date" className="input-sm form-control" placeholder="End date" name="end" value={endDate} onChange={(e) => {
                            this.setState({ endDate: e.target.value });
                          }} min={startDate} />
                        </div>
                        <div className="col-sm-3">
                          <input id="search" type="text" className="input-sm form-control" placeholder="search by name , refno, email" name="search" value={keyword} onChange={(e) => {
                            this.setState({ keyword: e.target.value });
                          }} min={startDate} />
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-sm-2">
                          <button type="submit" className="btn btn-primary waves-effect waves-light">
                            Search
                        </button>
                          <button type="reset" onClick={(e) => {
                            e.preventDefault(); e.stopPropagation();
                            this.clearFilter();
                          }} className="btn btn-secondary waves-effect m-l-10">
                            Cancel
                        </button>
                        </div>
                      </div>
                    </form>
                    <MDBDataTable
                      // striped
                      paging={false}
                      bordered
                      hover
                      data={{ columns, rows }}
                      searching={false}
                      noBottomColumns={true}
                      noRecordsFoundLabel={'No Records Found'}
                    />
                    <div className="pagination">
                      <Pagination
                        totalCount={totalCount}
                        currentPage={currentPage}
                        limit={limit}
                        getData={this.getPayments}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

export default withRouter(PaymentList);
