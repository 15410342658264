import React, { Component } from 'react';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import customFunctions from '../../../helper/customFunctions';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import AUX from '../../../hoc/Aux_';

class AddTaskFeeCharges extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countryid: '',
      countries: [],
      cityid: '',
      cities: [],
      charges: [],
      chargesObj: {
        startrange: '',
        endrange: '',
        taskfee: ''
      },
      startrangeErr: '',
      endrangeErr: '',
      taskfeeErr: '',
      editIndex: null,
      showButton: false,
      isReadOnly: true,
      removeId: null,
      showDelete: false,
      editFlag: false,
    }
  }

  componentDidMount = async () => {

    const cityid = this.props.match.params.id;
    if (this.props && this.props.location && this.props.location.state && this.props.location.state.editFlag) {
      this.setState({
        editFlag: true
      })
    }
    this.getCountries();
    this.setState({ cityid }, () => {
      this.getTaskFeeCharges();
    });
    if (cityid) {
      customFunctions.setTitle('Edit Task Fee ');
    } else {
      customFunctions.setTitle('Add Task Fee ');
    }
  }

  getCountries = async () => {
    const { data } = await AuthApi.getDataFromServer(Api.getCountries);
    if (data && data.status !== "Fail") {
      this.setState({ countries: data.data });
    }
  }

  getCities = async (countryid) => {
    const { data } = await AuthApi.getDataFromServer(`${Api.getCities}/${countryid}`);
    if (data && data.status !== "Fail") {
      this.setState({ cities: data.data });
    }
  }

  getTaskFeeCharges = async () => {
    const { cityid } = this.state;
    if (!cityid) return;
    const { data, message } = await AuthApi.getDataFromServer(`${Api.getTaskFee}?cityid=${cityid
      }`);
    if (data && data.status !== "Fail") {
      if (data.data && data.data.length > 0) {
        var countryid = data.data[0].countryid;
        this.setState({ charges: data.data, countryid: data.data[0].countryid }, () => {
          this.getCities(countryid);
        });
      }
      else {
        this.setState({
          charges: []
        })
      }
    } else {
      NotificationManager.error((data && data.message) || message || 'Please try again later');
    }
  }

  validateForm = (e) => {
    e.preventDefault();
    const {
      cityid, countryid
    } = this.state;
    let cityErr = '', countryErr = '', isValid = true;

    if (!cityid) {
      cityErr = 'City is required'; isValid = false;
    }

    if (!countryid) {
      countryErr = 'Country is required'; isValid = false;
    }

    this.setState({ cityErr, countryErr });
    if (isValid) this.addchargesData();

  }

  addchargesData = async () => {
    const { charges, chargesObj, cityid } = this.state;
    if (!(chargesObj.startrange) || !(chargesObj.endrange) || !(chargesObj.taskfee)) {
      chargesObj.startrangeErr = 'Start km is required';
      chargesObj.endrangeErr = 'End km is required';
      chargesObj.taskfeeErr = 'Charge is required';
      this.setState({ chargesObj });
      return;
    }

    const allData = [...charges];
    const filteredData = allData.filter((data) => {
      return ((Number(chargesObj.startrange) >= Number(data.startrange) && Number(chargesObj.startrange) <= Number(data.endrange)) || (Number(chargesObj.endrange) >= Number(data.startrange) && Number(chargesObj.endrange) <= Number(data.endrange)) || Number(chargesObj.startrange) === Number(chargesObj.endrange))
    });
    if (filteredData.length) {
      chargesObj.startrangeErr = "Invalid start km";
      chargesObj.endrangeErr = "Invalid end km";
      chargesObj.taskfeeErr = "Invalid charge km";
      this.setState({ chargesObj });
      return;
    }
    this.setState({ chargesObj });
    const postData = {
      cityid: cityid,
      startrange: chargesObj.startrange,
      endrange: chargesObj.endrange,
      taskfee: chargesObj.taskfee,
    }
    const callback = AuthApi.postDataToServer;
    const endPoint = Api.addTaskFee;
    const { data, message } = await callback(endPoint, postData);
    if (data && data.status !== "Fail") {
      NotificationManager.success('Task fee added', '', 1000);
      await this.getTaskFeeCharges();
      await this.setState({ chargesObj: { startrange: '', endrange: '', taskfee: '' }, editFlag: true })
    } else {
      NotificationManager.error((data && data.message) || message || 'Please try again later');
    }
  }

  removeTaskFeeCharge = async (index) => {
    const { showButton, removeId } = this.state;
    if (showButton) {
      this.setState({ showButton: false, editIndex: null, showDelete: false });
      return;
    }

    const { data, message } = await AuthApi.deleteDataFromServer(`${Api.deleteTaskFee}${removeId}`);
    if (data && data.status !== "Fail") {
      this.setState({ showDelete: false, removeId: null, editIndex: null }, () => {
        this.getTaskFeeCharges();
      });
      NotificationManager.success('Task fee deleted', '', 1000);
    } else {
      this.setState({ showDelete: false });
      NotificationManager.error(message);
    }
  }


  onEditHandler = async (type, taskfeeid, index) => {
    const { charges, editIndex, cityid } = this.state;

    if (type === 'Edit') {
      await this.setState({ showButton: true, isReadOnly: false, editIndex: index })
    }
    else if (charges && editIndex === index) {
      if ((charges[index].startrange < 0) || !(charges[index].endrange) || !(charges[index].taskfee)) {
        charges[index].startrangeErr = 'Start km is required';
        charges[index].endrangeErr = 'End km is required';
        charges[index].taskfeeErr = 'Charge is required';
        this.setState({ charges });
        return;
      }

      const allData = [...charges];
      const filteredData = allData.filter((data, i) => {
        return (editIndex !== i && ((Number(charges[index].startrange) >= Number(data.startrange) && Number(charges[index].startrange) <= Number(data.endrange)) || (Number(charges[index].endrange) >= Number(data.startrange) && Number(charges[index].endrange) <= Number(data.endrange)) || Number(charges[index].startrange) === Number(charges[index].endrange)))
      });
      if (filteredData.length) {
        charges[index].startrangeErr = "Invalid start km";
        charges[index].endrangeErr = "Invalid end km";
        charges[index].taskfeeErr = "Invalid charge km";
        this.setState({ charges });
        return;
      }
      this.setState({ charges });

      let postData = {};
      charges && charges.map((charge, index) => {
        postData = {
          taskfeeid,
          cityid: Number(cityid),
          startrange: Number(charges[index].startrange),
          endrange: Number(charges[index].endrange),
          taskfee: Number(charges[index].taskfee),
        }
      })

      const callback = AuthApi.putDataToServer;
      const endPoint = Api.editTaskFee;
      const { data, message } = await callback(endPoint, postData);
      if (data && data.status !== "Fail") {
        NotificationManager.success('Task fee updated', '', 1000);
        setTimeout(async () => {
          await this.setState({ showButton: false, editIndex: null });
        }, 800)
      } else {
        NotificationManager.error((data && data.message) || message || 'Please try again later');
      }
    }
  }

  resetData = () => {
    this.setState({
      cityid: '', countryid: '', cities: []
    });
  }

  render() {
    const {
      cityid, countryid, countries, cities, cityErr, countryErr, charges, chargesObj, editIndex, showButton, showDelete, editFlag
    } = this.state;
    return (
      <AUX>
        <div className="page-content-wrapper">
          <NotificationContainer />
          <div className="container-fluid">
            {
              showDelete && (
                <SweetAlert
                  title="Are you sure, you want to delete?"
                  error
                  showCancel
                  confirmBtnBsStyle="success"
                  cancelBtnBsStyle="danger"
                  onConfirm={() => { this.removeTaskFeeCharge() }}
                  onCancel={() => { this.setState({ showDelete: false, removeId: null }) }} >
                  You won't be able to revert this!
                </SweetAlert>
              )
            }
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <form onSubmit={this.validateForm}>
                      <div className="form-group row">
                        <label for="example-text-input" className="col-sm-2 col-form-label">Country*</label>
                        <div className="col-sm-5">
                          <select disabled={editFlag} className="form-control" value={countryid} onChange={(e) => {
                            this.setState({ countryid: e.target.value, cityid: '' });
                            this.getCities(e.target.value);
                          }}>
                            <option value=''>Select country</option>
                            {
                              countries.map((country) => (
                                <option value={country.countryid}>{country.countryname_eng}</option>
                              ))
                            }
                          </select>
                          <span id="err">{countryErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label for="example-text-input" className="col-sm-2 col-form-label">City*</label>
                        <div className="col-sm-5">
                          <select disabled={editFlag} className="form-control" value={cityid} onChange={(e) => {
                            this.setState({ cityid: e.target.value });
                            // this.getCities(e.target.value);
                          }}>
                            <option value=''>Select city</option>
                            {
                              cities.map((city) => (
                                <option value={city.cityid}>{city.cityname_eng}</option>
                              ))
                            }
                          </select>
                          <span id="err">{cityErr}</span>
                        </div>
                      </div>
                      {/* {cityid ? ( */}
                      <div className="form-group row">
                        <div className="col-sm-2">
                          <label for="example-search-input" className="col-form-label">Task Fee*</label>
                        </div>
                        <div className="col-sm-10">
                          {charges && charges.map((charge, index) => (
                            <div className="row m-t-10 p-0">
                              <div className="col-md-2">
                                <input className="form-control" readOnly={editIndex === index ? false : true} value={charge.startrange} type="text" placeholder="Start km" id="chargestartrange" onChange={(e) => {
                                  charges[index].startrange = e.target.value;
                                  this.setState({ charges })
                                }} />
                                <span id="err">{charges[index].startrangeErr}</span>
                              </div>
                              <div className="col-md-2">
                                <input className="form-control" readOnly={editIndex === index ? false : true} value={charge.endrange} type="text" placeholder="End km" id="chargeendrange" onChange={(e) => {
                                  charges[index].endrange = e.target.value;
                                  this.setState({ charges })
                                }} />
                                <span id="err">{charges[index].endrangeErr}</span>
                              </div>
                              <div className="col-md-2">
                                <input className="form-control" readOnly={editIndex === index ? false : true} value={charge.taskfee} type="text" placeholder="Task fee" id="chargeCharge" onChange={(e) => {
                                  charges[index].taskfee = e.target.value;
                                  this.setState({ charges })
                                }
                                } />
                                <span id="err">{charges[index].taskfeeErr}</span>
                              </div>
                              <div className="col-md-4">
                                {editIndex === index && (

                                  <button type="button" className="btn btn-primary waves-effect waves-light m-r-5" onClick={() => this.onEditHandler(`Save`, charge.taskfeeid, index)}>
                                    Save </button>
                                )
                                }
                                {editIndex !== index && (
                                  <button type="button" className="btn btn-primary waves-effect waves-light m-r-5" onClick={() => this.onEditHandler(`Edit`, charge.taskfeeid, index)}>
                                    Edit </button>
                                )
                                }
                                {
                                  editIndex !== index && (
                                    <button type="button" className="btn btn-danger waves-effect waves-light"
                                      onClick={(e) => {
                                        e.preventDefault(); e.stopPropagation();
                                        this.setState({ showDelete: true, removeId: charge.taskfeeid, showButton: false, index })
                                      }}>
                                      Remove</button>
                                  )
                                }
                                {editIndex === index && (
                                  <button type="button" className="btn btn-danger waves-effect waves-light m-r-5"
                                    onClick={() => { this.removeTaskFeeCharge() }}> Cancel
                                  </button>
                                )
                                }
                              </div>
                            </div>
                          ))
                          }
                          {
                            !showButton && (
                              <div className="row m-t-10 p-0">
                                <div className="col-md-2">
                                  <input className="form-control" value={chargesObj.startrange} type="text" placeholder="Start km" id="chargestartrange" onChange={(e) => {
                                    chargesObj.startrange = e.target.value;
                                    this.setState({ chargesObj })
                                  }} />
                                  <span id="err">{chargesObj.startrangeErr}</span>
                                </div>
                                <div className="col-md-2">
                                  <input className="form-control" value={chargesObj.endrange} type="text" placeholder="End km" id="chargeendrange" onChange={(e) => {
                                    chargesObj.endrange = e.target.value;
                                    this.setState({ chargesObj })
                                  }} />
                                  <span id="err">{chargesObj.endrangeErr}</span>
                                </div>
                                <div className="col-md-2">
                                  <input className="form-control" value={chargesObj.charge} type="text" placeholder="Charge" id="chargeCharge" onChange={(e) => {
                                    chargesObj.taskfee = e.target.value;
                                    this.setState({ chargesObj })
                                  }
                                  } />
                                  <span id="err">{chargesObj.taskfeekmErr}</span>
                                </div>
                                <div className="col-md-4">
                                  <button type="submit" className="btn btn-primary waves-effect waves-light m-r-5"> Add </button>
                                </div>
                              </div>
                            )
                          }
                        </div>
                      </div>
                      {/* ) : ''} */}
                      {/* <div className="form-group row">
                        <div className="col-sm-3">
                          <button type="submit" className="btn btn-primary waves-effect waves-light">
                            Submit
                          </button>
                          <button type="reset" onClick={() => {
                            this.props.history.push('/delivery-charges');
                          }} className="btn btn-secondary waves-effect m-l-10">
                            Cancel
                          </button>
                        </div>
                      </div> */}

                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

export default AddTaskFeeCharges;