import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { MDBDataTable } from 'mdbreact';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import Pagination from '../../../components/Pagination';
import SweetAlert from 'react-bootstrap-sweetalert';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import customFunctions from '../../../helper/customFunctions';
import orderData from '../../../helper/sampledata/orders';

const columns = [
  {
    label: 'Order Id',
    field: 'oid',
    sort: 'disabled',
    // width: 150
  },
  {
    label: 'Customer Name',
    field: 'customer',
    sort: 'disabled',
    // width: 150
  },
  {
    label: 'Customer Contact',
    field: 'custcontact',
    sort: 'disabled',
    // width: 150
  },
  {
    label: 'Merchant Name',
    field: 'merchant',
    sort: 'disabled',
    // width: 270
  },
  {
    label: 'Merchant Contact',
    field: 'merchantcontact',
    sort: 'disabled',
    // width: 270
  },
  {
    label: 'Driver',
    field: 'driver',
    sort: 'disabled',
    // width: 270
  },
  {
    label: 'Driver Contact',
    field: 'drivercontact',
    sort: 'disabled',
    // width: 270
  },
  {
    label: 'Time slot',
    field: 'timeslot',
    sort: 'disabled',
    // width: 270
  },
  {
    label: 'Status',
    field: 'status',
    sort: 'disabled',
    // width: 200
  },
  {
    label: 'Action',
    field: 'actions',
    sort: 'disabled'
  }
];

class Orders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      rows: [],
      driverId: null,
      showDelete: false,
      keyword: '',
      page: 1,
      limit: 10,
      totalCount: 0,
      currentPage: 1,
      userId: null
    }
  }

  componentDidMount = async () => {
    const userId = this.props.match.params.userId;
    if (!userId) {
      this.props.history.goBack();
      return;
    }
    customFunctions.setTitle('Orders');
    this.setState({ userId }, () => {
      this.getOrders(1);
    })
  }

  getOrders = async (currentPage) => {
    const { keyword, limit, userId } = this.state;
    const pageNo = currentPage || 1;
    const endPoint = (keyword !== '') ? `${Api.getUsersOrders}/${userId}/${pageNo}/${limit}?keyword=${keyword}` : `${Api.getUsersOrders}/${userId}/${pageNo}/${limit}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data) {
      this.setState({
        orders: data.data,
        totalCount: data.totalcount,
        currentPage: pageNo
      }, () => {
        this.setTableData();
      });
    } else {
      NotificationManager.error(message);
    }
  }

  setTableData = () => {
    const { orders } = this.state;
    const rows = [];
    orders.map((order) => { // eslint-disable-line
      rows.push({
        oid: `#${order.shipmentid}`,
        customer: order.customername,
        merchant: order.storename,
        merchantcontact: order.vendornumber || '-',
        custcontact: order.shipmobilenumber || '-',
        driver: order.drivername || '-',
        drivercontact: order.drivercontact || '-',
        timeslot: order.timeslot,
        status: order.deliverystatus,
        actions: (
          <React.Fragment>
            <a href="/" onClick={(e) => {
              e.preventDefault(); e.stopPropagation();
              this.props.history.push(`/order-view/${order.awbnumber}`)
            }}>
              <i className="mdi mdi-eye" id="editDriver" title="View order"></i>
            </a>
            <a href="/" className="m-l-10" onClick={(e) => {
              e.preventDefault(); e.stopPropagation();
              this.props.history.push(`/track-shipment/${order.awbnumber}`)
            }}>
              <i className="mdi mdi-clipboard-text" id="trackShipment" title="Track shipment"></i>
            </a>
          </React.Fragment>
        )
      })
    })
    this.setState({ showLoader: false, rows });
    this.forceUpdate();
  }


  onSearchSubmit = (e) => {
    e.preventDefault();
    this.getOrders(1);
  }

  clearSearch = () => {
    this.setState({ keyword: '' }, () => {
      this.getOrders();
    })
  }

  render() {
    const { rows, showDelete, keyword, totalCount, currentPage, limit } = this.state;
    return (
      <AUX>
        <div className="page-content-wrapper">
          <NotificationContainer />
          <div className="container-fluid">
            {
              showDelete && (
                <SweetAlert
                  title="Are you sure, you want to delete?"
                  error
                  showCancel
                  confirmBtnBsStyle="success"
                  cancelBtnBsStyle="danger"
                  onConfirm={() => { this.deteleDriver() }}
                  onCancel={() => { this.setState({ showDelete: false, driverId: null }) }} >
                  You won't be able to revert this!
                </SweetAlert>
              )
            }
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <form onSubmit={this.onSearchSubmit}>
                      <div className="form-group row">
                        <div className="col-sm-5">
                          <input className="form-control" value={keyword} type="text" placeholder="Search here" id="example-text-input" onChange={(e) => { this.setState({ keyword: e.target.value }) }} required={true} />
                        </div>
                        <div className="col-sm-3">
                          <button type="submit" className="btn btn-primary waves-effect waves-light">
                            Search
                        </button>
                          <button type="reset" onClick={() => { this.clearSearch(); }} className="btn btn-secondary waves-effect m-l-10">
                            Cancel
                        </button>
                        </div>
                      </div>
                    </form>
                    <MDBDataTable
                      // striped
                      paging={false}
                      bordered
                      hover
                      data={{ columns, rows }}
                      searching={false}
                    />
                    <div className="pagination">
                      <Pagination
                        totalCount={totalCount}
                        currentPage={currentPage}
                        limit={limit}
                        getData={this.getOrders}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </AUX>
    );
  }
}

export default withRouter(Orders);   