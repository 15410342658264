import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { withRouter } from 'react-router-dom';
import { MDBDataTable } from 'mdbreact';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import Pagination from '../../../components/Pagination';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import customFunctions from '../../../helper/customFunctions';
import { Row, Col, Button } from 'react-bootstrap';
import AddSubMerchant from './addSubMerchant';

const columns = [
  {
    label: 'Name',
    field: 'name',
    sort: 'disabled',
    // width: 150
  },
  {
    label: 'Email',
    field: 'email',
    sort: 'disabled',
    // width: 270
  },
  {
    label: 'Mobile',
    field: 'mobile',
    sort: 'disabled',
    // width: 200
  }
];

class SubVendorsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      merchants: [],
      rows: [],
      merchantId: null,
      showDelete: false,
      keyword: '',
      page: 1,
      limit: 10,
      totalCount: 0,
      currentPage: 1,
      countryid: '',
      cityid: '',
      cities: [],
      showCountryCityFilter: true,
      hasDetails: true,
      addressList: [],
      userId: null,
      subMerchantPopup: false,
    }
  }

  componentDidMount = async () => {
    customFunctions.setTitle('Sub Merchants');
    const vendorId = this.props.match.params.merchantId;
    if (!vendorId) {
      this.props.history.push('/dashboard');
      return;
    }
    this.getMerchantAddresses(vendorId);
    this.setState({ vendorId }, () => {
      this.getSubvendorList();
    });
  }

  getSubvendorList = async (currentPage) => {
    const { vendorId } = this.state;
    const pageNo = currentPage || 1;
    const endPoint = `${Api.subVendorList}?vendorid=${vendorId}`;
    const callback = AuthApi.getDataFromServer;

    const { data, message, status } = await callback(endPoint);
    if (data) {
      this.setState({
        merchants: data.data.subVendors,
        totalCount: data.totalcount,
        currentPage: pageNo
      }, () => {
        this.setTableData();
      });
    } else {
      if (status === 401) {
        this.props.history.push('/login');
        return;
      }
      NotificationManager.error(message);
    }
  }

  setTableData = () => {
    const { merchants } = this.state;
    const rows = [];
    merchants.map((merchant) => {
      rows.push({
        name: `${merchant.vendorname}`,
        email: merchant.email ? `${merchant.email}` : '-',
        mobile: merchant.mobilenumber ? `${merchant.mobilenumber}` : '-',
      })
      return merchant;
    })
    this.setState({ showLoader: false, rows });
    this.forceUpdate();
  }

  getMerchantAddresses = async (vendorId) => {
    const { data } = await AuthApi.getDataFromServer(`${Api.getVendorAddress}/${vendorId}`);
    if (data && data.status !== "Fail") {
      this.setState({ addressList: data.data });
    }
  }

  render() {
    const { rows, limit, totalCount, currentPage, addressList, subMerchantPopup, vendorId } = this.state;

    return (
      <AUX>
        <div className="page-content-wrapper">
          <NotificationContainer />
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <div className="mb-5">
                      <Row>
                        <Col>
                          {
                            addressList.length > 0 && (
                              <>
                                <h5>
                                  Store name: {addressList.length > 0 ? addressList[0].pickuplocationname : '-'}
                                </h5>
                                <label>Address:</label> {addressList.length > 0 ? addressList[0].pickuplocationaddress1 : '-'}
                              </>
                            )
                          }
                        </Col>
                        <Col>
                          <Button
                            style={{ float: 'right' }}
                            onClick={() => this.setState({ subMerchantPopup: true })}
                          >
                            Add Sub Merchants
                          </Button>
                        </Col>
                      </Row>
                    </div>

                    <MDBDataTable
                      // striped
                      noBottomColumns={true}
                      paging={false}
                      bordered
                      hover
                      responsive
                      data={{ columns, rows }}
                      searching={false}
                    />
                    <div className="pagination">
                      <Pagination
                        totalCount={totalCount}
                        currentPage={currentPage}
                        limit={limit}
                        getData={this.getSubvendorList}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {
          subMerchantPopup && (
            <AddSubMerchant
              subMerchantPopup={subMerchantPopup}
              vendorId={vendorId}
              callbackfun={() => {
                this.getSubvendorList();
              }}
              popupClose={() => this.setState({ subMerchantPopup: !subMerchantPopup })}
            />
          )
        }
      </AUX >
    );
  }
}

export default withRouter(SubVendorsList);
