const isoCodes = [

  { id: 'AL', value: 'AL' },
  { id: 'DZ', value: 'DZ' },
  { id: 'AS', value: 'AS' },
  { id: 'AD', value: 'AD' },
  { id: 'AO', value: 'AO' },
  { id: 'AI', value: 'AI' },
  { id: 'AQ', value: 'AQ' },
  { id: 'AG', value: 'AG' },
  { id: 'AR', value: 'AR' },
  { id: 'AM', value: 'AM' },
  { id: 'AW', value: 'AW' },
  { id: 'AU', value: 'AU' },
  { id: 'AT', value: 'AT' },
  { id: 'AZ', value: 'AZ' },
  { id: 'BS', value: 'BS' },
  { id: 'BH', value: 'BH' },
  { id: 'BD', value: 'BD' },
  { id: 'BB', value: 'BB' },
  { id: 'BY', value: 'BY' },
  { id: 'BE', value: 'BE' },
  { id: 'BZ', value: 'BZ' },
  { id: 'BJ', value: 'BJ' },
  { id: 'BM', value: 'BM' },
  { id: 'BT', value: 'BT' },
  { id: 'BO', value: 'BO' },
  { id: 'BQ', value: 'BQ' },
  { id: 'BA', value: 'BA' },
  { id: 'BW', value: 'BW' },
  { id: 'BV', value: 'BV' },
  { id: 'BR', value: 'BR' },
  { id: 'IO', value: 'IO' },
  { id: 'BN', value: 'BN' },
  { id: 'BG', value: 'BG' },
  { id: 'BF', value: 'BF' },
  { id: 'BI', value: 'BI' },
  { id: 'CV', value: 'CV' },
  { id: 'KH', value: 'KH' },
  { id: 'CM', value: 'CM' },
  { id: 'CA', value: 'CA' },
  { id: 'KY', value: 'KY' },
  { id: 'CF', value: 'CF' },
  { id: 'TD', value: 'TD' },
  { id: 'CL', value: 'CL' },
  { id: 'CN', value: 'CN' },
  { id: 'CX', value: 'CX' },
  { id: 'CC', value: 'CC' },
  { id: 'CO', value: 'CO' },
  { id: 'KM', value: 'KM' },
  { id: 'CD', value: 'CD' },
  { id: 'CG', value: 'CG' },
  { id: 'CK', value: 'CK' },
  { id: 'CR', value: 'CR' },
  { id: 'HR', value: 'HR' },
  { id: 'CU', value: 'CU' },
  { id: 'CW', value: 'CW' },
  { id: 'CY', value: 'CY' },
  { id: 'CZ', value: 'CZ' },
  { id: 'CI', value: 'CI' },
  { id: 'DK', value: 'DK' },
  { id: 'DJ', value: 'DJ' },
  { id: 'DM', value: 'DM' },
  { id: 'DO', value: 'DO' },
  { id: 'EC', value: 'EC' },
  { id: 'EG', value: 'EG' },
  { id: 'SV', value: 'SV' },
  { id: 'GQ', value: 'GQ' },
  { id: 'ER', value: 'ER' },
  { id: 'EE', value: 'EE' },
  { id: 'SZ', value: 'SZ' },
  { id: 'ET', value: 'ET' },
  { id: 'FK', value: 'FK' },
  { id: 'FO', value: 'FO' },
  { id: 'FJ', value: 'FJ' },
  { id: 'FI', value: 'FI' },
  { id: 'FR', value: 'FR' },
  { id: 'GF', value: 'GF' },
  { id: 'PF', value: 'PF' },
  { id: 'TF', value: 'TF' },
  { id: 'GA', value: 'GA' },
  { id: 'GM', value: 'GM' },
  { id: 'GE', value: 'GE' },
  { id: 'DE', value: 'DE' },
  { id: 'GH', value: 'GH' },
  { id: 'GI', value: 'GI' },
  { id: 'GR', value: 'GR' },
  { id: 'GL', value: 'GL' },
  { id: 'GD', value: 'GD' },
  { id: 'GP', value: 'GP' },
  { id: 'GU', value: 'GU' },
  { id: 'GT', value: 'GT' },
  { id: 'GG', value: 'GG' },
  { id: 'GN', value: 'GN' },
  { id: 'GW', value: 'GW' },
  { id: 'GY', value: 'GY' },
  { id: 'HT', value: 'HT' },
  { id: 'HM', value: 'HM' },
  { id: 'VA', value: 'VA' },
  { id: 'HN', value: 'HN' },
  { id: 'HK', value: 'HK' },
  { id: 'HU', value: 'HU' },
  { id: 'IS', value: 'IS' },
  { id: 'IN', value: 'IN' },
  { id: 'ID', value: 'ID' },
  { id: 'IR', value: 'IR' },
  { id: 'IQ', value: 'IQ' },
  { id: 'IE', value: 'IE' },
  { id: 'IM', value: 'IM' },
  { id: 'IL', value: 'IL' },
  { id: 'IT', value: 'IT' },
  { id: 'JM', value: 'JM' },
  { id: 'JP', value: 'JP' },
  { id: 'JE', value: 'JE' },
  { id: 'JO', value: 'JO' },
  { id: 'KZ', value: 'KZ' },
  { id: 'KE', value: 'KE' },
  { id: 'KI', value: 'KI' },
  { id: 'KP', value: 'KP' },
  { id: 'KR', value: 'KR' },
  { id: 'KW', value: 'KW' },
  { id: 'KG', value: 'KG' },
  { id: 'LA', value: 'LA' },
  { id: 'LV', value: 'LV' },
  { id: 'LB', value: 'LB' },
  { id: 'LS', value: 'LS' },
  { id: 'LR', value: 'LR' },
  { id: 'LY', value: 'LY' },
  { id: 'LI', value: 'LI' },
  { id: 'LT', value: 'LT' },
  { id: 'LU', value: 'LU' },
  { id: 'MO', value: 'MO' },
  { id: 'MG', value: 'MG' },
  { id: 'MW', value: 'MW' },
  { id: 'MY', value: 'MY' },
  { id: 'MV', value: 'MV' },
  { id: 'ML', value: 'ML' },
  { id: 'MT', value: 'MT' },
  { id: 'MH', value: 'MH' },
  { id: 'MQ', value: 'MQ' },
  { id: 'MR', value: 'MR' },
  { id: 'MU', value: 'MU' },
  { id: 'YT', value: 'YT' },
  { id: 'MX', value: 'MX' },
  { id: 'FM', value: 'FM' },
  { id: 'MD', value: 'MD' },
  { id: 'MC', value: 'MC' },
  { id: 'MN', value: 'MN' },
  { id: 'ME', value: 'ME' },
  { id: 'MS', value: 'MS' },
  { id: 'MA', value: 'MA' },
  { id: 'MZ', value: 'MZ' },
  { id: 'MM', value: 'MM' },
  { id: 'NA', value: 'NA' },
  { id: 'NR', value: 'NR' },
  { id: 'NP', value: 'NP' },
  { id: 'NL', value: 'NL' },
  { id: 'NC', value: 'NC' },
  { id: 'NZ', value: 'NZ' },
  { id: 'NI', value: 'NI' },
  { id: 'NE', value: 'NE' },
  { id: 'NG', value: 'NG' },
  { id: 'NU', value: 'NU' },
  { id: 'NF', value: 'NF' },
  { id: 'MP', value: 'MP' },
  { id: 'NO', value: 'NO' },
  { id: 'OM', value: 'OM' },
  { id: 'PK', value: 'PK' },
  { id: 'PW', value: 'PW' },
  { id: 'PS', value: 'PS' },
  { id: 'PA', value: 'PA' },
  { id: 'PG', value: 'PG' },
  { id: 'PY', value: 'PY' },
  { id: 'PE', value: 'PE' },
  { id: 'PH', value: 'PH' },
  { id: 'PN', value: 'PN' },
  { id: 'PL', value: 'PL' },
  { id: 'PT', value: 'PT' },
  { id: 'PR', value: 'PR' },
  { id: 'QA', value: 'QA' },
  { id: 'MK', value: 'MK' },
  { id: 'RO', value: 'RO' },
  { id: 'RU', value: 'RU' },
  { id: 'RW', value: 'RW' },
  { id: 'RE', value: 'RE' },
  { id: 'BL', value: 'BL' },
  { id: 'SH', value: 'SH' },
  { id: 'KN', value: 'KN' },
  { id: 'LC', value: 'LC' },
  { id: 'MF', value: 'MF' },
  { id: 'PM', value: 'PM' },
  { id: 'VC', value: 'VC' },
  { id: 'WS', value: 'WS' },
  { id: 'SM', value: 'SM' },
  { id: 'ST', value: 'ST' },
  { id: 'SA', value: 'SA' },
  { id: 'SN', value: 'SN' },
  { id: 'RS', value: 'RS' },
  { id: 'SC', value: 'SC' },
  { id: 'SL', value: 'SL' },
  { id: 'SG', value: 'SG' },
  { id: 'SX', value: 'SX' },
  { id: 'SK', value: 'SK' },
  { id: 'SI', value: 'SI' },
  { id: 'SB', value: 'SB' },
  { id: 'SO', value: 'SO' },
  { id: 'ZA', value: 'ZA' },
  { id: 'GS', value: 'GS' },
  { id: 'SS', value: 'SS' },
  { id: 'ES', value: 'ES' },
  { id: 'LK', value: 'LK' },
  { id: 'SD', value: 'SD' },
  { id: 'SR', value: 'SR' },
  { id: 'SJ', value: 'SJ' },
  { id: 'SE', value: 'SE' },
  { id: 'CH', value: 'CH' },
  { id: 'SY', value: 'SY' },
  { id: 'TW', value: 'TW' },
  { id: 'TJ', value: 'TJ' },
  { id: 'TZ', value: 'TZ' },
  { id: 'TH', value: 'TH' },
  { id: 'TL', value: 'TL' },
  { id: 'TG', value: 'TG' },
  { id: 'TK', value: 'TK' },
  { id: 'TO', value: 'TO' },
  { id: 'TT', value: 'TT' },
  { id: 'TN', value: 'TN' },
  { id: 'TR', value: 'TR' },
  { id: 'TM', value: 'TM' },
  { id: 'TC', value: 'TC' },
  { id: 'TV', value: 'TV' },
  { id: 'UG', value: 'UG' },
  { id: 'UA', value: 'UA' },
  { id: 'AE', value: 'AE' },
  { id: 'GB', value: 'GB' },
  { id: 'UM', value: 'UM' },
  { id: 'US', value: 'US' },
  { id: 'UY', value: 'UY' },
  { id: 'UZ', value: 'UZ' },
  { id: 'VU', value: 'VU' },
  { id: 'VE', value: 'VE' },
  { id: 'VN', value: 'VN' },
  { id: 'VG', value: 'VG' },
  { id: 'VI', value: 'VI' },
  { id: 'WF', value: 'WF' },
  { id: 'EH', value: 'EH' },
  { id: 'YE', value: 'YE' },
  { id: 'ZM', value: 'ZM' },
  { id: 'ZW', value: 'ZW' },
  { id: 'AX', value: 'AX' }
];

export default isoCodes;