import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import { MDBDataTable } from 'mdbreact';
import Pagination from '../../../components/Pagination';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import customFunctions from '../../../helper/customFunctions';
import SweetAlert from 'react-bootstrap-sweetalert';

const columns = [
  {
    label: 'Country',
    field: 'country',
    sort: 'disabled',
    // width: 150
  },
  {
    label: 'City',
    field: 'city',
    sort: 'disabled',
    // width: 150
  },
  {
    label: 'Start Range',
    field: 'startrange',
    sort: 'disabled',
    // width: 200
  },
  {
    label: 'End Range',
    field: 'endrange',
    sort: 'disabled',
    // width: 200
  },
  {
    label: 'Task fee',
    field: 'taskfee',
    sort: 'disabled',
    // width: 200
  },
  {
    label: 'Action',
    field: 'actions',
    sort: 'disabled'
  }
];


class TaskFeeCharges extends Component {
  constructor(props) {
    super(props);
    this.state = {
      taskfeeCharges: [],
      rows: [],
      page: 1,
      skip: 0,
      limit: 10,
      totalCount: 0,
      currentPage: 1,
      chargeId: null,
      showDelete: false,
      countryid: '',
      cityid: '',
      countries: [],
      cities: [],
    }
  }

  componentDidMount = async () => {
    customFunctions.setTitle('Task Fee Charges');
    this.getCountries();
    this.getTaskFeeCharges(1);
  }

  getTaskFeeCharges = async (currentPage) => {
    const { limit, cityid } = this.state;
    const pageNo = currentPage || 0;
    const endPoint = `${Api.getTaskFee}?skip=${pageNo}&limit=${limit}&cityid=${cityid ? cityid
      : ''}`;
    const callback = AuthApi.getDataFromServer;
    const { data, message } = await callback(endPoint);
    if (data) {
      this.setState({
        taskfeeCharges: data.data,
        totalCount: data.totalcount,
        currentPage: pageNo
      }, () => {
        this.setTableData();
      });
    } else {
      NotificationManager.error(message);
    }
  }

  getCountries = async () => {
    const { data } = await AuthApi.getDataFromServer(Api.getCountries);
    if (data && data.status !== "Fail") {
      this.setState({ countries: data.data });
    }
  }

  getCities = async (countryid) => {
    const { data } = await AuthApi.getDataFromServer(`${Api.getCities}/${countryid}`);
    if (data && data.status !== "Fail") {
      this.setState({ cities: data.data });
    }
  }

  setTableData = () => {
    const { taskfeeCharges } = this.state;
    const rows = [];
    taskfeeCharges.map((charges) => { // eslint-disable-line
      rows.push({
        taskfeeid: charges.taskfeeid,
        country: charges.countryname_eng,
        city: charges.cityname_eng,
        taskfee: charges.taskfee ? `$${charges.taskfee}` : '-',
        startrange: charges.startrange,
        endrange: charges.endrange,
        cityid: charges.cityid,
        actions: (
          <React.Fragment>
            <a href="/" onClick={(e) => {
              e.preventDefault(); e.stopPropagation();
              this.props.history.push(`/edit-taskfee/${charges.cityid}`, {
                editFlag: true
              })
            }}>
              <i className="mdi mdi-lead-pencil" id="editDriver" title="Edit task fee"></i>
            </a>
            <a href="/" className="m-l-10" onClick={(e) => {
              e.preventDefault(); e.stopPropagation();
              this.setState({ showDelete: true, chargeId: charges.taskfeeid })
            }}>
              <i className="mdi mdi-delete text-danger" title="Delete task fee"></i>
            </a>
          </React.Fragment>
        )
      })
    })
    this.setState({ showLoader: false, rows });
    this.forceUpdate();

  }

  deleteCharge = async () => {
    const { chargeId } = this.state;
    const { data, message } = await AuthApi.deleteDataFromServer(`${Api.deleteTaskFee}${chargeId}`);
    if (data) {
      this.setState({ showDelete: false, chargeId: null }, () => {
        this.getTaskFeeCharges(1);
      });
      NotificationManager.success('Task fee deleted');
    } else {
      NotificationManager.error(message);
    }
  }

  onSearchSubmit = (e) => {
    e.preventDefault();
    this.getTaskFeeCharges(1);
  }

  clearSearch = () => {
    this.setState({ keyword: '', cityid: null, countryid: null }, () => {
      this.getTaskFeeCharges(1);
    })
  }

  render() {
    const { rows, showDelete, totalCount, currentPage, limit, countryid, cities, cityid, countries } = this.state;
    return (
      <AUX>
        <div className="page-content-wrapper">
          <NotificationContainer />
          <div className="container-fluid">
            {
              showDelete && (
                <SweetAlert
                  title="Are you sure, you want to delete?"
                  error
                  showCancel
                  confirmBtnBsStyle="success"
                  cancelBtnBsStyle="danger"
                  onConfirm={() => { this.deleteCharge() }}
                  onCancel={() => { this.setState({ showDelete: false, chargeId: null }) }} >
                  You won't be able to revert this!
                </SweetAlert>
              )
            }
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <form onSubmit={this.onSearchSubmit}>
                      <div className="form-group row">
                        <React.Fragment>
                          <div className="col-sm-3">
                            <select className="form-control" value={countryid} onChange={(e) => {
                              this.setState({ countryid: e.target.value, cityid: '' });
                              this.getCities(e.target.value);
                            }}>
                              <option value=''>Select country</option>
                              {
                                countries.map((country) => (
                                  <option value={country.countryid}>{country.countryname_eng}</option>
                                ))
                              }
                            </select>
                          </div>
                          <div className="col-sm-3">
                            <select className="form-control" value={cityid} onChange={(e) => {
                              this.setState({ cityid: e.target.value });
                              // this.getCities(e.target.value);
                            }}>
                              <option value=''>Select city</option>
                              {
                                cities.map((city) => (
                                  <option value={city.cityid}>{city.cityname_eng}</option>
                                ))
                              }
                            </select>
                          </div>
                        </React.Fragment>
                        <div className="col-sm-3">
                          <button type="submit" className="btn btn-primary waves-effect waves-light">
                            Search
                          </button>
                          <button type="reset" onClick={() => { this.clearSearch(); }} className="btn btn-secondary waves-effect m-l-10">
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                    <MDBDataTable
                      // striped
                      noBottomColumns={true}
                      paging={false}
                      bordered
                      hover
                      data={{ columns, rows }}
                      searching={false}
                    />
                    <div className="pagination">
                      <Pagination
                        totalCount={totalCount}
                        currentPage={currentPage}
                        limit={limit}
                        getData={this.getTaskFeeCharges}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

export default TaskFeeCharges;