import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import InputMask from 'react-input-mask';
import MaterialInput from '@material-ui/core/Input';
import customFunctions from '../../../helper/customFunctions';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import 'react-multi-email/style.css';



class AddDriver extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerDeliveryCharges: '',
      merchantDeliveryCharges: '',
      deliveryRadius: '',
      maxShipments: '',
      kurbiFee: '',
      kurbiFeeErr: '',
      emails: [],
      adminMobile: '',
      adminMobileErr: '',
    }
  }

  componentDidMount = () => {
    customFunctions.setTitle('Settings');
    this.getSettings();
  }

  getSettings = async () => {
    const { data, message } = await AuthApi.getDataFromServer(Api.getConfig);
    if (data && data.status === "success" && data.data && data.data.length > 0) {
      this.setState({
        customerDeliveryCharges: data.data[0].customerdeliverycharges,
        merchantDeliveryCharges: data.data[0].vendordeliverycharges,
        deliveryRadius: data.data[0].deliveryradius,
        maxShipments: data.data[0].maxload,
        kurbiFee: data.data[0].kurbicharges,
        emails: data.data[0].bccemail.split(','),
        adminMobile: data.data[0].adminmobile ? String(data.data[0].adminmobile) : ''
      })
    }
  }

  validateForm = (e) => {
    e.preventDefault();
    const { customerDeliveryCharges, merchantDeliveryCharges, deliveryRadius, maxShipments, kurbiFee, emails, adminMobile } = this.state;
    let custDelErr = '', mercDelErr = '', radiusErr = '', maxShipErr = '', kurbiFeeErr = '', emailsErr = '', adminMobileErr = '', isValid = true;

    if (customerDeliveryCharges.trim() === '') {
      custDelErr = 'This field is required'; isValid = false;
    } else if (!customFunctions.validateAmount(customerDeliveryCharges)) {
      custDelErr = 'Invalid input'; isValid = false;
    }

    if (merchantDeliveryCharges.trim() === '') {
      mercDelErr = 'This field is required'; isValid = false;
    } else if (!customFunctions.validateAmount(merchantDeliveryCharges)) {
      mercDelErr = 'Invalid input'; isValid = false;
    }

    if (kurbiFee.trim() === '') {
      kurbiFeeErr = 'This field is required'; isValid = false;
    } else if (!customFunctions.validateAmount(kurbiFee)) {
      kurbiFeeErr = 'Invalid input'; isValid = false;
    }

    if (deliveryRadius.trim() === '') {
      radiusErr = 'This field is required'; isValid = false;
    } else if (!customFunctions.validateAmount(deliveryRadius)) {
      radiusErr = 'Invalid input'; isValid = false;
    }

    if (maxShipments.trim() === '') {
      maxShipErr = 'This field is required'; isValid = false;
    } else if (!customFunctions.validateNumber(maxShipments)) {
      maxShipErr = 'Invalid input'; isValid = false;
    }

    if (emails && emails.length === 0) {
      emailsErr = 'This field is required'; isValid = false;
    }

    if (adminMobile && !customFunctions.validatePhoneNum(adminMobile)) {
      adminMobileErr = 'Invalid mobile number'; isValid = false;
    }


    this.setState({ custDelErr, mercDelErr, radiusErr, maxShipErr, kurbiFeeErr, emailsErr, adminMobileErr });
    if (isValid) this.updateSettings();

  }

  updateSettings = async () => {
    const { customerDeliveryCharges, merchantDeliveryCharges, deliveryRadius, maxShipments, kurbiFee, emails, adminMobile } = this.state;

    const payload = {
      customerdeliverycharges: customerDeliveryCharges,
      vendordeliverycharges: merchantDeliveryCharges,
      deliveryradius: deliveryRadius,
      maxload: maxShipments,
      kurbicharges: kurbiFee,
      bccemail: emails.join(','),
      adminmobile: adminMobile
    };

    const { data, message } = await AuthApi.postDataToServer(Api.saveConfig, payload);
    if (data && data.status !== "Fail") {
      NotificationManager.success('Settings updated');
    } else {
      NotificationManager.error((data && data.message) || message || 'Please try again later');
    }
  }

  render() {
    const {
      customerDeliveryCharges, merchantDeliveryCharges, deliveryRadius, maxShipments, kurbiFee, emails,
      custDelErr, mercDelErr, radiusErr, maxShipErr, kurbiFeeErr, emailsErr, adminMobile, adminMobileErr
    } = this.state;
    console.log('emails', emails);
    return (
      <AUX>
        <div className="page-content-wrapper">
          <NotificationContainer />
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <form onSubmit={this.validateForm}>
                      <div className="form-group row">
                        <label for="example-delivery-charges" className="col-sm-2 col-form-label">Delivery charges for customer*</label>
                        <div className="col-sm-7">
                          <div className="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text" id="basic-addon1">$</span>
                            </div>
                            <input className="form-control" type="text" id="example-delivery-charges" value={customerDeliveryCharges} onChange={(e) => {
                              if (customFunctions.validateAmount(e.target.value) || e.target.value == '') {
                                this.setState({ customerDeliveryCharges: e.target.value });
                              }
                            }} />
                          </div>
                          <span id="err">{custDelErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label for="example-search-input" className="col-sm-2 col-form-label">Delivery charges for Merchant*</label>
                        <div className="col-sm-7">
                          <div className="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text" id="basic-addon1">$</span>
                            </div>
                            <input className="form-control" type="text" id="example-search-input" value={merchantDeliveryCharges} onChange={(e) => {
                              if (customFunctions.validateAmount(e.target.value) || e.target.value == '') {
                                this.setState({ merchantDeliveryCharges: e.target.value });
                              }
                            }} />
                          </div>
                          <span id="err">{mercDelErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label for="kurbi-fee-input" className="col-sm-2 col-form-label">Kurbi fee*</label>
                        <div className="col-sm-7">
                          <div className="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text" id="basic-addon1">$</span>
                            </div>
                            <input className="form-control" type="text" id="kurbi-fee-input" value={kurbiFee} onChange={(e) => {
                              if (customFunctions.validateAmount(e.target.value) || e.target.value == '') {
                                this.setState({ kurbiFee: e.target.value });
                              }
                            }} />
                          </div>
                          <span id="err">{kurbiFeeErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label for="example-radius-input" className="col-sm-2 col-form-label">Delivery Radius*</label>
                        <div className="col-sm-7">
                          <input className="form-control" type="text" id="example-radius-input" value={deliveryRadius} onChange={(e) => {
                            if (customFunctions.validateNumber(e.target.value) || e.target.value == '') {
                              this.setState({ deliveryRadius: e.target.value })
                            }
                          }} />
                          <span id="err">{radiusErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label for="example-max-shipments" className="col-sm-2 col-form-label">Max shipments per driver*</label>
                        <div className="col-sm-7">
                          <input className="form-control" type="text" id="example-max-shipments" value={maxShipments} onChange={(e) => {
                            if (customFunctions.validateNumber(e.target.value) || e.target.value == '') {
                              this.setState({ maxShipments: e.target.value });
                            }
                          }} />
                          <span id="err">{maxShipErr}</span>
                        </div>
                      </div>
                      {/* <div className="form-group row">
                        <label for="example-max-shipments" className="col-sm-2 col-form-label">Max capacity per driver*</label>
                        <div className="col-sm-7">
                          <input className="form-control" type="text" id="example-max-shipments" />
                        </div>
                      </div> */}
                      <div className="form-group row">
                        <label for="example-max-shipments" className="col-sm-2 col-form-label">BCC Emails*</label>
                        <div className="col-sm-7">
                          <ReactMultiEmail
                            placeholder="BCC emails"
                            emails={emails}
                            onChange={(_emails) => {
                              this.setState({ emails: _emails });
                            }}
                            validateEmail={email => {
                              return isEmail(email); // return boolean
                            }}
                            getLabel={(
                              email,
                              index,
                              removeEmail = (index) => { },
                            ) => {
                              return (
                                <div data-tag key={index}>
                                  {email}
                                  <span data-tag-handle onClick={() => removeEmail(index)}>
                                    ×
                                  </span>
                                </div>
                              );
                            }}
                          />
                          <span id="err">{emailsErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label for="example-delivery-charges" className="col-sm-2 col-form-label">Admin mobile*</label>
                        <div className="col-sm-7">
                          <div className="input-group">
                            <input className="form-control" type="text" id="admin-mobile-input" value={adminMobile} onChange={(e) => {
                              this.setState({ adminMobile: e.target.value });
                            }} />
                          </div>
                          <span id="err">{adminMobileErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-sm-3">
                          <button type="submit" className="btn btn-primary waves-effect waves-light">
                            Update Settings
                        </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

export default withRouter(AddDriver);   