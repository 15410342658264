import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { withRouter } from 'react-router-dom';
import customFunctions from '../../../helper/customFunctions';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';

class CustomerAddresses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addressList: [],
      customerDetails: {}
    };
  }

  componentDidMount = () => {
    customFunctions.setTitle('Addresses');
    const customerId = this.props.match.params.customerId;
    if (!customerId) {
      this.props.history.push('/customers');
      return;
    }
    this.getCustomerDetails(customerId);
    this.getCustomerAddresses(customerId);
  }

  getCustomerDetails = async (customerId) => {
    const { data } = await AuthApi.getDataFromServer(`${Api.getCustomer}${customerId}`);
    if (data && data.data && data.status !== "Fail") {
      this.setState({ customerDetails: data.data });
    } else {
      this.props.history.push('/customers');
    }
  }

  getCustomerAddresses = async (customerId) => {
    const { data } = await AuthApi.getDataFromServer(`${Api.getCustomerAddress}/${customerId}`);
    if (data && data.status !== "Fail") {
      this.setState({ addressList: data.data });
    }
  }

  render() {
    const { customerDetails, addressList } = this.state;
    return (
      <AUX>
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <div className="card">
              <div className="card-body">
                <h6 className="">Customer details</h6>
                <div className="form-group row">
                  <div className="col-sm-2">
                    <label>Name:</label>
                  </div>
                  <div className="col-sm-10">
                    <p>{customerDetails.first_name} {customerDetails.last_name}</p>
                  </div>
                  <div className="col-sm-2">
                    <label>Number:</label>
                  </div>
                  <div className="col-sm-10">
                    <p>{customerDetails.mobile}</p>
                  </div>
                  <div className="col-sm-2">
                    <label>Email:</label>
                  </div>
                  <div className="col-sm-10">
                    <p>{customerDetails.email}</p>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            {
              (addressList.length > 0) ? (
                <div className="row">
                  {
                    addressList.map((address) => (
                      <div className="col-sm-6">
                        <div className="card">
                          <div className="card-body">
                            <div>{address.addressline1} {address.addressline2} {address.addressline3}</div>
                            <div>{address.cityname_eng} {address.countryname_eng} {address.zipcode}</div>
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </div>
              ) : (
                  <div className="card">
                    <div className="card-body">
                      <h6 className="text-center">No addresses available</h6>
                    </div>
                  </div>
                )
            }
          </div>
        </div>
      </AUX>
    );
  }
}

export default withRouter(CustomerAddresses);