import firebase from 'firebase';

const config = {
  apiKey: "AIzaSyDzRHydq1Z8Z73nG8NUNP8ilmkNhEOx7lA",
  authDomain: "getcurb-9d66c.firebaseapp.com",
  databaseURL: "https://getcurb-9d66c.firebaseio.com",
  projectId: "getcurb-9d66c",
  storageBucket: "getcurb-9d66c.appspot.com",
  messagingSenderId: "989093563587",
  appId: "1:989093563587:web:8588f09a33447220f1e676"
};

firebase.initializeApp(config);

export default firebase;