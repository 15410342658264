const currency = [
    { id: 'AD', value: 'AD' },
    { id: 'AT', value: 'AT' },
    { id: 'BE', value: 'BE' },
    { id: 'BG', value: 'BG' },
    { id: 'HR', value: 'HR' },
    { id: 'CY', value: 'CY' },
    { id: 'CZ', value: 'CZ' },
    { id: 'DK', value: 'DK' },
    { id: 'EE', value: 'EE' },
    { id: 'FO', value: 'FO' },
    { id: 'FI', value: 'FI' },
    { id: 'FR', value: 'FR' },
    { id: 'DE', value: 'DE' },
    { id: 'GI', value: 'GI' },
    { id: 'GR', value: 'GR' },
    { id: 'GL', value: 'GL' },
    { id: 'GG', value: 'GG' },
    { id: 'VA', value: 'VA' },
    { id: 'HU', value: 'HU' },
    { id: 'IS', value: 'IS' },
    { id: 'IE', value: 'IE' },
    { id: 'IM', value: 'IM' },
    { id: 'IL', value: 'IL' },
    { id: 'IT', value: 'IT' },
    { id: 'JE', value: 'JE' },
    { id: 'LV', value: 'LV' },
    { id: 'LI', value: 'LI' },
    { id: 'LT', value: 'LT' },
    { id: 'LU', value: 'LU' },
    { id: 'MT', value: 'MT' },
    { id: 'MC', value: 'MC' },
    { id: 'NL', value: 'NL' },
    { id: 'NO', value: 'NO' },
    { id: 'PL', value: 'PL' },
    { id: 'PT', value: 'PT' },
    { id: 'RO', value: 'RO' },
    { id: 'PM', value: 'PM' },
    { id: 'SM', value: 'SM' },
    { id: 'SK', value: 'SK' },
    { id: 'SI', value: 'SI' },
    { id: 'ES', value: 'ES' },
    { id: 'SE', value: 'SE' },
    { id: 'TR', value: 'TR' },
    { id: 'GB', value: 'GB' },
    { id: 'US', value: 'US' },
    { id: 'USD', value: 'USD' },
    { id: 'AED', value: 'AED' },
    { id: 'AFN', value: 'AFN' },
    { id: 'ALL', value: 'ALL' },
    { id: 'AMD', value: 'AMD' },
    { id: 'ANG', value: 'ANG' },
    { id: 'AOA', value: 'AOA' },
    { id: 'ARS', value: 'ARS' },
    { id: 'AUD', value: 'AUD' },
    { id: 'AWG', value: 'AWG' },
    { id: 'AZN', value: 'AZN' },
    { id: 'BAM', value: 'BAM' },
    { id: 'BBD', value: 'BBD' },
    { id: 'BDT', value: 'BDT' },
    { id: 'BGN', value: 'BGN' },
    { id: 'BIF', value: 'BIF' },
    { id: 'BMD', value: 'BMD' },
    { id: 'BND', value: 'BND' },
    { id: 'BOB', value: 'BOB' },
    { id: 'BRL', value: 'BRL' },
    { id: 'BSD', value: 'BSD' },
    { id: 'BWP', value: 'BWP' },
    { id: 'BZD', value: 'BZD' },
    { id: 'CAD', value: 'CAD' },
    { id: 'CDF', value: 'CDF' },
    { id: 'CHF', value: 'CHF' },
    { id: 'CLP', value: 'CLP' },
    { id: 'CNY', value: 'CNY' },
    { id: 'COP', value: 'COP' },
    { id: 'CRC', value: 'CRC' },
    { id: 'CVE', value: 'CVE' },
    { id: 'CZK', value: 'CZK' },
    { id: 'DJF', value: 'DJF' },
    { id: 'DKK', value: 'DKK' },
    { id: 'DOP', value: 'DOP' },
    { id: 'DZD', value: 'DZD' },
    { id: 'EGP', value: 'EGP' },
    { id: 'ETB', value: 'ETB' },
    { id: 'EUR', value: 'EUR' },
    { id: 'FJD', value: 'FJD' },
    { id: 'FKP', value: 'FKP' },
    { id: 'GBP', value: 'GBP' },
    { id: 'GEL', value: 'GEL' },
    { id: 'GIP', value: 'GIP' },
    { id: 'GMD', value: 'GMD' },
    { id: 'GNF', value: 'GNF' },
    { id: 'GTQ', value: 'GTQ' },
    { id: 'GYD', value: 'GYD' },
    { id: 'HKD', value: 'HKD' },
    { id: 'HNL', value: 'HNL' },
    { id: 'HRK', value: 'HRK' },
    { id: 'HTG', value: 'HTG' },
    { id: 'HUF', value: 'HUF' },
    { id: 'IDR', value: 'IDR' },
    { id: 'ILS', value: 'ILS' },
    { id: 'INR', value: 'INR' },
    { id: 'ISK', value: 'ISK' },
    { id: 'JMD', value: 'JMD' },
    { id: 'JPY', value: 'JPY' },
    { id: 'KES', value: 'KES' },
    { id: 'KGS', value: 'KGS' },
    { id: 'KHR', value: 'KHR' },
    { id: 'KMF', value: 'KMF' },
    { id: 'KRW', value: 'KRW' },
    { id: 'KYD', value: 'KYD' },
    { id: 'KZT', value: 'KZT' },
    { id: 'LAK', value: 'LAK' },
    { id: 'LBP', value: 'LBP' },
    { id: 'LKR', value: 'LKR' },
    { id: 'LRD', value: 'LRD' },
    { id: 'LSL', value: 'LSL' },
    { id: 'MAD', value: 'MAD' },
    { id: 'MDL', value: 'MDL' },
    { id: 'MGA', value: 'MGA' },
    { id: 'MKD', value: 'MKD' },
    { id: 'MMK', value: 'MMK' },
    { id: 'MNT', value: 'MNT' },
    { id: 'MOP', value: 'MOP' },
    { id: 'MRO', value: 'MRO' },
    { id: 'MUR', value: 'MUR' },
    { id: 'MVR', value: 'MVR' },
    { id: 'MWK', value: 'MWK' },
    { id: 'MXN', value: 'MXN' },
    { id: 'MYR', value: 'MYR' },
    { id: 'MZN', value: 'MZN' },
    { id: 'NAD', value: 'NAD' },
    { id: 'NGN', value: 'NGN' },
    { id: 'NIO', value: 'NIO' },
    { id: 'NOK', value: 'NOK' },
    { id: 'NPR', value: 'NPR' },
    { id: 'NZD', value: 'NZD' },
    { id: 'PAB', value: 'PAB' },
    { id: 'PEN', value: 'PEN' },
    { id: 'PGK', value: 'PGK' },
    { id: 'PHP', value: 'PHP' },
    { id: 'PKR', value: 'PKR' },
    { id: 'PLN', value: 'PLN' },
    { id: 'PYG', value: 'PYG' },
    { id: 'QAR', value: 'QAR' },
    { id: 'RON', value: 'RON' },
    { id: 'RSD', value: 'RSD' },
    { id: 'RUB', value: 'RUB' },
    { id: 'RWF', value: 'RWF' },
    { id: 'SAR', value: 'SAR' },
    { id: 'SBD', value: 'SBD' },
    { id: 'SCR', value: 'SCR' },
    { id: 'SEK', value: 'SEK' },
    { id: 'SGD', value: 'SGD' },
    { id: 'SHP', value: 'SHP' },
    { id: 'SLL', value: 'SLL' },
    { id: 'SOS', value: 'SOS' },
    { id: 'SRD', value: 'SRD' },
    { id: 'STD', value: 'STD' },
    { id: 'SZL', value: 'SZL' },
    { id: 'THB', value: 'THB' },
    { id: 'TJS', value: 'TJS' },
    { id: 'TOP', value: 'TOP' },
    { id: 'TRY', value: 'TRY' },
    { id: 'TTD', value: 'TTD' },
    { id: 'TWD', value: 'TWD' },
    { id: 'TZS', value: 'TZS' },
    { id: 'UAH', value: 'UAH' },
    { id: 'UGX', value: 'UGX' },
    { id: 'UYU', value: 'UYU' },
    { id: 'UZS', value: 'UZS' },
    { id: 'VND', value: 'VND' },
    { id: 'VUV', value: 'VUV' },
    { id: 'WST', value: 'WST' },
    { id: 'XAF', value: 'XAF' },
    { id: 'XCD', value: 'XCD' },
    { id: 'XOF', value: 'XOF' },
    { id: 'XPF', value: 'XPF' },
    { id: 'YER', value: 'YER' },
    { id: 'ZAR', value: 'ZAR' },
    { id: 'ZMW', value: 'ZMW' }
];
export default currency;