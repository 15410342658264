import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import cookie from 'react-cookies';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import * as actionTypes from '../../../store/action';
import customFunctions from '../../../helper/customFunctions';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      emailErr: '',
      passwordErr: ''
    }
  }

  componentDidMount = async () => {
    if (this.props.loginpage === false) {
      this.props.UpdateLogin();
    }
    window.onpopstate = (e) => {
      this.props.UpdateLoginAgain();
    }
    const token = await cookie.load('token');
    if (token) {
      this.props.UpdateLoginAgain();
      this.props.history.push('/');
    }
  }

  validateForm = (e) => {
    e.preventDefault();
    const { email, password } = this.state;
    let emailErr = '';
    let passwordErr = '';
    let isValid = true;
    if (email === '') {
      emailErr = 'Email is required';
      isValid = false;
    } else if (!customFunctions.validateEmail(email)) {
      emailErr = 'Invalid email';
      isValid = false;
    }

    if (password === '') {
      passwordErr = "Password is required";
      isValid = false;
    }
    this.setState({ emailErr, passwordErr });
    if (isValid) {
      this.loginUser();
    }
  }

  loginUser = async () => {
    const { email, password } = this.state;
    const postData = {
      email,
      password
    };
    const { data, message } = await AuthApi.postDataToServer(Api.loginUrl, postData);
    if (data && data.token) {
      await cookie.save('token', data.token)
      await cookie.save('userdata', data.data)
      await localStorage.setItem('userLoggedIn', 'yes');
      window.location.reload(false)
      // this.props.history.push('/');
    } else {
      NotificationManager.error((data && data.message) || message || 'Something went wrong! Please try again.');
    }
  }

  render() {
    const { email, password, emailErr, passwordErr } = this.state;
    return (
      <AUX>
        <div className="accountbg"></div>
        <div className="wrapper-page">
          <NotificationContainer />

          <div className="card">
            <div className="card-body">

              <h3 className="text-center m-0">
                <Link to="/" onClick={() => this.props.UpdateLoginAgain()} className="logo logo-admin"><img src="assets/images/kurbi.png" height="30" alt="logo" /></Link>
              </h3>

              <div className="p-3">
                <h4 className="font-18 m-b-5 text-center">Welcome Back !</h4>
                <p className="text-muted text-center">Sign in to continue</p>

                <form className="form-horizontal m-t-30" onSubmit={this.validateForm} >

                  <div className="form-group">
                    <label for="username">Username</label>
                    <input type="text" className="form-control" id="username" placeholder="Enter username" value={email} onChange={(e) => { this.setState({ email: e.target.value }) }} />
                    <span id="err">{emailErr}</span>
                  </div>

                  <div className="form-group">
                    <label for="userpassword">Password</label>
                    <input type="password" className="form-control" id="userpassword" placeholder="Enter password" value={password} onChange={(e) => { this.setState({ password: e.target.value }) }} />
                    <span id="err">{passwordErr}</span>
                  </div>

                  <div className="form-group row m-t-20">
                    <div className="col-sm-6">
                      {/* <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" id="customControlInline" />
                        <label className="custom-control-label" for="customControlInline">Remember me</label>
                      </div> */}
                    </div>
                    <div className="col-sm-6 text-right">
                      <button className="btn btn-primary w-md waves-effect waves-light" type="submit">Log In</button>
                    </div>
                  </div>

                  <div className="form-group m-t-10 mb-0 row">
                    <div className="col-12 m-t-20">
                      <Link to="forget-password" className="text-muted"><i className="mdi mdi-lock"></i> Forgot your password?</Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="m-t-40 text-center">
            <p className="text-white">Don't have an account ? <Link to="signin" className="font-500 font-14 text-white font-secondary"> Signup Now </Link> </p>
          </div>
        </div>
      </AUX>
    );
  }
}

const mapStatetoProps = state => {
  return {
    loginpage: state.ui_red.loginpage
  };
}

const mapDispatchtoProps = dispatch => {
  return {
    UpdateLogin: () => dispatch({ type: actionTypes.LOGINPAGE, value: true }),
    UpdateLoginAgain: () => dispatch({ type: actionTypes.LOGINPAGE, value: false })
  };
}

export default connect(mapStatetoProps, mapDispatchtoProps)(Login);
