import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { MDBDataTable } from 'mdbreact';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import Pagination from '../../../components/Pagination';
import SweetAlert from 'react-bootstrap-sweetalert';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import customFunctions from '../../../helper/customFunctions';
import cmsPagesData from '../../../helper/sampledata/cms';

const columns = [
  {
    label: 'Title',
    field: 'title',
    sort: 'disabled',
    // width: 150
  },
  {
    label: 'Status',
    field: 'status',
    sort: 'disabled',
    // width: 270
  },
  {
    label: 'Action',
    field: 'actions',
    sort: 'disabled'
  }
];

class CMS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cmsPages: [],
      rows: [],
      pageId: null,
      showDelete: false,
      keyword: '',
      page: 1,
      limit: 10,
      totalCount: 0,
      currentPage: 1
    }
  }

  componentDidMount = async () => {
    customFunctions.setTitle('All CMS Pages');
    this.getCmsPages(1);
  }

  getCmsPages = async (currentPage) => {
    const { keyword, limit } = this.state;
    const pageNo = currentPage || 1;
    const endPoint = (keyword !== '') ? `${Api.getCmsPages}/${pageNo}/${limit}?keyword=${keyword}` : `${Api.getCmsPages}/${pageNo}/${limit}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data) {
      this.setState({
        cmsPages: data.data,
        totalCount: data.totalcount,
        currentPage: pageNo
      }, () => {
        this.setTableData();
      });
    } else {
      NotificationManager.error(message);
    }
  }

  setTableData = () => {
    const { cmsPages } = this.state;
    const rows = [];
    cmsPages.map((page) => { // eslint-disable-line
      rows.push({
        title: page.pagetitle_eng,
        status: page.status,
        actions: (
          <React.Fragment>
            <a href="/" onClick={(e) => {
              e.preventDefault(); e.stopPropagation();
              this.props.history.push(`/edit-page/${page.id}`)
            }}>
              <i className="mdi mdi-lead-pencil" id="editDriver" title="Edit driver"></i>
            </a>
            <a href="/" className="m-l-10" onClick={(e) => {
              e.preventDefault(); e.stopPropagation();
              this.setState({ showDelete: true, pageId: page.id })
            }}>
              <i className="mdi mdi-delete text-danger" title="Delete driver"></i>
            </a>
          </React.Fragment>
        )
      })
    })
    this.setState({ showLoader: false, rows });
    this.forceUpdate();
  }

  deteleCustomer = async () => {
    const { pageId } = this.state;
    const { data, message } = await AuthApi.deleteDataFromServer(`${Api.getDrivers}/${pageId}`);
    if (data) {
      this.setState({ showDelete: false, pageId: null }, () => {
        this.getCmsPages(1);
      });
      NotificationManager.success('Page deleted');
    } else {
      NotificationManager.error(message);
    }
  }

  onSearchSubmit = (e) => {
    e.preventDefault();
    this.getCmsPages(1);
  }

  clearSearch = () => {
    this.setState({ keyword: '' }, () => {
      this.getCmsPages(1);
    })
  }

  render() {
    const { rows, showDelete, keyword, totalCount, currentPage, limit } = this.state;
    return (
      <AUX>
        <div className="page-content-wrapper">
          <NotificationContainer />
          <div className="container-fluid">
            {
              showDelete && (
                <SweetAlert
                  title="Are you sure, you want to delete?"
                  error
                  showCancel
                  confirmBtnBsStyle="success"
                  cancelBtnBsStyle="danger"
                  onConfirm={() => { this.deteleCustomer() }}
                  onCancel={() => { this.setState({ showDelete: false, pageId: null }) }} >
                  You won't be able to revert this!
                </SweetAlert>
              )
            }
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <form onSubmit={this.onSearchSubmit}>
                      <div className="form-group row">
                        <div className="col-sm-5">
                          <input className="form-control" value={keyword} type="text" placeholder="Search here" id="example-text-input" onChange={(e) => { this.setState({ keyword: e.target.value }) }} required={true} />
                        </div>
                        <div className="col-sm-3">
                          <button type="submit" className="btn btn-primary waves-effect waves-light">
                            Search
                        </button>
                          <button type="reset" onClick={() => { this.clearSearch(); }} className="btn btn-secondary waves-effect m-l-10">
                            Cancel
                        </button>
                        </div>
                      </div>
                    </form>
                    <MDBDataTable
                      // striped
                      noBottomColumns={true}
                      paging={false}
                      bordered
                      hover
                      data={{ columns, rows }}
                      searching={false}
                    />
                    <div className="pagination">
                      <Pagination
                        totalCount={totalCount}
                        currentPage={currentPage}
                        limit={limit}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </AUX>
    );
  }
}

export default withRouter(CMS);   