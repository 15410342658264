import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { withRouter } from 'react-router-dom';
import customFunctions from '../../../helper/customFunctions';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Loader from '../../../components/Loader/index';
import SweetAlert from 'react-bootstrap-sweetalert/lib/dist/SweetAlert';

class BulkUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      merchantId: null,
      merchant: {},
      merchantData: [],
      csvFile: '',
      merchantErr: '',
      csvFileErr: '',
      isShowLoader: false,
      storeContactNumber: null,
      storeAddress: '',
      storeName: '',
      finalCsvFile: '',
      showPopup: false,
    }
  }

  componentDidMount = () => {
    customFunctions.setTitle('Bulk Order Upload');
    this.getMerchantsData();
  }

  getMerchantsData = async (currentPage) => {
    this.setState({
      isShowLoader: true
    })
    let pageNo = 0, limit = 1000;
    const endPoint = `${Api.getMerchants}/${pageNo}/${limit}`;
    const searchPayload = {
      "skip": pageNo,
      "limit": limit
    };
    const callback = AuthApi.getDataFromServer;

    const { data, message, status } = await callback(endPoint, searchPayload);
    if (data) {
      this.setState({
        merchantData: data.data,
        isShowLoader: false,
      });
    } else {
      this.setState({
        isShowLoader: false
      })
      if (status === 401) {
        this.props.history.push('/login');
        return;
      }
      NotificationManager.error(message);
    }
  }

  getMerchant = async (id) => {
    const { data, message } = await AuthApi.getDataFromServer(`${Api.getVendor}${id}`);
    if (data && data.data) {
      this.setState({
        storeContactNumber: data.data.mobile,
        storeName: data.data.vendorname,
        storeAddress: data.data.pickuplocationaddress1,
      });
    } else {
      var $this = this;
      NotificationManager.error(message, '', 1000);
      setTimeout(() => {
        $this.props.history.push('/orders');
      }, 1000);
    }
  }

  validateForm = (e) => {
    e.preventDefault();
    const { csvFile, merchantId } = this.state;
    let csvFileErr = '', merchantErr = '';
    let isValid = true;
    console.log('merchant', merchantId);
    console.log('csv', csvFile);
    if (!merchantId) {
      merchantErr = 'Merchant is required'; isValid = false
    }

    if (csvFile == '') {
      csvFileErr = 'CSV is required'; isValid = false
    }


    this.setState({ csvFileErr, merchantErr });
    if (isValid) {
      this.addOrder()
    }
  }

  addOrder = async () => {
    this.setState({ isShowLoader: true });
    const { merchantId, csvFile } = this.state;
    const formData = new FormData();
    formData.append('upfile', csvFile[0]);
    const callback = AuthApi.postDataToServer;
    const endPoint = `${Api.orderBulkUpload}?vendorid=${merchantId}`;
    const { data, message } = await callback(endPoint, formData);
    var $this = this;
    if (data && data.status !== "Fail") {
      this.setState({
        finalCsvFile: data.file,
        showPopup: true,
        isShowLoader: false
      });
    } else {
      this.setState({ isShowLoader: false });
      NotificationManager.error((data && data.message) || message || 'Please try again later');
    }
  }

  downloadFile = () => {
    const { finalCsvFile } = this.state;
    window.open(finalCsvFile, '_blank');
    this.setState({
      showPopup: false,
    });
    this.props.history.push('/orders');
  }

  onCancel = () => {
    this.setState({
      showPopup: false
    }, () => {
      this.props.history.push('/orders');
    })
  }

  resetData = () => {
    this.setState({
      merchantId: null,
      file: ''
    });
  }

  render() {
    const {
      merchantId, csvFileErr, merchantErr, merchantData, isShowLoader, finalCsvFile, showPopup
    } = this.state;
    return (
      <AUX>
        <div className="page-content-wrapper">
          <NotificationContainer />
          <Loader showLoader={isShowLoader} needFullPage={false} />
          {
            showPopup && (
              <SweetAlert success title="Order added!" onConfirm={(e) => this.downloadFile(e)} showCancel={true} onCancel={this.onCancel}>
                Do you want to download csv file ?
              </SweetAlert>
            )
          }
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <form onSubmit={this.validateForm}>
                      <div className="form-group row">
                        <label for="example-email-input" className="col-sm-2 col-form-label">Merchant*</label>
                        <div className="col-sm-5">
                          <select className="form-control" value={merchantId} onChange={(e) => {
                            this.setState({ merchantId: e.target.value });
                            console.log('e', e.target.value);
                            const result = merchantData.filter((item) => {
                              console.log('item', item);
                              if (e.target.value == item.vendorid) {
                                return item;
                              }
                            });
                            this.setState({
                              merchant: result
                            })
                            this.getMerchant(e.target.value);
                          }}>
                            <option value=''>Select Merchant</option>
                            {
                              merchantData.map((merchant) => (
                                <option value={merchant.vendorid}>{merchant.vendorname}</option>
                              ))
                            }
                          </select>
                          <span id="err">{merchantErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label for="example-text-input" className="col-sm-2 col-form-label">CSV File*</label>
                        <div className="col-sm-5">
                          <input
                            className="form-control"
                            accept=".csv"
                            type="file"
                            placeholder="Symbol"
                            id="sym"
                            onChange={(e) => { this.setState({ csvFile: e.target.files }) }} />
                          <span id="err">{csvFileErr}</span>
                        </div>
                      </div>

                      <div className="form-group row">
                        <div className="col-sm-3">
                          <button type="submit" className="btn btn-primary waves-effect waves-light">
                            Submit
                          </button>
                          <button type="reset" onClick={() => {
                            this.props.history.push('/orders');
                          }} className="btn btn-secondary waves-effect m-l-10">
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

export default withRouter(BulkUpload);   