import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import InputMask from 'react-input-mask';
import MaterialInput from '@material-ui/core/Input';
import customFunctions from '../../../helper/customFunctions';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import CurrencyCodeList from '../../../helper/sampledata/currency';
import isocodes from '../../../helper/sampledata/isocodes';

class AddCountry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      name_a: '',
      code: '',
      currency: '',
      stripe_currency_code: '',
      isactive: 0,
      nameErr: '',
      name_aErr: '',
      codeErr: '',
      currencyErr: '',
      stripe_currency_codeErr: '',
      countryid: null,
      symbol: '',
      isocode: '',
    }
  }

  componentDidMount = () => {
    const countryid = this.props.match.params.countryId;
    this.setState({ countryid });
    if (countryid) {
      this.getCountryData(countryid);
      customFunctions.setTitle('Edit Country');
    } else {
      customFunctions.setTitle('Add Country');
    }
  }

  getCountryData = async (countryid) => {
    const { data, message } = await AuthApi.getDataFromServer(`${Api.getCountryById}/${countryid}`);
    if (data) {
      this.setState({
        name: data.data[0].countryname_eng,
        name_a: data.data[0].countryname_arabic,
        code: data.data[0].countrycode,
        currency: data.data[0].currency || '',
        stripe_currency_code: data.data[0].stripe_currency_code || '',
        isocode: data.data[0].countryisocode || '',
        symbol: data.data[0].currencysymbol || '',
        isactive: data.data[0].isactive,
      })
    } else {
      var $this = this;
      NotificationManager.error(message, '', 1000);
      setTimeout(() => {
        $this.props.history.push('/country-list');
      }, 1000);
    }
  }

  validateForm = (e) => {
    e.preventDefault();
    const { name, name_a, code, currency, stripe_currency_code, isactive, countryid, isocode, symbol } = this.state;
    let nameErr = '', name_aErr = '', codeErr = '', currencyErr = '', stripe_currency_codeErr = '', isocodeErr = '', symbolErr = '';
    let isValid = true;
    if (name.trim() === '') {
      nameErr = 'name is required'; isValid = false
    }

    if (name_a.trim() === '') {
      name_aErr = 'name (Arabic) is required'; isValid = false
    }

    if (String(code).trim() === '') {
      codeErr = 'code is required'; isValid = false;
    }

    if (String(currency).trim() === '') {
      currencyErr = 'currency is required'; isValid = false;
    }

    if (String(stripe_currency_code).trim() === '') {
      stripe_currency_codeErr = 'stripe currency is required'; isValid = false;
    }

    if (String(isocode).trim() === '') {
      isocodeErr = 'ISO code is required'; isValid = false;
    }

    if (String(symbol).trim() === '') {
      symbolErr = 'Currency symbol is required'; isValid = false;
    }

    this.setState({ nameErr, name_aErr, codeErr, currencyErr, stripe_currency_codeErr, isocodeErr, symbolErr });
    if (isValid) {
      this.addCountry()
    }
  }

  addCountry = async () => {
    const { name, name_a, code, currency, stripe_currency_code, isactive, countryid, isocode, symbol } = this.state;
    const postData = {
      countryname_eng: String(name),
      countryname_arabic: String(name_a),
      countrycode: String(code),
      currency: String(currency),
      stripe_currency_code: String(stripe_currency_code),
      countryisocode: isocode,
      currencysymbol: symbol,
    };
    const callback = AuthApi.postDataToServer;
    const endPoint = countryid ? Api.updatecountry : Api.addcountry;
    if (countryid) {
      postData.isactive = parseInt(isactive);
      postData.countryid = parseInt(countryid);
    }
    const { data, message } = await callback(endPoint, postData);
    if (data && data.status !== "Fail") {
      if (countryid) {
        var $this = this;
        NotificationManager.success('Country edited', '', 1000);
        setTimeout(() => {
          $this.props.history.push('/country-list');
        }, 1000);
        return;
      }
      NotificationManager.success('Country added');
      this.resetData();
    } else {
      NotificationManager.error((data && data.message) || message || 'Please try again later');
    }
  }

  resetData = () => {
    this.setState({
      name: '', name_a: '', code: '', currency: '', stripe_currency_code: '', isactive: 1, nameErr: '', name_aErr: '', codeErr: '', currencyErr: '', stripe_currency_codeErr: '', countryid: null, isocode: '', symbol: ''
    });
  }

  render() {
    const {
      name, name_a, code, currency, stripe_currency_code, isactive, nameErr, name_aErr, codeErr, currencyErr, stripe_currency_codeErr, countryid, isocode, symbol, isocodeErr, symbolErr
    } = this.state;
    return (
      <AUX>
        <div className="page-content-wrapper">
          <NotificationContainer />
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <form onSubmit={this.validateForm}>
                      <div className="form-group row">
                        <label for="example-text-input" className="col-sm-2 col-form-label">Name*</label>
                        <div className="col-sm-5">
                          <input className="form-control" value={name} type="text" placeholder="Country" id="name" onChange={(e) => { this.setState({ name: e.target.value }) }} />
                          <span id="err">{nameErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label for="example-search-input" className="col-sm-2 col-form-label">Name(Arabic)*</label>
                        <div className="col-sm-5">
                          <input className="form-control" value={name_a} type="text" placeholder="Country arabic" id="name_a" onChange={(e) => { this.setState({ name_a: e.target.value }) }} />
                          <span id="err">{name_aErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label for="example-email-input" className="col-sm-2 col-form-label">Code*</label>
                        <div className="col-sm-5">
                          <input className="form-control" value={code} type="text" placeholder="Code" id="code" onChange={(e) => {
                            var regex = /^[0-9+-]{1,5}$/
                            var str = e.target.value;
                            var match = str.match(regex);
                            if (match || str.trim() === "") {
                              this.setState({ code: e.target.value })
                            }
                          }} />
                          <span id="err">{codeErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label for="example-email-input" className="col-sm-2 col-form-label">Currency*</label>
                        <div className="col-sm-5">
                          <select className="form-control" value={currency.toLocaleLowerCase()} onChange={(e) => {
                            this.setState({ currency: e.target.value });
                          }}>
                            <option value=''>Select Currency</option>
                            {
                              CurrencyCodeList.map((code) => (
                                <option value={code.id.toLocaleLowerCase()}>{code.value}</option>
                              ))
                            }
                          </select>
                          <span id="err">{currencyErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label for="example-email-input" className="col-sm-2 col-form-label">Stripe Currency Code*</label>
                        <div className="col-sm-5">
                          <select className="form-control" value={stripe_currency_code.toLocaleLowerCase()} onChange={(e) => {
                            this.setState({ stripe_currency_code: e.target.value });
                          }}>
                            <option value=''>Select Stripe Currency</option>
                            {
                              CurrencyCodeList.map((code) => (
                                <option value={code.id.toLocaleLowerCase()}>{code.value}</option>
                              ))
                            }
                          </select>
                          <span id="err">{stripe_currency_codeErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label for="example-email-input" className="col-sm-2 col-form-label">ISO Code*</label>
                        <div className="col-sm-5">
                          <select className="form-control" value={isocode.toLocaleLowerCase()} onChange={(e) => {
                            this.setState({ isocode: e.target.value });
                          }}>
                            <option value=''>Select ISO Code</option>
                            {
                              isocodes.map((code) => (
                                <option value={code.id.toLocaleLowerCase()}>{code.value}</option>
                              ))
                            }
                          </select>
                          <span id="err">{isocodeErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label for="example-text-input" className="col-sm-2 col-form-label">Currency symbol*</label>
                        <div className="col-sm-5">
                          <input className="form-control" value={symbol} type="text" placeholder="Symbol" id="sym" onChange={(e) => { this.setState({ symbol: e.target.value }) }} />
                          <span id="err">{symbolErr}</span>
                        </div>
                      </div>
                      {
                        countryid && (
                          <div className="form-group row">
                            <label className="col-sm-2 col-form-label">Status</label>
                            <div className="col-sm-5">
                              <input type="checkbox" id={`switchIsActive`} switch="info" checked={isactive ? "checked" : ""} onChange={() => {
                                this.setState({ isactive: isactive ? 0 : 1 });
                              }} />
                              <label for={`switchIsActive`} data-on-label="" data-off-label=""></label>
                              <span id="err"></span>
                            </div>
                          </div>
                        )
                      }
                      <div className="form-group row">
                        <div className="col-sm-3">
                          <button type="submit" className="btn btn-primary waves-effect waves-light">
                            Submit
                        </button>
                          <button type="reset" onClick={() => {
                            this.props.history.push('/country-list');
                          }} className="btn btn-secondary waves-effect m-l-10">
                            Cancel
                        </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

export default withRouter(AddCountry);   