import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import InputMask from 'react-input-mask';
import MaterialInput from '@material-ui/core/Input';
import customFunctions from '../../../helper/customFunctions';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
// import { Map, Marker, GoogleApiWrapper, Polygon } from 'google-maps-react';
import Geocode from "react-geocode";
import constants from '../../../constants.js';
import GoogleMapPolygon from '../../../components/GoogleMapPolygon';
import SweetAlert from 'react-bootstrap-sweetalert';

Geocode.setApiKey(constants.mapKey);


class AddCity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      name_a: '',
      isactive: 1,
      nameErr: '',
      name_aErr: '',
      countryErr: '',
      cityid: null,
      countryid: null,
      countries: [],
      autoAllocation: 0,
      geoLocation: '',
      placeInText: '',
      setPickupBound: '',
      cityCoordinates: [],
      polycoordinate: [],
      surcharges: [],
      surchargesObj: {
        startkm: '',
        endkm: '',
        charge: ''
      },
      radius: '',
      amount: '',
      per_km_charge: '',
      surchargeStartkmErr: '',
      surchargeEndkmErr: '',
      surchargeChargeErr: '',
      radiusErr: '',
      amountErr: '',
      per_km_chargeErr: '',
      showDelete: false,
      showSurcharge: false,
      showButton: false,
      isReadOnly: true,
      editIndex: null,
      removeId: null,
    }
    this.mapRef = null;
  }

  componentDidMount = async () => {
    const cityid = this.props.match.params.cityId;
    this.setState({ cityid });
    await this.getCountries();
    if (cityid) {
      this.getSurcharge();
      this.getCityData(cityid);
      customFunctions.setTitle('Edit City');
    } else {
      customFunctions.setTitle('Add City');
    }
  }

  setMapBound = async () => {
    let setboundLoc = this.state.placeInText;
    var latlong = await this.getLatLogFromAddress(setboundLoc);
    if (latlong) {
      let bounds = (latlong.geometry.bounds !== undefined) ? latlong.geometry.bounds : latlong.geometry.viewport;
      var points = [
        bounds.northeast,
        bounds.southwest
      ]
      var boundsArr = new this.props.google.maps.LatLngBounds()
      for (var i = 0; i < points.length; i++) {
        boundsArr.extend(points[i]);
      }
      this.setState({ setPickupBound: boundsArr });
    } else {
      alert('sdsd')
      this.setState({ setPickupBound: '' });
    }
  }

  getLatLogFromAddress = async (address) => {
    var latLng = '';
    if (!address) return '';

    latLng = new Promise(async (resolve, reject) => {
      await Geocode.fromAddress(address).then(
        response => {
          const { lat, lng } = response.results[0].geometry.location;
          resolve(response.results[0]);
        },
        error => {
          console.error('geocode errror', error);
          resolve('');
        }
      );
    });
    return latLng;
  }

  getCountries = async () => {
    const { data } = await AuthApi.getDataFromServer(Api.getCountries);
    if (data && data.status !== "Fail") {
      this.setState({ countries: data.data });
    }
  }

  getCityData = async (cityid) => {
    const { countries } = this.state;
    const { data, message } = await AuthApi.getDataFromServer(`${Api.getCityById}/${cityid}`);
    if (data) {
      const coord = data.data[0].polycoordinate || [];
      const cityCoordinates = [];
      for (let i = 0; i < coord.length; i++) {
        for (let j = 0; j < coord[i][0].length; j++) {
          if (!cityCoordinates[i]) cityCoordinates[i] = [];
          cityCoordinates[i].push({ lat: coord[i][0][j][0], lng: coord[i][0][j][1] })
        }
      }
      const selectedCountry = countries.filter((con) => {
        return Number(con.countryid) === Number(data.data[0].countryid)
      })
      this.setState({
        name: data.data[0].cityname_eng,
        name_a: data.data[0].cityname_arabic,
        countryid: data.data[0].countryid ? data.data[0].countryid : null,
        isactive: data.data[0].isactive,
        autoAllocation: data.data[0].autoallocate_shipment,
        placeInText: `${data.data[0].cityname_eng} ${selectedCountry[0].countryname_eng}`,
        polycoordinate: coord,
        cityCoordinates,
        radius: data.data[0].radius,
        amount: data.data[0].charges,
        per_km_charge: data.data[0].perkmcharges
        // placeInText: 'India'
      })
    } else {
      var $this = this;
      NotificationManager.error(message, '', 1000);
      setTimeout(() => {
        $this.props.history.push('/city-list');
      }, 1000);
    }
  }

  validateForm = (e) => {
    e.preventDefault();
    const { name, name_a, countryid, radius, amount, surcharges, per_km_charge } = this.state;
    let nameErr = '', name_aErr = '', countryErr = '', radiusErr = '', amountErr = '', per_km_chargeErr = '';
    let isValid = true;

    if (name.trim() === '') {
      nameErr = 'name is required'; isValid = false;
    }

    if (name_a.trim() === '') {
      name_aErr = 'name (Arabic) is required'; isValid = false;
    }

    if (String(countryid).trim() === '' || !countryid) {
      countryErr = 'country is required'; isValid = false;
    }

    if (String(radius).trim() === '') {
      radiusErr = 'Radius is required'; isValid = false;
    }

    if (String(amount).trim() === '') {
      amountErr = 'Amount is required'; isValid = false;
    }

    if (radius && !customFunctions.validateFloatNumber(radius)) {
      radiusErr = 'Only numbers are allowed'; isValid = false;
    }

    if (amount && !customFunctions.validateFloatNumber(amount)) {
      amountErr = 'Only numbers are allowed'; isValid = false;
    }

    if (String(per_km_charge).trim() === '') {
      per_km_chargeErr = 'Charge is required'; isValid = false;
    }

    if (per_km_charge && !customFunctions.validateFloatNumber(per_km_charge)) {
      per_km_chargeErr = 'Only numbers are allowed'; isValid = false;
    }

    this.setState({ nameErr, name_aErr, countryErr, radiusErr, amountErr, per_km_chargeErr });
    if (isValid) {
      this.addCity()
    }
  }

  addCity = async () => {
    const { name, name_a, countryid, cityid, isactive, autoAllocation, polycoordinate, radius, amount, per_km_charge } = this.state;
    const postData = {
      cityname_eng: String(name),
      cityname_arabic: String(name_a),
      countryid: parseInt(countryid),
      autoallocate_shipment: autoAllocation,
      polycoordinate,
      radius: parseFloat(radius),
      charges: parseFloat(amount),
      perkmcharges: parseFloat(per_km_charge)
    };
    const callback = AuthApi.postDataToServer;
    const endPoint = cityid ? Api.updatecity : Api.addcity;
    if (cityid) {
      postData.isactive = parseInt(isactive);
      postData.cityid = parseInt(cityid);
    }
    const { data, message } = await callback(endPoint, postData);
    if (data && data.status !== "Fail") {
      var $this = this;
      if (cityid) {
        NotificationManager.success('City edited', '', 800);
        setTimeout(() => {
          $this.props.history.push('/city-list');
        }, 1000);
        return;
      }
      NotificationManager.success('City added', '', 800);
      setTimeout(() => {
        $this.resetData();
      }, 1000)
    } else {
      NotificationManager.error((data && data.message) || message || 'Please try again later');
    }
  }


  onMapClick = (e, marker, event) => {
    // console.log(event.latLng.lat());//e.latLng.lng()
    const { setPickupBound } = this.state;
    const selectedLocation = { lat: event.latLng.lat(), lng: event.latLng.lng() };
    const { google } = this.props;
    var geocoder = new google.maps.Geocoder();
    var $this = this;
    geocoder.geocode({
      location: selectedLocation,
    }, function (results, status) {
      if (status === "OK") {
        $this.setState({ geoLocation: selectedLocation, placeInText: results[0].formatted_address }, () => {
          $this.setMapBound()
        });
      } else {
        window.alert(
          "Geocode was not successful for the following reason: " + status
        );
      }
    });

  }

  getSurcharge = async () => {
    const { cityid } = this.state;
    const { data, message } = await AuthApi.getDataFromServer(`${Api.getSurcharges}?cityid=${cityid}`);
    if (data && data.status !== "Fail") {
      this.setState({ surcharges: data.data })
    } else {
      NotificationManager.error((data && data.message) || message || 'Please try again later');
    }
  }

  removeSurcharge = async (index) => {
    const { editIndex, showDelete, showButton, removeId } = this.state;
    if (showButton) {
      this.setState({ showButton: false, editIndex: null, showDelete: false });
      return;
    }

    const { data, message } = await AuthApi.deleteDataFromServer(`${Api.deleteSurcharges}${removeId}`);
    if (data && data.status !== "Fail") {
      this.setState({ showDelete: false, removeId: null, editIndex: null }, () => {
        this.getSurcharge();
      });
      NotificationManager.success('Surcharge deleted', '', 1000);
    } else {
      this.setState({ showDelete: false });
      NotificationManager.error(message);
    }
  }


  onEditHandler = async (type, id, index) => {
    const { surcharges, editIndex, surchargesObj, cityid } = this.state;

    if (type === 'Edit') {
      await this.setState({ showButton: true, isReadOnly: false, editIndex: index })
    }
    else if (surcharges && editIndex === index) {
      if (!(surcharges[index].startkm) || !(surcharges[index].endkm) || !(surcharges[index].surcharge)) {
        surcharges[index].surchargeStartkmErr = 'Start km is required';
        surcharges[index].surchargeEndkmErr = 'End km is required';
        surcharges[index].surchargeChargekmErr = 'Charge is required';
        this.setState({ surcharges });
        return;
      }

      const allData = [...surcharges];
      const filteredData = allData.filter((data, i) => {
        return (editIndex !== i && ((Number(surcharges[index].startkm) >= Number(data.startkm) && Number(surcharges[index].startkm) <= Number(data.endkm)) || (Number(surcharges[index].endkm) >= Number(data.startkm) && Number(surcharges[index].endkm) <= Number(data.endkm)) || Number(surcharges[index].startkm) === Number(surcharges[index].endkm)))
      });
      if (filteredData.length) {
        surcharges[index].surchargeStartkmErr = "Invalid start km";
        surcharges[index].surchargeEndkmErr = "Invalid end km";
        surcharges[index].surchargeChargekmErr = "Invalid charge km";
        this.setState({ surcharges });
        return;
      }
      this.setState({ surcharges });

      let postData = {};
      surcharges && surcharges.map((surcharge) => {
        postData = {
          id,
          cityid: cityid,
          startkm: surcharges[index].startkm,
          endkm: surcharges[index].endkm,
          surcharge: surcharges[index].surcharge,
        }
      })

      const callback = AuthApi.putDataToServer;
      const endPoint = Api.updateSurcharges;
      const { data, message } = await callback(endPoint, postData);
      if (data && data.status !== "Fail") {
        // this.setState({ surcharges })
        // this.getSurcharge()
        var $this = this;
        NotificationManager.success('Surcharge updated', '', 1000);
        setTimeout(async () => {
          // $this.resetData();
          await this.setState({ showButton: false, editIndex: null })
        }, 1000)
      } else {
        NotificationManager.error((data && data.message) || message || 'Please try again later');
      }
    }
  }

  addSurchargesData = async () => {
    const { surcharges, surchargesObj, cityid } = this.state;
    if (!(surchargesObj.startkm) || !(surchargesObj.endkm) || !(surchargesObj.charge)) {
      surchargesObj.surchargeStartkmErr = 'Start km is required';
      surchargesObj.surchargeEndkmErr = 'End km is required';
      surchargesObj.surchargeChargekmErr = 'Charge is required';
      this.setState({ surchargesObj });
      return;
    }

    const allData = [...surcharges];
    const filteredData = allData.filter((data) => {
      return ((Number(surchargesObj.startkm) >= Number(data.startkm) && Number(surchargesObj.startkm) <= Number(data.endkm)) || (Number(surchargesObj.endkm) >= Number(data.startkm) && Number(surchargesObj.endkm) <= Number(data.endkm)) || Number(surchargesObj.startkm) === Number(surchargesObj.endkm))
    });
    if (filteredData.length) {
      surchargesObj.surchargeStartkmErr = "Invalid start km";
      surchargesObj.surchargeEndkmErr = "Invalid end km";
      surchargesObj.surchargeChargekmErr = "Invalid charge km";
      this.setState({ surchargesObj });
      return;
    }
    this.setState({ surchargesObj });
    const postData = {
      cityid: cityid,
      startkm: surchargesObj.startkm,
      endkm: surchargesObj.endkm,
      surcharge: surchargesObj.charge,
    }
    const callback = AuthApi.postDataToServer;
    const endPoint = Api.addSurcharges;
    const { data, message } = await callback(endPoint, postData);
    if (data && data.status !== "Fail") {
      var $this = this;
      NotificationManager.success('Surcharges added', '', 1000);
      await this.getSurcharge();
      await this.setState({ surchargesObj: { startkm: '', endkm: '', charge: '' } })
    } else {
      NotificationManager.error((data && data.message) || message || 'Please try again later');
    }
  }

  resetData = () => {
    this.setState({
      name: '', name_a: '', cityid: null, nameErr: '', name_aErr: '', countryErr: '', countryid: '', isactive: 1,
      cityCoordinates: [], polycoordinate: [], placeInText: '', radiusErr: '', amountErr: '', surcharges: '', per_km_chargeErr: '', surchargeStartkmErr: '', surchargeEndkmErr: '', surchargeChargeErr: '',
    });
    // this.forceUpdate();
    window.location.reload();
  }

  render() {
    const {
      name, name_a, nameErr, name_aErr, countryErr, countryid, cityid, countries, isactive, autoAllocation,
      setPickupBound, geoLocation, placeInText, cityCoordinates, surcharges, radius, amount, radiusErr,
      amountErr, per_km_charge, per_km_chargeErr, showSurcharge, surchargesObj, showDelete, showButton, editIndex, isReadOnly
    } = this.state;
    return (
      <AUX>
        <div className="page-content-wrapper">
          <NotificationContainer />
          <div className="container-fluid">
            {
              showDelete && (
                <SweetAlert
                  title="Are you sure, you want to delete?"
                  error
                  showCancel
                  confirmBtnBsStyle="success"
                  cancelBtnBsStyle="danger"
                  onConfirm={() => { this.removeSurcharge() }}
                  onCancel={() => { this.setState({ showDelete: false, removeId: null }) }} >
                  You won't be able to revert this!
                </SweetAlert>
              )
            }
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <form onSubmit={this.validateForm}>
                      <div className="form-group row">
                        <label for="example-text-input" className="col-sm-2 col-form-label">Name*</label>
                        <div className="col-sm-5">
                          <input className="form-control" value={name} type="text" placeholder="City" id="name" onChange={(e) => { this.setState({ name: e.target.value }) }} />
                          <span id="err">{nameErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label for="example-search-input" className="col-sm-2 col-form-label">Name(Arabic)*</label>
                        <div className="col-sm-5">
                          <input className="form-control" value={name_a} type="text" placeholder="City arabic" id="name_a" onChange={(e) => { this.setState({ name_a: e.target.value }) }} />
                          <span id="err">{name_aErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label for="example-email-input" className="col-sm-2 col-form-label">Country*</label>
                        <div className="col-sm-5">
                          <select className="form-control" value={countryid} onChange={(e) => {
                            const selectedCountry = countries.filter((c) => {
                              return Number(e.target.value) === Number(c.countryid);
                            })
                            const placeInText = (selectedCountry && selectedCountry.length > 0) ? selectedCountry[0].countryname_eng : '';
                            this.setState({ countryid: e.target.value, placeInText }, () => {
                              // this.setMapBound();
                            });
                          }}>
                            <option value=''>Select country</option>
                            {
                              countries.map((country) => (
                                <option value={country.countryid}>{country.countryname_eng}</option>
                              ))
                            }
                          </select>
                          <span id="err">{countryErr}</span>
                        </div>
                      </div>
                      {
                        cityid && (
                          <div className="form-group row">
                            <label className="col-sm-2 col-form-label">Status</label>
                            <div className="col-sm-5">
                              <input type="checkbox" id={`switchIsActive`} switch="info" checked={isactive ? "checked" : ""} onChange={() => {
                                this.setState({ isactive: isactive ? 0 : 1 });
                              }} />
                              <label for={`switchIsActive`} data-on-label="" data-off-label=""></label>
                              <span id="err"></span>
                            </div>
                          </div>
                        )
                      }
                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label">Auto allocation</label>
                        <div className="col-sm-5">
                          <input type="checkbox" id={`switchAllocation`} switch="info" checked={autoAllocation ? "checked" : ""} onChange={() => {
                            this.setState({ autoAllocation: autoAllocation ? 0 : 1 });
                          }} />
                          <label for={`switchAllocation`} data-on-label="" data-off-label=""></label>
                          <span id="err"></span>
                        </div>
                      </div>
                      <div className="form-group d-flex row">
                        <label className="col-sm-2">Select area</label>
                        <div className="col-sm-5 map-wrapper">
                          {/* <div className="map-wrapper"> */}
                          <GoogleMapPolygon
                            defaultCoordinates={cityCoordinates}
                            inText={placeInText}
                            getPolygons={(polygons) => {
                              this.setState({ polycoordinate: polygons })
                            }}
                          />
                          {/* </div> */}
                        </div>
                      </div>
                      <div>
                        <div className="form-group row">
                          <div className="col-sm-2">
                            <label for="example-search-input" className="col-form-label">Fixed Charges</label>
                          </div>
                          <div className="col-sm-10">
                            <div className="row">
                              <div className="col-md-3">
                                <input className="form-control" value={radius} type="text" placeholder="Radius" id="radius" onChange={(e) => { this.setState({ radius: e.target.value }) }} />
                                <span id="err">{radiusErr}</span>
                              </div>
                              <div className="col-md-3">
                                <input className="form-control" value={amount} type="text" placeholder="Amount" id="amount" onChange={(e) => { this.setState({ amount: e.target.value }) }} />
                                <span id="err">{amountErr}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-sm-2">
                            <label for="example-search-input" className="col-form-label">Per Km Charges</label>
                          </div>
                          <div className="col-sm-10">
                            <div className="row">

                              <div className="col-md-6">
                                <input className="form-control" value={per_km_charge} type="text" placeholder="Charge" id="per_km_charge" onChange={(e) => { this.setState({ per_km_charge: e.target.value }) }} />
                                <span id="err">{per_km_chargeErr}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {cityid ? (
                        <div className="form-group row">
                          <div className="col-sm-2">
                            <label for="example-search-input" className="col-form-label">Surcharge</label>
                          </div>
                          <div className="col-sm-10">
                            {surcharges && surcharges.map((surcharge, index) => (
                              <div className="row m-t-10 p-0">
                                <div className="col-md-2">
                                  <input className="form-control" readOnly={editIndex === index ? false : true} value={surcharge.startkm} type="text" placeholder="Start km" id="surchargeStartkm" onChange={(e) => {
                                    surcharges[index].startkm = e.target.value;
                                    this.setState({ surcharges })
                                  }} />
                                  <span id="err">{surcharges[index].surchargeStartkmErr}</span>
                                </div>
                                <div className="col-md-2">
                                  <input className="form-control" readOnly={editIndex === index ? false : true} value={surcharge.endkm} type="text" placeholder="End km" id="surchargeEndkm" onChange={(e) => {
                                    surcharges[index].endkm = e.target.value;
                                    this.setState({ surcharges })
                                  }} />
                                  <span id="err">{surcharges[index].surchargeEndkmErr}</span>
                                </div>
                                <div className="col-md-2">
                                  <input className="form-control" readOnly={editIndex === index ? false : true} value={surcharge.surcharge} type="text" placeholder="Charge" id="surchargeCharge" onChange={(e) => {
                                    surcharges[index].surcharge = e.target.value;
                                    this.setState({ surcharges })
                                  }
                                  } />
                                  <span id="err">{surcharges[index].surchargeChargekmErr}</span>
                                </div>
                                <div className="col-md-4">
                                  {editIndex === index && (

                                    <button type="button" className="btn btn-primary waves-effect waves-light m-r-5" onClick={() => this.onEditHandler(`Save`, surcharge.id, index)}>
                                      Save </button>
                                  )
                                  }
                                  {editIndex !== index && (
                                    <button type="button" className="btn btn-primary waves-effect waves-light m-r-5" onClick={() => this.onEditHandler(`Edit`, surcharge.id, index)}>
                                      Edit </button>
                                  )
                                  }
                                  {
                                    editIndex !== index && (
                                      <button type="button" className="btn btn-danger waves-effect waves-light"
                                        onClick={(e) => {
                                          e.preventDefault(); e.stopPropagation();
                                          this.setState({ showDelete: true, removeId: surcharge.id, showButton: false, index })
                                        }}>
                                        Remove</button>
                                    )
                                  }
                                  {editIndex === index && (
                                    <button type="button" className="btn btn-danger waves-effect waves-light m-r-5"
                                      onClick={() => { this.removeSurcharge() }}> Cancel
                                    </button>
                                  )
                                  }
                                </div>
                              </div>
                            ))
                            }
                            {
                              !showButton && (
                                <div className="row m-t-10 p-0">
                                  <div className="col-md-2">
                                    <input className="form-control" value={surchargesObj.startkm} type="text" placeholder="Start km" id="surchargeStartkm" onChange={(e) => {
                                      surchargesObj.startkm = e.target.value;
                                      this.setState({ surchargesObj })
                                    }} />
                                    <span id="err">{surchargesObj.surchargeStartkmErr}</span>
                                  </div>
                                  <div className="col-md-2">
                                    <input className="form-control" value={surchargesObj.endkm} type="text" placeholder="End km" id="surchargeEndkm" onChange={(e) => {
                                      surchargesObj.endkm = e.target.value;
                                      this.setState({ surchargesObj })
                                    }} />
                                    <span id="err">{surchargesObj.surchargeEndkmErr}</span>
                                  </div>
                                  <div className="col-md-2">
                                    <input className="form-control" value={surchargesObj.charge} type="text" placeholder="Charge" id="surchargeCharge" onChange={(e) => {
                                      surchargesObj.charge = e.target.value;
                                      this.setState({ surchargesObj })
                                    }
                                    } />
                                    <span id="err">{surchargesObj.surchargeChargekmErr}</span>
                                  </div>
                                  <div className="col-md-4">
                                    <button type="button" className="btn btn-primary waves-effect waves-light m-r-5" onClick={() => this.addSurchargesData()}> Add </button>
                                  </div>
                                </div>
                              )
                            }
                          </div>
                        </div>
                      ) : ''}
                      <div className="form-group row">
                        <div className="col-sm-3">
                          <button type="submit" className="btn btn-primary waves-effect waves-light">
                            Submit
                          </button>
                          <button type="reset" onClick={() => {
                            this.props.history.push('/city-list');
                          }} className="btn btn-secondary waves-effect m-l-10">
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

// export default withRouter(GoogleApiWrapper({
//   // apiKey: 'AIzaSyD46yuo9rWxFv9luFDL8ua6OMdiymeL0fY'
//   apiKey: constants.mapKey
// })(AddCity));

export default AddCity;
