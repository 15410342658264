import React from 'react';

const footer = (props) => (
  <React.Fragment>
    {/* <footer className="footer">
      ©   {new Date().getFullYear() - 1} -   {new Date().getFullYear()} Admiria <span className="text-muted d-none d-sm-inline-block float-right">Crafted with <i className="mdi mdi-heart text-danger"></i> by Themesbrand</span>
    </footer> */}
  </React.Fragment>
);

export default footer;
