import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import cookie from 'react-cookies';
import customFunctions from './../../helper/customFunctions';
import Api from './../../helper/api';
import AuthApi from './../../helper/authApi';
import { Scrollbars } from 'react-custom-scrollbars';
import SharedService from './../../helper/SharedService';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

class header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      dropdownOpen1: false,
      dropdownOpenprofile: false,
      dropdownOpenbadge: false,
      now_route: "",
      countries: [],
      cities: [],
      countryid: '',
      cityid: '',
      notificationCnt: 0
    };

    this.toggle = this.toggle.bind(this);
    this.toggle1 = this.toggle1.bind(this);
    this.toggleprofile = this.toggleprofile.bind(this);
    this.togglebadge = this.togglebadge.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }
  toggle1() {
    this.setState(prevState => ({
      dropdownOpen1: !prevState.dropdownOpen1
    }));
  }
  toggleprofile() {
    this.setState(prevState => ({
      dropdownOpenprofile: !prevState.dropdownOpenprofile
    }));
  }
  togglebadge() {
    this.setState(prevState => ({
      dropdownOpenbadge: !prevState.dropdownOpenbadge
    }));
  }

  notificationRedirect(e) {
    e.preventDefault();
    window.location.href = '/orders';
  }

  togglescreen(e) {
    if (!document.fullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement) {  // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  componentDidMount = async () => {
    this.setState({ now_route: this.props.location.pathname });
    const userData = await customFunctions.getUserData();
    if (!userData) {
      this.redirectToLogin();
      return;
    }
    const countryid = await localStorage.getItem('admincountry');
    const cityid = await localStorage.getItem('admincity');
    this.setState({ countryid, cityid });
    this.getCountries();
    if (countryid) this.getCities(countryid);
    this.getAdminData();
    const isLoggedIn = await localStorage.getItem('userLoggedIn');
    if (isLoggedIn) {
      await localStorage.removeItem('userLoggedIn');
      this.getNotificationsCount(true);
    }
    var $this = this;
    setInterval(async () => {
      await $this.getNotificationsCount()
    }, 120000);
    this.subscribed = SharedService.reloadAdminCities.subscribe(this.reloadAdminCities);
  }

  reloadAdminCities = async () => {
    const userData = await customFunctions.getUserData();
    const countryid = await localStorage.getItem('admincountry');
    if (countryid) this.getCities(countryid, userData.data.adminid);
  }

  getCountries = async () => {
    const { data } = await AuthApi.getDataFromServer(Api.getCountries);
    if (data && data.status !== "Fail") {
      this.setState({ countries: data.data });
    }
  }

  getCities = async (countryid) => {
    const userData = await customFunctions.getUserData();
    const adminId = userData.data.adminid;
    const { data } = await AuthApi.getDataFromServer(`${Api.getAdminCityList}/${adminId}/${countryid}`);
    const cityid = await localStorage.getItem('admincity');
    if (data && data.status !== "Fail") {
      if (cityid) {
        const allCities = data.data;
        const selectedCity = allCities.filter((city) => { return Number(city.cityid) === Number(cityid) });
        if(!selectedCity || selectedCity.length === 0) {
          await localStorage.removeItem('admincity');
          SharedService.cityChanged.next(true);
        }
      }
      this.setState({ cities: data.data });
    }
  }

  getAdminData = async () => {
    const userData = await customFunctions.getUserData();
    const { data } = await AuthApi.getDataFromServer(`${Api.getAdmin}/${userData.data.adminid}`);
    if (!data || !data.data) {
      this.redirectToLogin();
    }
  }

  getNotificationsCount = async (showNotification = false) => {
    const userData = await customFunctions.getUserData();

    if (!userData) {
      this.setState({ notificationCnt: 0 });
      return;
    }

    const { data } = await AuthApi.getDataFromServer(`${Api.notificationCount}/${userData.data.id}`);
    if (data && data.status !== "Fail") {
      this.setState({ notificationCnt: data.totalcount });
      if (showNotification && data.totalcount) {
        NotificationManager.success(`${data.totalcount} orders has been placed since last login`);
      }
    }
  }

  redirectToLogin = async () => {
    const currentUrl = window.location.href;
    if (currentUrl.indexOf('login') > -1) return;
    await cookie.remove('token');
    await cookie.remove('userdata');
    window.location.href = '/login';
  }

  setCityData = async (city) => {
    const { countryid } = this.state;
    await localStorage.setItem('admincity', city);
    await localStorage.setItem('admincountry', countryid);
    SharedService.cityChanged.next(true);
    this.setState({ cityid: city });
  }

  resetFilter = async () => {
    await localStorage.removeItem('admincity');
    await localStorage.removeItem('admincountry');
    SharedService.cityChanged.next(true);
    this.setState({ cityid: '', countryid: '', cities: [] });

  }

  componentWillUnmount = () => {
    if (this.subscribed) this.subscribed.unsubscribe();
  }

  render() {
    const { countries, countryid, cities, cityid, notificationCnt } = this.state;
    return (
      <div className="topbar">
        <NotificationContainer />
        <nav className="navbar-custom">
          <div className="search-wrap" id="search-wrap">
            <div className="search-bar">
              <input className="search-input" type="search" placeholder="Search" />
              <a href="#" className="close-search toggle-search" data-target="#search-wrap">
                <i className="mdi mdi-close-circle"></i>
              </a>
            </div>
          </div>
          <ul className="list-inline float-right mb-0">
            {/* <li className="list-inline-item dropdown notification-list">
              <Dropdown isOpen={this.state.dropdownOpenbadge} toggle={this.togglebadge}>
                <DropdownToggle className="nav-link dropdown-toggle droptest arrow-none waves-effect" tag="a">
                  <i className="ion-ios7-bell noti-icon"></i>
                  <span className="badge badge-pill badge-danger noti-icon-badge">3</span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu dropdown-menu-right dropdown-menu-lg">
                  <h6 className="dropdown-item-text">
                    Notifications (258)
                                        </h6>

                  <DropdownItem >
                    <Scrollbars style={{ height: "200px" }} className="notification-item-list" >
                      <Link id="ex" to="#" className="dropdown-item notify-item active">
                        <div className="notify-icon bg-success"><i className="mdi mdi-cart-outline"></i></div>
                        <p className="notify-details">Your order is placed<span className="text-muted">Dummy text of the printing and typesetting industry.</span></p>
                      </Link>

                      <Link id="ex" to="#" className="dropdown-item notify-item">
                        <div className="notify-icon bg-warning"><i className="mdi mdi-message-text-outline"></i></div>
                        <p className="notify-details">New Message received<span className="text-muted">You have 87 unread messages</span></p>
                      </Link>

                      <Link id="ex" to="#" className="dropdown-item notify-item">
                        <div className="notify-icon bg-info"><i className="mdi mdi-glass-cocktail"></i></div>
                        <p className="notify-details">Your item is shipped<span className="text-muted">It is a long established fact that a reader will</span></p>
                      </Link>

                    </Scrollbars>
                    <Link id="ex" to="#" className="dropdown-item text-center text-primary">
                      View all <i className="fi-arrow-right"></i>
                    </Link>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>

            </li> */}
            <li className="list-inline-item dropdown notification-list">
              <Dropdown isOpen={this.state.dropdownOpenbadge} toggle={this.togglebadge}>
                <DropdownToggle className="nav-link dropdown-toggle droptest arrow-none waves-effect" tag="a" onClick={this.notificationRedirect}>
                  <i className="ion-ios7-bell noti-icon"></i>
                  {
                    notificationCnt > 0 && (<span className="badge badge-pill badge-danger noti-icon-badge">{notificationCnt}</span>)
                  }
                </DropdownToggle>
              </Dropdown>
            </li>
            <li className="list-inline-item dropdown notification-list">
              <Dropdown isOpen={this.state.dropdownOpenprofile} toggle={this.toggleprofile}>
                <DropdownToggle className="nav-link dropdown-toggle droptest arrow-none waves-effect nav-user" tag="a">
                  <img src="/assets/images/users/avatar-4.jpg" alt="user" className="rounded-circle" />
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem>
                    <i className="mdi mdi-account-circle m-r-5"></i>
                    <Link to="/profile">Profile</Link>
                  </DropdownItem>
                  {/* <DropdownItem><i className="mdi mdi-wallet m-r-5"></i> My Wallet</DropdownItem> */}
                  <DropdownItem>
                    <i className="mdi mdi-settings m-r-5"></i>
                    <Link to="/settings">Settings</Link>
                  </DropdownItem>
                  {/* <DropdownItem><i className="mdi mdi-lock-open-outline m-r-5"></i> Lock screen</DropdownItem> */}
                  <DropdownItem><Link to="/login" onClick={async () => {
                    await cookie.remove('token');
                    await cookie.remove('userdata');
                  }}><i className="mdi mdi-power text-danger"></i> Logout</Link></DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </li>
          </ul>
          <ul className="list-inline float-right mb-0 mr-3 mt-4">
            <li className="list-inline-item">
              {
                (countryid && cityid) && (
                  <a href="/" onClick={(e) => {
                    e.preventDefault(); e.stopPropagation();
                    this.resetFilter();
                  }} title="Reset filter" >X</a>
                )
              }
            </li>
          </ul>
          <ul className="list-inline float-right mb-0 mr-3 mt-3">
            <li className="list-inline-item">
              <div className="form-group">
                <select className="form-control" value={cityid} onChange={(e) => {
                  // this.setState({ cityid: e.target.value });
                  let val = e.target.value;
                  if (!val) return;
                  this.setCityData(val);
                }}>
                  <option value="">Select City</option>
                  {
                    cities.map((city) => (
                      <option value={city.cityid}>{city.cityname_eng}</option>
                    ))
                  }
                </select>
              </div>
            </li>
          </ul>
          <ul className="list-inline float-right mb-0 mr-3 mt-3">
            <li className="list-inline-item">
              <div className="form-group">
                <select className="form-control" value={countryid} onChange={(e) => {
                  let val = e.target.value;
                  if (!val) return;
                  this.setState({ countryid: val, cities: [], cityid: '' }, () => {
                    this.getCities(val);
                  });
                }}>
                  <option value="">Select Country</option>
                  {
                    countries.map((country) => (
                      <option value={country.countryid}>{country.countryname_eng}</option>
                    ))
                  }
                </select>
              </div>
            </li>
          </ul>
          <ul className="list-inline menu-left mb-0">
            <li className="list-inline-item">
              <button type="button" className="button-menu-mobile open-left waves-effect">
                <i className="ion-navicon"></i>
              </button>
            </li>
            <li className="hide-phone list-inline-item app-search">
              <h3 className="page-title" id="now_routing"></h3>
            </li>
          </ul>
          <div className="clearfix"></div>
        </nav>
      </div>

    );
  }
}

export default withRouter(header);