import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { withRouter } from 'react-router-dom';
import { MDBDataTable } from 'mdbreact';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import Pagination from '../../../components/Pagination';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import customFunctions from '../../../helper/customFunctions';

const columns = [
  {
    label: 'Order Id',
    field: 'oid',
    sort: 'disabled',
    // width: 150
  },
  {
    label: 'Customer Name',
    field: 'shipfirstname',
    sort: 'disabled',
    // width: 150
  },
  {
    label: 'Shipment Charges',
    field: 'shipmentcharges',
    sort: 'disabled',
    // width: 270
  },
  {
    label: 'Date',
    field: 'date',
    sort: 'disabled',
    // width: 270
  },
  // {
  //   label: 'Action',
  //   field: 'actions',
  //   sort: 'disabled'
  // }
];

class Orders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      rows: [],
      page: 1,
      limit: 10,
      totalCount: 0,
      currentPage: 1,
      merchants: [],
      startDate: '',
      endDate: '',
      vendorId: null,
      vendorDetails: {}
    }
  }

  componentDidMount = async () => {
    customFunctions.setTitle('Order Reports');
    const vendorId = this.props.match.params.vendorId;
    if (!vendorId) {
      this.props.history.push('/dashboard');
      return;
    }
    this.getVendorDetails(vendorId);
    this.setState({
      vendorId
    });
  }

  getOrdersReport = async (currentPage) => {
    const { limit, vendorId, startDate, endDate } = this.state;
    const pageNo = currentPage || 1;
    const skip = (pageNo * limit) - limit;
    const endPoint = `${Api.getVendorReport}?skip=${skip}&limit=${limit}&vendorid=${vendorId}&startdate=${startDate}&enddate=${endDate}`;
    const { data, message } = await AuthApi.getDataFromServer(`${endPoint}`);
    if (data) {
      this.setState({
        orders: data.data.reports,
        totalCount: data.data.reportscount,
        currentPage: pageNo
      }, () => {
        this.setTableData();
      });
    } else {
      NotificationManager.error(message);
    }
  }


  setTableData = () => {
    const { orders } = this.state;
    const rows = [];
    orders.map((order) => { // eslint-disable-line
      rows.push({
        oid: `#${order.awbnumber}`,
        shipfirstname: order.shipfirstname,
        shiplastname: order.shiplastname,
        date: order.orderdate || '-',
        shipmentcharges: `${order.shipmentcharges ? ('$' + order.shipmentcharges) : '-'} `,
        status: order.deliverystatus,
      })
    })
    this.setState({ showLoader: false, rows });
    this.forceUpdate();
  }


  onSearchSubmit = (e) => {
    e.preventDefault();
    this.getOrdersReport(1);
  }

  clearSearch = () => {
    this.setState({ keyword: '', startDate: '', endDate: '', orders: [], totalCount: 0 }, () => {
      this.setTableData();
    });
  }

  exportOrderCsv = async (e) => {
    const { vendorId, startDate, endDate } = this.state;
    const endPoint = `${Api.vendorExportReport}?vendorid=${vendorId}&startdate=${startDate}&enddate=${endDate}`;
    const { data } = await AuthApi.getDataFromServer(`${endPoint}`);
    if (data) {
      const url = window.URL.createObjectURL(new Blob([data.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'order-report.csv'); //or any other extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  getVendorDetails = async (vendorId) => {
    const { data } = await AuthApi.getDataFromServer(`${Api.getVendor}${vendorId}`);
    if (data && data.status !== "Fail") {
      this.setState({ vendorDetails: data.data });
    }
  }

  render() {
    const { rows, totalCount, currentPage, limit, startDate, endDate, vendorDetails } = this.state;
    return (
      <AUX>
        <div className="page-content-wrapper">
          <NotificationContainer />
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <div className="mb-5">
                      {
                        (vendorDetails.vendorid && vendorDetails.pickuplocationname) && (
                          <>
                            <h5>
                              Store name: {vendorDetails.pickuplocationname}
                            </h5>
                            <label>Address:</label> {vendorDetails.pickuplocationaddress1}
                          </>
                        )
                      }
                    </div>
                    <form onSubmit={this.onSearchSubmit}>
                      <div className="form-group row">
                        <div className="col-sm-6">
                          <div className="date-range d-flex">
                            <span className="title">Filter By</span>
                            <div className="input-daterange input-group  d-flex" id="datepicker">
                              <input id="ArrivalDate" type="date" className="input-sm form-control" placeholder="Start date" name="start" value={startDate} onChange={(e) => {
                                this.setState({ startDate: e.target.value });
                              }} required={true} max={endDate} />&nbsp;&nbsp;
                              <span className="input-group-addon">to</span>&nbsp;&nbsp;
                              <input id="DepartDate" type="date" className="input-sm form-control" placeholder="End date" name="end" value={endDate} onChange={(e) => {
                                this.setState({ endDate: e.target.value });
                              }} required={true} min={startDate} />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <button type="submit" className="btn btn-primary waves-effect waves-light">
                            Search
                        </button>
                          <button type="reset" onClick={() => { this.clearSearch(); }} className="btn btn-secondary waves-effect m-l-10">
                            Cancel
                        </button>
                          {
                            (rows && rows.length > 0) && (
                              <button type="reset" onClick={() => { this.exportOrderCsv(); }} className="btn btn-secondary waves-effect m-l-10">
                                Export
                              </button>
                            )
                          }
                        </div>
                      </div>
                    </form>
                    <MDBDataTable
                      // striped
                      noBottomColumns={true}
                      paging={false}
                      bordered
                      hover
                      responsive
                      data={{ columns, rows }}
                      searching={false}
                    />
                    <div className="pagination">
                      <Pagination
                        totalCount={totalCount}
                        currentPage={currentPage}
                        limit={limit}
                        getData={this.getOrdersReport}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </AUX>
    );
  }
}

export default withRouter(Orders);   