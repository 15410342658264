import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { MDBDataTable } from 'mdbreact';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import Pagination from '../../../components/Pagination';
import SweetAlert from 'react-bootstrap-sweetalert';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import customFunctions from '../../../helper/customFunctions';

const columns = [
  {
    label: 'Name',
    field: 'name',
    sort: 'disabled',
    // width: 150
  },
  {
    label: 'Code',
    field: 'code',
    sort: 'disabled',
    // width: 270
  },
  // {
  //   label: 'Status',
  //   field: 'status',
  //   sort: 'disabled',
  //   // width: 200
  // },
  {
    label: 'Action',
    field: 'actions',
    sort: 'disabled'
  }
];

class Country extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countries: [],
      rows: [],
      countryid: null,
      showDelete: false,
      keyword: '',
      page: 1,
      limit: 10,
      totalCount: 0,
      currentPage: 1
    }
  }

  componentDidMount = async () => {
    customFunctions.setTitle('All Country');
    this.getCountryList(1);
  }

  getCountryList = async (currentPage) => {
    const { keyword, limit } = this.state;
    const pageNo = currentPage || 1;
    const endPoint = keyword ? `${Api.searchCountry}` : `${Api.getAllCountyList}/${pageNo}/${limit}`;
    const searchPayload = {
      "countryname": keyword,
      "skip": pageNo,
      "limit": limit
    };
    const callback = keyword ? AuthApi.postDataToServer : AuthApi.getDataFromServer;
    const { data, message } = await callback(endPoint, searchPayload);
    if (data) {
      this.setState({
        countries: data.data,
        totalCount: data.totalcount,
        currentPage: pageNo
      }, () => {
        this.setTableData();
      });
    } else {
      NotificationManager.error(message);
    }
  }

  changecountriestatus = async (country) => {
    const { currentPage } = this.state;
    const payload = {
      "countryid": country.countryid,
      "userstatus": country.isactive ? 0 : 1
    };

    const { data, message } = await AuthApi.postDataToServer(Api.changecountriestatus, payload);
    if (data && data.status !== 'Fail') {
      NotificationManager.success('country status changed');
      this.getCountryList(currentPage);
    } else {
      NotificationManager.error((data && data.message) || message || 'Please try again later');
    }
  }

  setTableData = () => {
    const { countries } = this.state;
    const rows = [];
    countries.map((country, i) => { // eslint-disable-line
      rows.push({
        name: `${country.countryname_eng}`,
        code: `${country.countrycode}`,
        status: (
          <React.Fragment>
            <input type="checkbox" id={`switch${country.countryid}`} switch="info" checked={country.isactive ? "checked" : ""} onChange={() => {
              this.changecountriestatus(country);
            }} />
            <label for={`switch${country.countryid}`} data-on-label="" data-off-label=""></label>
          </React.Fragment>
        ),
        actions: (
          <React.Fragment>
            <a href="/" onClick={(e) => {
              e.preventDefault(); e.stopPropagation();
              this.props.history.push(`/edit-country/${country.countryid}`)
            }}>
              <i className="mdi mdi-lead-pencil" title="Edit Country"></i>
            </a>
            {/* <a href="/" onClick={(e) => {
              e.preventDefault(); e.stopPropagation();
              this.props.history.push(`/countries/address/${country.countryid}`)
            }}>
              <i className="mdi mdi-note-text" title="View Country"></i>
            </a> */}
            <a href="/" className="m-l-10" onClick={(e) => {
              e.preventDefault(); e.stopPropagation();
              this.setState({ showDelete: true, countryid: country.countryid })
            }}>
              <i className="mdi mdi-delete text-danger" title="Delete Country"></i>
            </a>
          </React.Fragment>
        )
      })
    })
    this.setState({ showLoader: false, rows });
    this.forceUpdate();
  }

  deteleCountry = async () => {
    const { countryid } = this.state;
    const { data, message } = await AuthApi.deleteDataFromServer(`${Api.deleteCountry}${countryid}`);
    if (data) {
      this.setState({ showDelete: false, countryid: null }, () => {
        this.getCountryList(1);
      });
      NotificationManager.success('Country deleted');
    } else {
      NotificationManager.error(message);
    }
  }

  onSearchSubmit = (e) => {
    e.preventDefault();
    this.getCountryList(1);
  }

  clearSearch = () => {
    this.setState({ keyword: '' }, () => {
      this.getCountryList(1);
    })
  }

  render() {
    const { rows, showDelete, keyword, limit, totalCount, currentPage } = this.state;
    return (
      <AUX>
        <div className="page-content-wrapper">
          <NotificationContainer />
          <div className="container-fluid">
            {
              showDelete && (
                <SweetAlert
                  title="Are you sure, you want to delete?"
                  error
                  showCancel
                  confirmBtnBsStyle="success"
                  cancelBtnBsStyle="danger"
                  onConfirm={() => { this.deteleCountry() }}
                  onCancel={() => { this.setState({ showDelete: false, countryid: null }) }} >
                  You won't be able to revert this!
                </SweetAlert>
              )
            }
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <form onSubmit={this.onSearchSubmit}>
                      <div className="form-group row">
                        <div className="col-sm-5">
                          <input className="form-control" value={keyword} type="text" placeholder="Search here" id="example-text-input" onChange={(e) => { this.setState({ keyword: e.target.value }) }} required={true} />
                        </div>
                        <div className="col-sm-3">
                          <button type="submit" className="btn btn-primary waves-effect waves-light">
                            Search
                        </button>
                          <button type="reset" onClick={() => { this.clearSearch(); }} className="btn btn-secondary waves-effect m-l-10">
                            Cancel
                        </button>
                        </div>
                      </div>
                    </form>
                    <MDBDataTable
                      // striped
                      noBottomColumns={true}
                      paging={false}
                      bordered
                      hover
                      data={{ columns, rows }}
                      searching={false}
                    />
                    <div className="pagination">
                      <Pagination
                        totalCount={totalCount}
                        currentPage={currentPage}
                        limit={limit}
                        getData={this.getCountryList}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </AUX>
    );
  }
}

export default withRouter(Country);   