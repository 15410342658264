import React, { Component } from 'react';
import cookie from 'react-cookies';
import AUX from '../../../hoc/Aux_';
import customFunctions from '../../../helper/customFunctions';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';

class dashboard1 extends Component {

  constructor(props) {
    super(props);

    this.state = {
      simple: 80, simple68: 68, simple37: 37, simple72: 72,
      dashboardData: {},
    };
  }

  componentWillMount = async () => {
    const token = await cookie.load('token');
    if (!token) {
      await cookie.remove('token');
      this.props.history.push('/login');
    }
  }

  componentDidMount = () => {
    customFunctions.setTitle('Dashboard');
    this.getDashboardData();
  }

  getDashboardData = async () => {
    const { data } = await AuthApi.getDataFromServer(Api.getDashboardData);
    if (data && data.data && data.status !== "Fail") {
      this.setState({ dashboardData: data.data });
    }
  }

  render() {
    const { dashboardData } = this.state;
    return (
      <AUX>
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 col-xl-3">
                <div className="mini-stat clearfix bg-white">
                  <span className="mini-stat-icon bg-purple mr-0 float-right"><i className="mdi mdi-shopping"></i></span>
                  <div className="mini-stat-info">
                    <span className="counter text-purple">{dashboardData.totalorders}</span>
                    Total Orders
                            </div>
                  <div className="clearfix"></div>
                  <p className=" mb-0 m-t-20 text-muted"></p>
                </div>
              </div>
              <div className="col-md-6 col-xl-3">
                <div className="mini-stat clearfix bg-white">
                  <span className="mini-stat-icon bg-teal mr-0 float-right"><i className="mdi mdi-shopping"></i></span>
                  <div className="mini-stat-info">
                    <span className="counter text-teal">{dashboardData.todaysorders}</span>
                    Today's Orders
                            </div>
                  <div className="clearfix"></div>
                  <p className="text-muted mb-0 m-t-20"></p>
                </div>
              </div>
              <div className="col-md-6 col-xl-3">
                <div className="mini-stat clearfix bg-white">
                  <span className="mini-stat-icon bg-blue-grey mr-0 float-right"><i className="mdi mdi-truck"></i></span>
                  <div className="mini-stat-info">
                    <span className="counter text-blue-grey">{dashboardData.pickuppending}</span>
                    Pending for pickup
                            </div>
                  <div className="clearfix"></div>
                  <p className="text-muted mb-0 m-t-20"></p>
                </div>
              </div>
              <div className="col-md-6 col-xl-3">
                <div className="mini-stat clearfix bg-white">
                  <span className="mini-stat-icon bg-brown mr-0 float-right"><i className="mdi mdi-truck-delivery"></i></span>
                  <div className="mini-stat-info">
                    <span className="counter text-brown">{dashboardData.outfordelivery}</span>
                    Out for delivery
                            </div>
                  <div className="clearfix"></div>
                  <p className="text-muted mb-0 m-t-20"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

export default dashboard1;   