import axios from 'axios';
import cookie from 'react-cookies';

export default {
  setupInterceptors: async () => {
    await axios.interceptors.request.use(async (config) => {
      config.headers['Content-Type'] = 'application/json';
      const token = await cookie.load('userdata');
      if (token) config.headers['x-access-token'] = `${token}`; // eslint-disable-line
      return config;
    }, (error) => {
      Promise.reject(error);
    });
    // axios.interceptors.response.use(response => response, (error) => {
    //   console.log(error, 'interceptor errorrrr');
    //   return Promise.reject(error.response);
    // });
  }
};
