import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { MDBDataTable } from 'mdbreact';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import customFunctions from '../../../helper/customFunctions';
import shipmentData from '../../../helper/sampledata/shipment';
import imgProcess from '../../../assets/images/inprocess.png';
import imgDispatched from '../../../assets/images/dispatch.png';
import imgOfd from '../../../assets/images/ofd.png';
import imgDelivered from '../../../assets/images/delivered.png';
import './style.css'

const columns = [
  {
    label: 'Date & Time',
    field: 'datetime',
    sort: 'disabled',
    // width: 150
  },
  {
    label: 'Description',
    field: 'description',
    sort: 'disabled',
    // width: 150
  },

];

class Orders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shipmentHistory: [],
      rows: [],
      shipmentId: '',
      trackingId: '',
      orderStatus: 1
    }
  }

  componentDidMount = async () => {
    customFunctions.setTitle('Shipment tracking');
    const shipmentId = this.props.match.params.shipmentId;
    if (shipmentId) {
      this.setState({ shipmentId }, () => {
        this.getShipmentTracking();
      })
    }
  }

  getShipmentTracking = async () => {
    const { shipmentId } = this.state;
    const endPoint = `${Api.trackShipment}${shipmentId}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    console.log('---data', data);
    if (data && data.status !== "Fail") {
      this.setState({
        shipmentHistory: data.data,
        trackingId: this.state.shipmentId
      }, () => {
        this.setTableData();
        this.setOrderStatus();
      });
    } else {
      NotificationManager.error(message);
    }
  }

  setTableData = () => {
    const { shipmentHistory } = this.state;
    const rows = [];
    shipmentHistory.map((shipment) => { // eslint-disable-line
      rows.push({
        description: customFunctions.getShipmentStatus(shipment.process).description,
        datetime: shipment.createddate,
      })
    })
    this.setState({ showLoader: false, rows });
    this.forceUpdate();
  }

  setOrderStatus = () => {
    const { shipmentHistory } = this.state;
    const historyLength = shipmentHistory.length;
    if (!historyLength) {
      this.setState({ orderStatus: 1 }); return;
    }
    const status = shipmentHistory[historyLength - 1].process;
    switch (String(status).toLowerCase()) {
      case 'bookingpending':
      case 'bookingdone': this.setState({ orderStatus: 1 }); break;
      case 'ofp': this.setState({ orderStatus: 2 }); break;
      case 'ofd': this.setState({ orderStatus: 3 }); break;
      case 'delivered': this.setState({ orderStatus: 4 }); break;
      case 'undelivered': this.setState({ orderStatus: 5 }); break;
      default: this.setState({ orderStatus: 1 });
    }
  }

  onTrackShipment = (e) => {
    e.preventDefault();
    this.getShipmentTracking();
  }

  getCurrentStatus = () => {
    const { shipmentHistory } = this.state;
    const historyLength = shipmentHistory.length;
    if (!historyLength) return '';
    const objStatus = customFunctions.getShipmentStatus(shipmentHistory[historyLength - 1].process);
    return objStatus.status;
  }

  render() {
    const { rows, shipmentId, trackingId, orderStatus } = this.state;
    return (
      <AUX>
        <div className="page-content-wrapper">
          <NotificationContainer />
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <form onSubmit={this.onTrackShipment}>
                      <div className="form-group row">
                        <div className="col-sm-5">
                          <input className="form-control" value={shipmentId} type="text" placeholder="Shipment Id" id="example-text-input" onChange={(e) => { this.setState({ shipmentId: e.target.value }) }} required={true} />
                        </div>
                        <div className="col-sm-3">
                          <button type="submit" className="btn btn-primary waves-effect waves-light">
                            Track shipment
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                {
                  rows && (rows.length > 0) && (
                    <div className="card m-b-20">
                      <div className="card-body">
                        <h3 for="example-text-input" className="col-form-label">Shipment Id #{trackingId}</h3>
                        <div class="row d-flex justify-content-center">
                          <div class="col-12">
                            <ul id="progressbar" class="text-center">
                              <li class={`step0 ${orderStatus >= 1 ? 'active' : ''}`}></li>
                              <li class={`step0 ${orderStatus >= 2 ? 'active' : ''}`}></li>
                              <li class={`step0 ${orderStatus >= 3 ? 'active' : ''}`}></li>
                              <li class={`step0 ${(orderStatus >= 4 && orderStatus <= 4) ? 'active' : (orderStatus >=5 ? 'inactive' : '')}`}></li>
                            </ul>
                          </div>
                        </div>
                        <div class="row justify-content-between top">
                          <div class="col-md-3 text-center">
                            <img class="icon" alt="inprocess" src={imgProcess} />
                            <div class="d-flex flex-column">
                              <p class="font-weight-bold">In process</p>
                            </div>
                          </div>
                          <div class="col-md-3 text-center">
                            <img class="icon" alt="dispatched" src={imgDispatched} />
                            <div class="d-flex flex-column">
                              <p class="font-weight-bold">Dispatched</p>
                            </div>
                          </div>
                          <div class="col-md-3 text-center">
                            <img class="icon" alt="ofd" src={imgOfd} />
                            <div class="d-flex flex-column">
                              <p class="font-weight-bold">Out for delivery</p>
                            </div>
                          </div>
                          <div class="col-md-3 text-center">
                            <img class="icon" alt="delivered" src={imgDelivered} />
                            <div class="d-flex flex-column">
                              <p class="font-weight-bold">Delivered</p>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <h5>Tracking Information:</h5>
                        <hr />
                        <div>
                          <div className="form-group row">
                            <label for="example-text-input" className="col-sm-2 col-form-label">Shipment Status:</label>
                            <div className="col-sm-10">
                              <p>{this.getCurrentStatus()}</p>
                            </div>
                            <label for="example-text-input" className="col-sm-2 col-form-label">Ship Carrier:</label>
                            <div className="col-sm-10">
                              <p>Stripe</p>
                            </div>
                            <label for="example-text-input" className="col-sm-2 col-form-label">AWB Number:</label>
                            <div className="col-sm-10">
                              <p>{trackingId}</p>
                            </div>
                          </div>

                        </div>

                        <MDBDataTable
                          // striped
                          noBottomColumns={true}
                          paging={false}
                          bordered
                          hover
                          data={{ columns, rows }}
                          searching={false}
                        />
                      </div>
                    </div>
                  )
                }
                {
                  rows && (rows.length <= 0) && (
                    <div className="card m-b-20">
                      <div className="card-body text-center">
                        <h6>No shipment history available</h6>
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>

      </AUX>
    );
  }
}

export default withRouter(Orders);   