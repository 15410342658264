import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { MDBDataTable } from 'mdbreact';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import Pagination from '../../../components/Pagination';
import SweetAlert from 'react-bootstrap-sweetalert';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import customFunctions from '../../../helper/customFunctions';

class DriverLoadTransfer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fromdriverid: '',
      todriverid: '',
      pendingShipmentList: [],
      rows: [],
      keyword: '',
      page: 1000,
      limit: 1,
      totalCount: 0,
      currentPage: 1,
      drivers: [],
      columns: [],
      confirmTransferModel: false
    }
  }

  componentDidMount = async () => {
    customFunctions.setTitle('Load Transfer');
    const userData = await customFunctions.getUserData();
    this.getDrivers();
  }

  getDrivers = async () => {
    const endPoint = `${Api.getDrivers}/1/50`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data) {
      this.setState({
        drivers: data.data,
      });
    } else {
      NotificationManager.error(message);
    }
  }

  getDriverPendingShipment = async (currentPage) => {
    const { keyword, limit, fromdriverid } = this.state;
    const pageNo = currentPage || 1;
    if (!fromdriverid) return;

    const endPoint = `${Api.getPendingShipmentPickup}/${fromdriverid}/`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data) {
      this.setState({
        pendingShipmentList: data.data,
        totalCount: data.data.length, //data.totalcount,
        currentPage: pageNo,
        allChecked: false
      }, () => {
        this.setColumns();
        this.setTableData();
      });
    } else {
      NotificationManager.error(message);
    }
  }

  setColumns = () => {
    const columns = [
      {
        label: 'Sr.No',
        field: 'srNo',
        sort: 'asc',
        // width: 150
      },
      {
        label: 'Location',
        field: 'pickuplocationname',
        sort: 'asc',
        // width: 150
      },
      {
        label: 'Pickup Count',
        field: 'pickupcount',
        sort: 'asc',
        // width: 270
      },
    ];
    this.setState({ columns });
  }

  setTableData = () => {
    const { pendingShipmentList } = this.state;
    const rows = [];
    pendingShipmentList.map((order, index) => { // eslint-disable-line
      rows.push({
        srNo: `#${index + 1}`,
        pickuplocationname: order.pickuplocationname,
        pickupcount: order.pickupcount,
      })
    })
    this.setState({ showLoader: false, rows });
    this.forceUpdate();
  }

  validate = () => {
    const { fromdriverid, todriverid, pendingShipmentList } = this.state;
    if (!fromdriverid) {
      NotificationManager.error('Please select from driver');
      return false;
    }

    if (!todriverid) {
      NotificationManager.error('Please select to driver');
      return false;
    }

    if (pendingShipmentList.length < 1) {
      NotificationManager.error('No shipments to transfer');
      return false;
    }

    return true;
  }

  resetData = () => {
    this.setState({
      fromdriverid: '',
      todriverid: '',
      pendingShipmentList: [],
      totalCount: 0,
      rows: [],
      columns: [],
      confirmTransferModel: false
    });
  }

  trasferShipment = async () => {
    const { fromdriverid, todriverid } = this.state;
    const userData = await customFunctions.getUserData();
    if (!userData) return;

    const payload = {
      fromdriverid,
      todriverid
    };
    const { data, message } = await AuthApi.postDataToServer(Api.driverTransferLoad, payload);
    if (data && data.status == "success") {
      NotificationManager.success('pending shipments transfered');
      this.resetData();
    } else {
      this.setState({ confirmTransferModel: false });
      NotificationManager.error('shipments transfer failed');
    }
    await this.getDriverPendingShipment(1);
  }

  submitForm = async (e) => {
    e.preventDefault();
    const { fromdriverid, todriverid } = this.state;
    const userData = await customFunctions.getUserData();
    if (!userData) return;

    if (this.validate()) {
      this.setState({ confirmTransferModel: true });
    }
  }

  render() {
    const { rows, confirmTransferModel, keyword, totalCount, currentPage, limit, drivers, fromdriverid, todriverid, columns } = this.state;
    return (
      <AUX>
        <div className="page-content-wrapper">
          <NotificationContainer />
          <div className="container-fluid">
            {
              (confirmTransferModel) && (<SweetAlert
                title="Are you sure, you want to Transfer?"
                warning
                showCancel
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                confirmBtnText="Continue"
                onConfirm={() => { this.trasferShipment() }}
                onCancel={() => { this.resetData(); }} >
                You won't be able to revert this!
              </SweetAlert>)
            }
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <form onSubmit={this.submitForm}>
                      <div className="form-group row">
                        <div className="col-sm-5">
                          {/* <input className="form-control" value={keyword} type="text" placeholder="Search here" id="example-text-input" onChange={(e) => { this.setState({ keyword: e.target.value }) }} required={true} /> */}
                          <select className="form-control" placeholder="Select driver" required={true} value={fromdriverid} onChange={(e) => {
                            this.setState({ fromdriverid: e.target.value, todriverid: '' }, async () => {
                              await this.getDriverPendingShipment(1);
                            })
                          }} >
                            <option value="">Select from driver</option>
                            {
                              drivers.map(driver => (
                                <option key={driver.driverid} value={driver.driverid}>{`${driver.first_name} ${driver.last_name}`}</option>
                              ))
                            }
                          </select>
                        </div>
                        <div className="col-sm-5">
                          {/* <input className="form-control" value={keyword} type="text" placeholder="Search here" id="example-text-input" onChange={(e) => { this.setState({ keyword: e.target.value }) }} required={true} /> */}
                          <select className="form-control" placeholder="Select driver" required={true} value={todriverid} onChange={(e) => {
                            this.setState({ todriverid: e.target.value })
                          }} >
                            <option value="">Select to driver</option>
                            {
                              drivers.map(driver => (
                                (fromdriverid && parseInt(fromdriverid) !== parseInt(driver.driverid)) && (<option key={driver.driverid} value={driver.driverid}>{`${driver.first_name} ${driver.last_name}`}</option>)
                              ))
                            }
                          </select>
                        </div>
                        <div className="col-sm-2">
                          <button type="submit" className="btn btn-primary waves-effect waves-light">
                            Transfer
                        </button>
                        </div>
                      </div>
                    </form>
                    <MDBDataTable
                      // striped
                      noBottomColumns={true}
                      paging={false}
                      bordered
                      hover
                      data={{ columns, rows }}
                      searching={false}
                      sortable={true}
                    />
                    <div className="pagination">
                      <Pagination
                        totalCount={totalCount}
                        currentPage={currentPage}
                        limit={limit}
                        getData={this.getDriverPendingShipment}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </AUX>
    );
  }
}

export default withRouter(DriverLoadTransfer);
