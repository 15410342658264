import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import { MDBDataTable } from 'mdbreact';
import Pagination from '../../../components/Pagination';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import customFunctions from '../../../helper/customFunctions';

const columns = [
  {
    label: 'Country',
    field: 'country',
    sort: 'disabled',
    // width: 150
  },
  {
    label: 'City',
    field: 'city',
    sort: 'disabled',
    // width: 150
  },
  {
    label: 'Driver Commission',
    field: 'commission',
    sort: 'disabled',
    // width: 270
  },
  {
    label: 'Action',
    field: 'actions',
    sort: 'disabled'
  }
];

class Commissions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      driverCommissions: [],
      rows: [],
      page: 1,
      limit: 10,
      totalCount: 0,
      currentPage: 1,
    }
  }

  componentDidMount = async () => {
    customFunctions.setTitle('Driver Commisions');
    this.getDriverCommissions(1);
  }

  getDriverCommissions = async (currentPage) => {
    const { limit } = this.state;
    const pageNo = currentPage || 1;
    const endPoint = `${Api.getDriverCommisions}/${pageNo}/${limit}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data) {
      this.setState({
        driverCommissions: data.data,
        totalCount: data.totalcount,
        currentPage: pageNo
      }, () => {
        this.setTableData();
      });
    } else {
      NotificationManager.error(message);
    }
  }

  setTableData = () => {
    const { driverCommissions } = this.state;
    const rows = [];
    driverCommissions.map((charges) => { // eslint-disable-line
      rows.push({
        country: charges.countryname_eng,
        city: charges.cityname_eng,
        commission: `$${charges.drivercommission}`,
        actions: (
          <React.Fragment>
            <a href="/" onClick={(e) => {
              e.preventDefault(); e.stopPropagation();
              this.props.history.push(`/driver-commission/edit/${charges.cityid}`)
            }}>
              <i className="mdi mdi-lead-pencil" id="editDriver" title="Edit commission"></i>
            </a>
            {/* <a href="/" className="m-l-10" onClick={(e) => {
              e.preventDefault(); e.stopPropagation();
              this.setState({ showDelete: true, driverId: driver.mobile })
            }}>
              <i className="mdi mdi-delete" title="Delete driver"></i>
            </a> */}
          </React.Fragment>
        )
      })
    })
    this.setState({ showLoader: false, rows });
    this.forceUpdate();

  }

  render() {
    const { rows, showDelete, keyword, totalCount, currentPage, limit } = this.state;
    return (
      <AUX>
        <div className="page-content-wrapper">
          <NotificationContainer />
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <MDBDataTable
                      // striped
                      noBottomColumns={true}
                      paging={false}
                      bordered
                      hover
                      data={{ columns, rows }}
                      searching={false}
                    />
                    <div className="pagination">
                      <Pagination
                        totalCount={totalCount}
                        currentPage={currentPage}
                        limit={limit}
                        getData={this.getDriverCommissions}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

export default Commissions;