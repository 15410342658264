import React, { Component } from 'react';
import AuthApi from '../../helper/authApi';
import Api from '../../helper/api';
// import constants from '../../helper/constants';
import './style.css';

class PlaceSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      places: [],
      selectedPlace: '',
      countries: [],
      cities: []
    };
    this.timeout = 0;
  }

  map = null;
  service = null;

  componentDidMount = async () => {
    // this.getCountries();
    // this.getCities()
    const { google } = this.props;
    this.map = new google.maps.Map(document.getElementById('getcurb-map'));
    this.service = new google.maps.places.PlacesService(this.map);
  }

  getCountries = async () => {
    const { data } = await AuthApi.getDataFromServer(Api.getCountries);
    if (data && data.data && data.status !== "Fail") {
      this.setState({ countries: data.data });
    }
  }

  getCities = async (countryId = 1) => {
    const { data } = await AuthApi.getDataFromServer(`${Api.getCities}/${countryId}`);
    if (data && data.data && data.status !== "Fail") {
      // this.setState({ cities: data.data });
      return data.data;
    }
    return [];
  }

  searchPlace = async (placeText = '') => {
    const { disabled, inText, google } = this.props;
    if (disabled) return;
    if (placeText.trim() === '') {
      this.setState({ places: [] });
      if (this.timeout) clearTimeout(this.timeout);
      return;
    }
    if (inText)
      placeText = `${placeText} ${inText}`;

    var request = {
      query: placeText,
    };

    if (this.timeout) clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(async () => {
      if (!$this.service) return;
      $this.service.textSearch(request, (result, status) => {
        if (status == google.maps.places.PlacesServiceStatus.OK) {
          $this.setState({ places: result });
        } else {
          $this.setState({ places: [] });
        }
      });
    }, 200);
  }

  getPlaceDetails = async (place) => {
    const { google } = this.props;
    var request = {
      placeId: place.place_id,
      fields: ['name', 'rating', 'formatted_phone_number', 'geometry', 'address_component', 'formatted_address']
    };
    if (!this.service) {
      this.setState({ places: [] });
      return;
    }
    this.service.getDetails(request, (place, status) => {
      if (status == google.maps.places.PlacesServiceStatus.OK) {
        this.generateAddressObject(place);
      }
    });
  }

  generateAddressObject = async (placeDetails) => {
    // const { countries } = this.state;
    const { onPlaceSelect, onValueChange } = this.props;
    const formattedAddress = placeDetails.formatted_address;

    const placeAddrObj = {
      formattedAddress,
      geoLocation: placeDetails.geometry.location
    };

    this.setState({ selectedPlace: formattedAddress, places: [] });
    if (onPlaceSelect) onPlaceSelect(placeAddrObj);
    if (onValueChange) onValueChange(formattedAddress, placeDetails.geometry.location);

  }

  render() {
    const { selectedPlace, places } = this.state;
    const { classes, placeholder, onPlaceSelect, placeValue, disabled, onValueChange } = this.props;
    return (
      <React.Fragment>
        <div className="floating-label-feild">
          <div id="getcurb-map"></div>
          <input
            value={placeValue}
            className={classes || ''}
            placeholder={placeholder}
            onChange={(e) => {
              if (onValueChange) onValueChange(e.target.value);
              this.setState({ selectedPlace: e.target.value });
              this.searchPlace(e.target.value);
            }}
            disabled={disabled}
            id="getcurb-place-search"
          // onBlur={() => {
          //   this.setState({ places: [] });
          // }}
          />
          {/* <label className="form-control-placeholder" for="">{placeholder}</label> */}
          {
            (places.length > 0) && (
              <ul className="places-container">
                {
                  places.map((place) => (
                    <li className="cursor-pointer" onClick={(e) => {
                      this.getPlaceDetails(place);
                      // if (onPlaceSelect)
                      //   onPlaceSelect(place)
                      // this.setState({ selectedPlace: place.formatted_address, places: [] });
                    }}>
                      <p className="mb-0"><strong>{place.name}</strong></p>
                      <small>{place.formatted_address}</small>
                      <hr />
                    </li>
                  ))
                }
              </ul>
            )
          }
        </div>
      </React.Fragment>
    );
  }
}

export default PlaceSearch;