import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { MDBDataTable } from 'mdbreact';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import Pagination from '../../../components/Pagination';
import SweetAlert from 'react-bootstrap-sweetalert';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import customFunctions from '../../../helper/customFunctions';
import SharedService from '../../../helper/SharedService';

const columns = [
  {
    label: 'Name',
    field: 'name',
    sort: 'disabled',
    // width: 150
  },
  {
    label: 'Email',
    field: 'email',
    sort: 'disabled',
    // width: 270
  },
  {
    label: 'Mobile',
    field: 'mobile',
    sort: 'disabled',
    // width: 200
  },
  {
    label: 'Country',
    field: 'country',
    sort: 'disabled',
    // width: 200
  },
  // {
  //   label: 'City',
  //   field: 'city',
  //   sort: 'disabled',
  //   // width: 200
  // },
  {
    label: 'Status',
    field: 'status',
    sort: 'disabled',
    // width: 200
  },
  {
    label: 'Action',
    field: 'actions',
    sort: 'disabled'
  }
];

class Admins extends Component {
  constructor(props) {
    super(props);
    this.state = {
      admins: [],
      rows: [],
      adminId: null,
      showDelete: false,
      keyword: '',
      page: 1,
      limit: 10,
      totalCount: 0,
      currentPage: 1,
      countryid: '',
      cityid: '',
      currentAdminId: null
    }
  }

  componentDidMount = async () => {
    customFunctions.setTitle('All Admins');
    const currentUser = await customFunctions.getUserData();
    const currentAdminId = currentUser && currentUser.data && currentUser.data.adminid ? currentUser.data.adminid : null;
    const countryid = await localStorage.getItem('admincountry') || '';
    const cityid = await localStorage.getItem('admincity') || '';
    await this.setState({ currentAdminId, countryid, cityid });
    this.getAdmins(1);
    this.checkAppliedFilter();
    this.subscribed = SharedService.cityChanged.subscribe(this.checkAppliedFilter);
  }

  componentWillUnmount = () => {
    if (this.subscribed) this.subscribed.unsubscribe();
  }

  checkAppliedFilter = async () => {
    const countryid = await localStorage.getItem('admincountry') || '';
    const cityid = await localStorage.getItem('admincity') || '';
    const showCountryCityFilter = cityid ? false : true;
    this.setState({ countryid, cityid, showCountryCityFilter }, () => {
      this.getAdmins(1);
    })
  }

  getAdmins = async (currentPage) => {
    const { keyword, limit, cityid, countryid } = this.state;
    const pageNo = currentPage || 1;
    const hasSearch = (keyword !== '') || (countryid.toString().trim() !== '') || (cityid.toString().trim() !== '')
    const endPoint = (hasSearch) ? `${Api.searchAdmin}` : `${Api.getAdmins}/${pageNo}/${limit}`;
    const searchPayload = {
      "searchfield": keyword,
      "skip": 1,//(currentPage - 1) * limit,
      "limit": limit
    };
    if (cityid.toString().trim() !== '') searchPayload.cityid = cityid;
    if (countryid.toString().trim() !== '') searchPayload.countryid = countryid;
    const callback = (hasSearch) ? AuthApi.postDataToServer : AuthApi.getDataFromServer;
    const { data, message } = await callback(endPoint, searchPayload);
    if (data) {
      this.setState({
        admins: data.data,
        totalCount: data.totalcount,
        currentPage: pageNo
      }, () => {
        this.setTableData();
      });
    } else {
      NotificationManager.error(message);
    }
  }

  changeAdminStatus = async (customer) => {
    const { currentPage } = this.state;
    const payload = {
      "adminid": customer.adminid,
      "userstatus": customer.isactive ? 0 : 1
    };

    const { data, message } = await AuthApi.postDataToServer(Api.changeAdminStatus, payload);
    if (data && data.status !== 'Fail') {
      NotificationManager.success('Customer status changed');
      this.getAdmins(currentPage);
    } else {
      NotificationManager.error((data && data.message) || message || 'Please try again later');
    }
  }

  setTableData = () => {
    const { admins, currentAdminId } = this.state;
    const rows = [];
    admins.map((admin, i) => { // eslint-disable-line
      rows.push({
        name: `${admin.first_name} ${admin.last_name}`,
        email: `${admin.email}`,
        mobile: `${admin.mobile}`,
        country: `${admin.countryname_eng}`,
        // city: `${admin.cityname_eng}`,
        regdate: '-',
        status: (
          <React.Fragment>
            <input type="checkbox" id={`switch${admin.adminid}`} switch="info" checked={admin.isactive ? "checked" : ""} onChange={() => {
              this.changeAdminStatus(admin);
            }} />
            <label for={`switch${admin.adminid}`} data-on-label="" data-off-label=""></label>
          </React.Fragment>
        ),
        actions: (
          <React.Fragment>
            <a href="/" onClick={(e) => {
              e.preventDefault(); e.stopPropagation();
              this.props.history.push(`/edit-admin/${admin.adminid}`)
            }}>
              <i className="mdi mdi-lead-pencil" title="Edit admin"></i>
            </a>
            {
              (currentAdminId !== admin.adminid) && (
                <a href="/" className="" onClick={(e) => {
                  e.preventDefault(); e.stopPropagation();
                  this.setState({ showDelete: true, adminId: admin.adminid })
                }}>
                  <i className="mdi mdi-delete text-danger" title="Delete admin"></i>
                </a>
              )
            }
          </React.Fragment>
        )
      })
    })
    this.setState({ showLoader: false, rows });
    this.forceUpdate();
  }

  deleteAdmin = async () => {
    const { adminId } = this.state;
    const { data, message } = await AuthApi.deleteDataFromServer(`${Api.deleteAdmin}/${adminId}`);
    if (data) {
      this.setState({ showDelete: false, adminId: null }, () => {
        this.getAdmins(1);
      });
      NotificationManager.success('Admin deleted');
    } else {
      NotificationManager.error(message);
    }
  }

  onSearchSubmit = (e) => {
    e.preventDefault();
    this.getAdmins(1);
  }

  clearSearch = () => {
    this.setState({ keyword: '' }, () => {
      this.getAdmins(1);
    })
  }

  render() {
    const { rows, showDelete, keyword, limit, totalCount, currentPage } = this.state;
    return (
      <AUX>
        <div className="page-content-wrapper">
          <NotificationContainer />
          <div className="container-fluid">
            {
              showDelete && (
                <SweetAlert
                  title="Are you sure, you want to delete?"
                  error
                  showCancel
                  confirmBtnBsStyle="success"
                  cancelBtnBsStyle="danger"
                  onConfirm={() => { this.deleteAdmin() }}
                  onCancel={() => { this.setState({ showDelete: false, adminId: null }) }} >
                  You won't be able to revert this!
                </SweetAlert>
              )
            }
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <form onSubmit={this.onSearchSubmit}>
                      <div className="form-group row">
                        <div className="col-sm-5">
                          <input className="form-control" value={keyword} type="text" placeholder="Search here" id="example-text-input" onChange={(e) => { this.setState({ keyword: e.target.value }) }} required={true} />
                        </div>
                        <div className="col-sm-3">
                          <button type="submit" className="btn btn-primary waves-effect waves-light">
                            Search
                        </button>
                          <button type="reset" onClick={() => { this.clearSearch(); }} className="btn btn-secondary waves-effect m-l-10">
                            Cancel
                        </button>
                        </div>
                      </div>
                    </form>
                    <MDBDataTable
                      // striped
                      noBottomColumns={true}
                      paging={false}
                      bordered
                      hover
                      responsive
                      data={{ columns, rows }}
                      searching={false}
                    />
                    <div className="pagination">
                      <Pagination
                        totalCount={totalCount}
                        currentPage={currentPage}
                        limit={limit}
                        getData={this.getAdmins}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </AUX>
    );
  }
}

export default withRouter(Admins);   