import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import customFunctions from '../../../helper/customFunctions';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';


class Timeslots extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countries: [],
      cities: [],
      countryid: '',
      cityid: '',
      slots: [{
        startTime: '',
        endTime: ''
      }]
    }
  }

  componentDidMount = async () => {
    customFunctions.setTitle('Add slots');
    this.getCountries();
  }

  getCountries = async () => {
    const { data } = await AuthApi.getDataFromServer(Api.getCountries);
    if (data && data.status !== "Fail") {
      this.setState({ countries: data.data });
    }
  }

  getCities = async (countryid) => {
    const { data } = await AuthApi.getDataFromServer(`${Api.getCities}/${countryid}`);
    if (data && data.status !== "Fail") {
      this.setState({ cities: data.data });
    }
  }

  validateForm = (e) => {
    e.preventDefault();
    const { cityid, countryid, slots } = this.state;
    let cityErr = '', countryErr = '', count = 0;
    let isValid = true;

    if (cityid.toString().trim() === '') {
      cityErr = 'City is required'; isValid = false;
    }

    if (countryid.toString().trim() === '') {
      countryErr = 'Country is required'; isValid = false;
    }

    for (let i = 0; i < slots.length; i++) {
      if (slots[i].startTime.trim() === '') {
        slots[i].startTimeErr = 'This field required';
        count++;
      } else if (!customFunctions.validateNumber(slots[i].startTime) || Number(slots[i].startTime) > 24) {
        slots[i].startTimeErr = 'Invalid time';
        count++;
      } else {
        slots[i].startTimeErr = '';
      }
      if (slots[i].endTime.trim() === '') {
        slots[i].endTimeErr = 'This field required';
        count++;
      } else if (!customFunctions.validateNumber(slots[i].endTime) || Number(slots[i].endTime) > 24) {
        slots[i].endTimeErr = 'Invalid time';
        count++;
      } else {
        slots[i].endTimeErr = '';
      }
    }


    this.setState({ cityErr, countryErr, slots });
    if (isValid && count === 0) {
      this.addTimeSlots()
    }
  }

  addTimeSlots = async () => {
    const { slots, cityid, countryid } = this.state;
    const payload = {
      cityid
    };
    for (let i = 0; i < slots.length; i++) {
      payload.startTime = slots[i].startTime;
      payload.endTime = slots[i].endTime;
      const { data } = await AuthApi.postDataToServer(Api.addTimeSlots, payload);
      if (data && data.status !== "Fail") {
        if (i === slots.length - 1) {
          NotificationManager.success('Slots added');
          this.resetData();
        }
      }
    }
  }

  resetData = () => {
    this.setState({
      cityid: '',
      countryid: '',
      cities: [],
      slots: [{
        startTime: '',
        endTime: '',
      }]
    })
  }

  addSlot = () => {
    const { slots } = this.state;
    const slot = {
      startTime: '',
      endTime: ''
    };
    slots.push(slot);
    this.setState({ slots });
  }

  removeSlot = (arrIndex) => {
    const { slots } = this.state;
    slots.splice(1, 1);
    this.setState({ slots });
  }

  render() {
    const { countries, countryid, countryErr, cities, cityid, cityErr, slots } = this.state;
    return (
      <AUX>
        <div className="page-content-wrapper">
          <NotificationContainer />
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <form onSubmit={this.validateForm}>
                      <div className="form-group row">
                        <label for="example-text-input" className="col-sm-2 col-form-label">Country*</label>
                        <div className="col-sm-5">
                          <select className="form-control" value={countryid} onChange={(e) => {
                            this.setState({ countryid: e.target.value, cityid: '' });
                            this.getCities(e.target.value);
                          }}>
                            <option value=''>Select country</option>
                            {
                              countries.map((country) => (
                                <option value={country.countryid}>{country.countryname_eng}</option>
                              ))
                            }
                          </select>
                          <span id="err">{countryErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label for="example-text-input" className="col-sm-2 col-form-label">City*</label>
                        <div className="col-sm-5">
                          <select className="form-control" value={cityid} onChange={(e) => {
                            this.setState({ cityid: e.target.value });
                            // this.getCities(e.target.value);
                          }}>
                            <option value=''>Select city</option>
                            {
                              cities.map((city) => (
                                <option value={city.cityid}>{city.cityname_eng}</option>
                              ))
                            }
                          </select>
                          <span id="err">{cityErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label for="example-text-input" className="col-sm-2 col-form-label">Slots*</label>
                        <div className="col-sm-5">
                          {
                            slots.map((slot, index) => (
                              <div className="row mb-4">
                                <div className="col-sm-4">
                                  <input className="form-control" placeholder="Start time" value={slot.startTime} onChange={(e) => {
                                    slot.startTime = e.target.value;
                                    this.setState({ slots });
                                  }} />
                                  <span id="err">{slot.startTimeErr ? slot.startTimeErr : ''}</span>
                                </div>
                                <div className="col-sm-4">
                                  <input className="form-control" placeholder="End time" value={slot.endTime} onChange={(e) => {
                                    slot.endTime = e.target.value;
                                    this.setState({ slots });
                                  }} />
                                  <span id="err">{slot.endTimeErr ? slot.endTimeErr : ''}</span>
                                </div>
                                <div className="col-sm-4">
                                  <button type="button" className="btn btn-primary waves-effect waves-light" onClick={() => {
                                    this.addSlot();
                                  }}>
                                    Add
                                  </button>
                                  {
                                    (index > 0) && (
                                      <button type="button" className="btn btn-secondary waves-effect waves-light ml-2" onClick={() => {
                                        this.removeSlot(index);
                                      }}>
                                        Remove
                                      </button>
                                    )
                                  }
                                </div>
                              </div>
                            ))
                          }
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-sm-3">
                          <button type="submit" className="btn btn-primary waves-effect waves-light">
                            Submit
                        </button>
                          <button type="reset" onClick={() => {
                            this.props.history.push('/timeslots');
                          }} className="btn btn-secondary waves-effect m-l-10">
                            Cancel
                        </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

export default Timeslots;