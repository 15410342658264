const constants = {
    s3bucketName: 'podimages',
    s3region: 'us-east-1',
    s3accessId: 'AKIASNCR53JCCBBRJ2OV',
    s3secretKey: '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',
    // stripeKey: 'pk_test_51GvbKPFr6qztdXVfRfeeT7Vf7aPcfnjtcjrXfZYhxYyGK9Rd3T2xqBLrlYzotFs0Nxhi9NBxwZc2sqgLCiR9Kxaj00Y8Y7GMDF',
    stripeKey: 'pk_test_51H5UfYHXrgCwTImKxcPTkPoG2VP9gGnOKYcI4yhkKoWlnJaAPHtMII6nBhJsNQWrRgDnaG76hqtqGfnYwckUJ2GB00KEd54Axh',
    mapKey: 'AIzaSyDvRarwZI3x0RZ7wUavQj8zi5wJHQKqc78',
    transactionsPercent: 3
  };

  export const googleCaptchaConfig = {
    siteKey:'6LdCy7AZAAAAAFx7nHkYVHp2a6SyXx6men_uInLJ'
  }

  export default constants;
