import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import $ from 'jquery';
import menu from '../../helper/menu';

class sidebar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      Tab: 'index', SubTab: '', MoreTab: '', dashboard_menu: false, email_menu: false, ui_menu: false,
      form_menu: false, chart_menu: false, table_menu: false, icon_menu: false, map_menu: false,
      auth_menu: false, extra_menu: false, eco_menu: false, emt_menu: false, hasSub: false,
      currentPath: ''
    };
  }

  setActiveTab = (tab, subtab, hasSub, toggleTab, e) => {
    this.setState({ Tab: tab, SubTab: subtab, hasSub });
  }

  componentDidMount() {
    var now_route = "";
    const currentPath = window.location.pathname;
    this.setState({ currentPath })
    var pageUrl = window.location.pathname.split(/[?#]/)[0];
    now_route = pageUrl.substr(1).replace(/_/g, " ");
    // $('#now_routing').empty();
    // if (now_route === "") { now_route = "Dashboard" } else { }
    // $('#now_routing').append(now_route);
    for (let i = 0; i < menu.length; i++) {
      let menuTitle = '';
      let subTitle = '';
      if (menu[i].path === currentPath) {
        menuTitle = menu[i].title;
      }
      const submenu = menu[i].submenu;
      for (let j = 0; j < submenu.length; j++) {
        if (submenu[j].path === currentPath) {
          subTitle = submenu[j].title;
        }
      }
      if (menuTitle !== '' || subTitle !== '') {
        this.setState({ Tab: menu[i].title, SubTab: subTitle });
        now_route = subTitle !== '' ? subTitle : (menu[i].submenu.length === 0) ? menu[i].title : '';
        // $('#now_routing').append(now_route);
        break;
      }
    }

    $('.toggle-search').on('click', function () {
      var targetId = $(this).data('target');
      var $searchBar;
      if (targetId) {
        $searchBar = $(targetId);
        $searchBar.toggleClass('open');
      }
    });

    $('.button-menu-mobile').on('click', function (event) {
      event.preventDefault();
      $("body").toggleClass("enlarged");
    });

    $('li.no_sub a').on('click', function (event) {
      //event.preventDefault();
      $("body").toggleClass("enlarged");
    });

    $('li.has_sub li').on('click', function (event) {
      $("body").toggleClass("enlarged");
    });
  }

  componentDidUpdate(prevProp, prevState) {
    var now_route = "";
    const currentPath = this.props.location.pathname;

    if (prevState.currentPath.toString() !== currentPath.toString()) {
      let menuTitle = '';
      let subTitle = '';
      for (let i = 0; i < menu.length; i++) {

        if (menu[i].path === currentPath) {
          menuTitle = menu[i].title;
        }
        const submenu = menu[i].submenu;
        for (let j = 0; j < submenu.length; j++) {
          if (submenu[j].path === currentPath) {
            subTitle = submenu[j].title;
          }
        }
        if (menuTitle !== '' || subTitle !== '') {
          menuTitle = menu[i].title;
          this.setState({ Tab: menuTitle, SubTab: subTitle });
          now_route = subTitle !== '' ? subTitle : (menu[i].submenu.length === 0) ? menu[i].title : '';
          // $('#now_routing').append(now_route);
          break;
        }
      }
      this.setState({ Tab: menuTitle, SubTab: subTitle });
      this.setState({ currentPath });
    }

    // const { Tab, SubTab, hasSub } = this.state;
    // if(hasSub) return;
    // now_route = (SubTab !== '') ? SubTab : Tab;
    // $('#now_routing').empty();
    // $('#now_routing').append(now_route);
  }

  render() {
    const { Tab, SubTab } = this.state;
    return (
      <div className="left side-menu">
        <div className="topbar-left">
          <div className="">
            <Link to="/" className="logo"><img src="/assets/images/logo-sm.png" height="36" alt="logo" /></Link>
          </div>
        </div>

        <div className="sidebar-inner slimscrollleft" >
          <PerfectScrollbar>
            <div id="sidebar-menu">
              <ul>
                {/* <li className="menu-title">Main</li> */}
                {
                  menu.map((menuItem) => {
                    const hasSubMenu = menuItem.submenu.length > 0;
                    let parentLiCls = hasSubMenu ? 'has_sub' : 'no_sub';
                    parentLiCls = `${parentLiCls} ${Tab === menuItem.title ? 'active-menu nav-active' : ''}`;
                    const parentLinkCls = (Tab === menuItem.title) ? 'waves-effect active-menu' : 'waves-effect';
                    return (
                      <li className={parentLiCls}>
                        <Link to={menuItem.path} className={parentLinkCls} onClick={this.setActiveTab.bind(this, menuItem.title, '', hasSubMenu)} > <i className={`mdi ${menuItem.icon}`}></i>
                          <span>
                            {menuItem.title}
                            {
                              (hasSubMenu) && (
                                <span className="pull-right"><i className="mdi mdi-chevron-right"></i></span>
                              )
                            }
                          </span>
                        </Link>
                        {
                          (hasSubMenu) && (
                            <ul className="list-unstyled" style={{ display: `(${Tab === menuItem.title}) ? 'block' : 'none'` }}>
                              {
                                menuItem.submenu.map((submenu) => {
                                  const submenuCls = (SubTab === submenu.title) ? 'active-menu' : '';
                                  return (
                                    <li>
                                      <Link className={submenuCls} onClick={this.setActiveTab.bind(this, menuItem.title, submenu.title, false)} to={submenu.path}>{submenu.title}</Link>
                                    </li>
                                  )
                                })
                              }
                            </ul>
                          )
                        }
                      </li>
                    )
                  })
                }
              </ul>
            </div>

            <div className="clearfix"></div>
          </PerfectScrollbar>
        </div>

      </div>
    );
  }
}

export default withRouter(sidebar);
