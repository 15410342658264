import React, { Component } from 'react';
import Layout from './components/Layout/Layout';
import { Route, Switch } from 'react-router-dom';
import mainbuilder from './containers/mainbuilder/mainbuilder';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AuthApi from './helper/authApi';
import Api from './helper/api';
import AUX from './hoc/Aux_';
import firebase from './init-firebase';
import Notification from './helper/Notification';
import cookie from 'react-cookies';
import customFunctions from './helper/customFunctions';

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ignore: true,
      title: '',
      options: {},
      userData: {}
    };
  }

  componentWillReceiveProps = async (props) => {
    if (props.loginpage !== this.state.loginpage) {
      this.setState({ loginpage: props.loginpage }, async () => {
        await this.setUserData();
        await this.setupNotification();
      });
    }
  }

  componentDidMount = async () => {
    await this.setUserData();
    await this.setupNotification();
  }

  setupNotification = async () => {
    const messaging = firebase.messaging();
    messaging.requestPermission().then(() => {
      return messaging.getToken();
    }).then(async (token) => {
      localStorage.setItem('notification-token', token);
      await this.setUserToken(token);
      //messaging.usePublicVapidKey(token);
    }).catch(async (err) => {
      console.log('notification', err);
      localStorage.setItem('notification-token', '');
      await this.setUserToken('');
      //console.log('error', err);
    })

    messaging.onTokenRefresh(() => {
      messaging.getToken().then(async (refreshedToken) => {
        // Indicate that the new Instance ID token has not yet been sent to the
        // app server.
        // Send Instance ID token to app server.
        localStorage.setItem('notification-token', refreshedToken);
        await this.setUserToken(refreshedToken);
        //messaging.usePublicVapidKey(refreshedToken);
        // ...
      }).catch(async (err) => {
        // console.log('Unable to retrieve refreshed token ', err);
        console.log('notification', err);
        localStorage.setItem('notification-token', '');
        await this.setUserToken();
      });
    });

    messaging.onMessage(async (payload) => {
      //console.log('Message received. ', payload);
      const icon = window.location.origin + '/assets/images/logo-sm-dark.png';
      const { id } = this.state.userData;
      if (!id || id == undefined) return;

      const conversation = payload.data;
      const notification = payload.notification;
      const title = (notification !== undefined) ? notification.title : conversation.title;
      const body = (notification !== undefined) ? notification.body : conversation.body;
      const options = {
        tag: Date.now(),
        body: body,
        icon: icon,
        //lang: customFunctions.getCurrentLanguage(),
        //dir: customFunctions.getCurrentLanguage() === 'en' ? 'ltr' : 'rtl',
        data: payload
      }
      this.setState({
        title: title,
        options: options
      });
    });
  }

  setUserData = async () => {
    const userData = await customFunctions.getUserData();
    this.setState({ userData: ((userData && userData.data) ? userData.data : {}) });
  }

  setUserToken = async (token = '') => {
    const { id } = this.state.userData;
    if (!id || id == undefined) return;

    const postData = {
      deviceid: '',
      devicetype: window.navigator.userAgent,
      token: token,
      userid: parseInt(id)
    };
    const callback = AuthApi.postDataToServer;
    const endPoint = Api.setfirebasetoken;
    const { data, message } = await callback(endPoint, postData);
    if (data && data.status !== "Fail") {
      //success
    } else {
      // failed
    }
  }

  handlePermissionGranted = () => {
    this.setState({
      ignore: false
    });
  }

  handlePermissionDenied = () => {
    this.setState({
      ignore: true
    });
  }

  handleNotSupported = () => {
    this.setState({
      ignore: true
    });
  }

  handleNotificationOnClick = (e, tag) => {
    window.focus();
    var data = e.target.data;
    if (data && data.data) {
      if (data.data.type === "NEWODRADMIN") {
        var shipmentId = JSON.parse(data.data.maincontent).length > 0 ? JSON.parse(data.data.maincontent)[0].shipmentid : '';
        var url = shipmentId ? '/order-view/' + shipmentId : '/orders';
        this.props.history.push(url);
      }
    }
  }

  handleNotificationOnError = (e, tag) => {
  }

  handleNotificationOnClose = (e, tag) => {
  }

  handleNotificationOnShow = (e, tag) => {

  }

  render() {
    let layout = null;
    const { title, ignore, options } = this.state;
    layout = this.props.header && this.props.sidebar && this.props.footer && (
      <Layout header={this.props.header} sidebar={this.props.sidebar} footer={this.props.footer} loginpage={this.props.loginpage}>
        <Switch>
          <Route path="/" component={mainbuilder} />
        </Switch>
      </Layout>
    );
    return (
      <AUX>
        {layout}
        <Notification
          ignore={ignore && title !== ''}
          notSupported={this.handleNotSupported}
          onPermissionGranted={this.handlePermissionGranted}
          onPermissionDenied={this.handlePermissionDenied}
          onShow={this.handleNotificationOnShow}
          onClick={this.handleNotificationOnClick}
          onClose={this.handleNotificationOnClose}
          onError={this.handleNotificationOnError}
          timeout={5000}
          title={title}
          options={options}
        />
      </AUX>
    );
  }
}
const mapStatetoProps = state => {
  return {
    header: state.ui_red.header,
    sidebar: state.ui_red.sidebar,
    footer: state.ui_red.footer,
    loginpage: state.ui_red.loginpage
  };
}

export default withRouter(connect(mapStatetoProps)(App));
