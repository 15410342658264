import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import Geocode from "react-geocode";
import constants from '../../helper/constants.js';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
Geocode.setApiKey(constants.mapKey);

const bootstrapURLKeys = {
  key: constants.mapKey,
  libraries: ['drawing', 'visualization'].join(','),
}

class GoogleMapPolygon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      setPickupBound: '',
      centerCoords: { lat: 50.813264, lng: -130.1653687 }
    }
  }

  allPolygons = [];

  selectedShape = null;

  objGoogle = null;

  componentWillReceiveProps = async (nextProps) => {
    if (this.props.inText !== nextProps.inText) {
      await this.setMapBound(nextProps.inText);
      this.setDefaultCoordinates();
    }
    // console.log('this.props.defaultCoordinates', this.props.defaultCoordinates);
    // console.log('nextProps.defaultCoordinates', nextProps.defaultCoordinates);
    // if (this.props.defaultCoordinates.length !== nextProps.defaultCoordinates.length) {
    // }
  }


  clearSelection = () => {
    if (this.selectedShape) {
      this.selectedShape.setEditable(false);
      this.selectedShape.set('strokeColor', '#ff0000');
      this.selectedShape = null;
    }
  }

  setSelection = (shape) => {
    this.clearSelection();
    this.selectedShape = shape;
    this.selectedShape.set('strokeColor', '#1E90FF');
    // shape.setEditable(true);
    // selectColor(shape.get('fillColor') || shape.get('strokeColor'));
  }

  deleteSelectedShape = async () => {
    if (this.selectedShape) {
      await this.selectedShape.setMap(null);
      this.getAllPolygonsPaths();
    }
  }

  handleGoogleMapApi = async (google) => {
    this.objGoogle = google;
    await this.setMapBound();
    const map = google.map
    const drawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
      drawingControl: true,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [
          google.maps.drawing.OverlayType.POLYGON,
        ],
        editable: true
      },
      polygonOptions: {
        // fillColor: '#ffff00',
        fillOpacity: 0.2,
        strokeWeight: 2,
        clickable: true,
        editable: false,
        strokeColor: '#ff0000',
        // zIndex: 1
      }
    });
    drawingManager.setMap(map);
    // this.setDefaultCoordinates();

    var $this = this;
    google.maps.event.addListener(drawingManager, 'overlaycomplete', async function (event) {
      const isInBound = await $this.containsPolygon($this, event.overlay);
      if (!$this.props.inText) {
        event.overlay.setMap(null);
        NotificationManager.error('Please select country and then try to select area');
        return;
      }
      if (!isInBound) {
        event.overlay.setMap(null);
        NotificationManager.error('Polygon coordinates are outside of selected area');
        return;
      };
      $this.allPolygons.push(event);
      const newShape = event.overlay;
      newShape.type = event.type;
      google.maps.event.addListener(newShape, 'click', function () {
        $this.setSelection(newShape);
      });
      $this.getAllPolygonsPaths();
      $this.setSelection(newShape);
    });
    google.maps.event.addListener(drawingManager, 'drawingmode_changed', this.clearSelection);
    google.maps.event.addListener(map, 'click', this.clearSelection);
    // google.maps.event.addDomListener(document.getElementById('delete-button'), 'click', this.deleteSelectedShape);
    // google.maps.event.addDomListener(document.getElementById('delete-all-button'), 'click', deleteAllShape);

  }

  setDefaultCoordinates = () => {
    const { defaultCoordinates } = this.props;
    if (defaultCoordinates && defaultCoordinates.length > 0) {
      for (let k = 0; k < defaultCoordinates.length; k++) {
        const bermudaTriangle = new this.objGoogle.maps.Polygon({
          paths: defaultCoordinates[k],
          strokeColor: '#ff0000',
          strokeWeight: 2,
          fillOpacity: 0.2,
        });
        var $this = this;
        if (!$this.containsPolygon($this, bermudaTriangle)) return;
        bermudaTriangle.setMap(this.objGoogle.map);
        const obj = { overlay: bermudaTriangle, type: 'polygon' }
        this.allPolygons.push(obj);
        const newShape = bermudaTriangle;
        newShape.type = 'polygon';
        this.objGoogle.maps.event.addListener(newShape, 'click', function () {
          $this.setSelection(newShape);
        });
        // $this.setSelection(newShape);
      }
    }
  }

  containsPolygon = ($this, polygon) => {
    if (!$this.state.setPickupBound) return true;
    return polygon.getPaths().getArray().every(function (path) {
      return path.getArray().every(function (coord) {
        return $this.state.setPickupBound.contains(coord);
      });
    });
  }


  setMapBound = async (inText = '') => {
    let setboundLoc = inText ? inText : this.props.inText;
    var latlong = await this.getLatLogFromAddress(setboundLoc);
    if (latlong) {
      let bounds = (latlong.geometry.bounds !== undefined) ? latlong.geometry.bounds : latlong.geometry.viewport;
      var points = [
        bounds.northeast,
        bounds.southwest
      ]
      var boundsArr = new this.objGoogle.maps.LatLngBounds()
      for (var i = 0; i < points.length; i++) {
        boundsArr.extend(points[i]);
      }
      this.setState({ setPickupBound: boundsArr, centerCoords: latlong.geometry.location });
    } else {
      // alert('sdsd')
      this.setState({ setPickupBound: '' });
    }

  }

  getLatLogFromAddress = async (address) => {
    var latLng = '';
    if (!address) return '';

    latLng = new Promise(async (resolve, reject) => {
      await Geocode.fromAddress(address).then(
        response => {
          const { lat, lng } = response.results[0].geometry.location;
          resolve(response.results[0]);
        },
        error => {
          console.error('geocode errror', error);
          resolve('');
        }
      );
    });
    return latLng;
  }

  getAllPolygonsPaths = () => {
    const { getPolygons } = this.props;
    let polygons = [];

    for (let i = 0; i < this.allPolygons.length; i++) {
      if (!this.allPolygons[i].overlay.getMap()) continue;
      let poly = [];
      const vertices = this.allPolygons[i].overlay.getPath();
      for (let i = 0; i < vertices.getLength(); i++) {
        const xy = vertices.getAt(i);
        // poly.push({ lat: xy.lat(), lng: xy.lng() });
        poly.push([xy.lat(), xy.lng()]);
      }
      if (poly.length > 0) poly.push(poly[0]);
      polygons.push([poly]);
    }
    if (getPolygons) getPolygons(polygons);
    // return polygons;
  }

  render() {
    const { centerCoords } = this.state;
    return (
      <React.Fragment>
        <NotificationContainer />
        <GoogleMapReact
          bootstrapURLKeys={bootstrapURLKeys}
          center={centerCoords}
          zoom={7}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={this.handleGoogleMapApi.bind(this)}
          style={{ 'min-height': '300px', 'position': 'relative', 'margin-bottom': '10px' }}
        >
        </GoogleMapReact>
        <button id="delete-button" className="btn btn-primary" type="button" onClick={() => {
          if (!this.selectedShape) return;
          this.deleteSelectedShape();
        }} >Delete polygon</button>
        {/* <button className="btn btn-primary ml-2" type="button" onClick={() => {
          this.getAllPolygonsPaths();
        }} >Get polygons</button> */}
      </React.Fragment>
    );
  }
}

export default GoogleMapPolygon;