import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import customFunctions from '../../../helper/customFunctions';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';


class AddCharges extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countryid: '',
      countries: [],
      cityid: '',
      cities: [],
      customercharges: '',
      vendorcharges: '',
      chargeId: null,
      deliverychargeid: '',
      currencies: ['USD', 'KD'],
      currency: 'USD',
      kurbiFee: '',
      kurbiFeeErr: ''
    }
  }

  componentDidMount = async () => {
    const chargeId = this.props.match.params.id;
    this.setState({ chargeId });
    this.getCountries();
    if (chargeId) {
      customFunctions.setTitle('Edit delivery charges');
      this.getGetDeliveryCharge(chargeId);
    } else {
      customFunctions.setTitle('Add delivery charges');
    }
  }

  getGetDeliveryCharge = async (chargeId) => {
    const { data, message } = await AuthApi.getDataFromServer(`${Api.getDeliverychargeByCity}/${chargeId}`);
    if (data && data.status !== "Fail") {
      this.getCities(data.data.countryid)
      this.setState({
        countryid: data.data.countryid,
        cityid: data.data.cityid,
        customercharges: data.data.customercharges,
        vendorcharges: data.data.vendorcharges,
        deliverychargeid: data.data.id,
        kurbiFee: data.data.kurbicharges
      });
    } else {
      this.props.history.push('/delivery-charges');
    }

  }

  getCountries = async () => {
    const { data } = await AuthApi.getDataFromServer(Api.getCountries);
    if (data && data.status !== "Fail") {
      this.setState({ countries: data.data });
    }
  }

  getCities = async (countryid) => {
    const { data } = await AuthApi.getDataFromServer(`${Api.getCities}/${countryid}`);
    if (data && data.status !== "Fail") {
      this.setState({ cities: data.data });
    }
  }

  validateForm = (e) => {
    e.preventDefault();
    const {
      customercharges, vendorcharges, cityid, countryid, kurbiFee
    } = this.state;
    let customerchargesErr = '', vendorchargesErr = '', cityErr = '', countryErr = '', kurbiFeeErr = '', isValid = true;

    if (customercharges.toString().trim() === '') {
      customerchargesErr = 'Customer charge is required'; isValid = false;
    } else if (!customFunctions.validateAmount(customercharges)) {
      customerchargesErr = 'Invalid input'; isValid = false;
    }

    if (vendorcharges.toString().trim() === '') {
      vendorchargesErr = 'Merchant charge is required'; isValid = false;
    } else if (!customFunctions.validateAmount(vendorcharges)) {
      vendorchargesErr = 'Invalid input'; isValid = false;
    }

    if (kurbiFee.toString().trim() === '') {
      kurbiFeeErr = 'Kurbi fee is required'; isValid = false;
    } else if (!customFunctions.validateAmount(kurbiFee)) {
      kurbiFeeErr = 'Invalid input'; isValid = false;
    }

    if (cityid.toString().trim() === '') {
      cityErr = 'City is required'; isValid = false;
    }

    if (countryid.toString().trim() === '') {
      countryErr = 'Country is required'; isValid = false;
    }

    this.setState({ customerchargesErr, vendorchargesErr, cityErr, countryErr, kurbiFeeErr });
    if (isValid) this.addDeliveryCharges();

  }

  addDeliveryCharges = async () => {
    const { customercharges, vendorcharges, cityid, chargeId, deliverychargeid, kurbiFee } = this.state;
    const payload = {
      customercharges,
      vendorcharges,
      cityid,
      kurbicharges: kurbiFee
    };
    if (chargeId) payload.deliverychargeid = deliverychargeid;
    const endpoint = chargeId ? Api.updateDeliveryCharges : Api.addDeliveryCharges;
    const method = chargeId ? AuthApi.putDataToServer : AuthApi.postDataToServer;
    const { data, message } = await method(endpoint, payload);
    if (data && data.status !== "Fail") {
      if (chargeId) {
        var $this = this;
        NotificationManager.success('Delivery charges updated', '', 1000);
        setTimeout(() => {
          $this.props.history.push('/delivery-charges');
        }, 1000);
        return;
      }
      NotificationManager.success('Charges added');
      this.resetData();
    } else {
      NotificationManager.error((data && data.message) || message || 'Please try again later');
    }
  }

  resetData = () => {
    this.setState({
      customercharges: '', vendorcharges: '', cityid: '', countryid: '', cities: []
    });
  }

  render() {
    const {
      customercharges, vendorcharges, cityid, countryid, countries, cities, currencies, currency, kurbiFee,
      customerchargesErr, vendorchargesErr, cityErr, countryErr, kurbiFeeErr
    } = this.state;
    return (
      <AUX>
        <div className="page-content-wrapper">
          <NotificationContainer />
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <form onSubmit={this.validateForm}>
                      <div className="form-group row">
                        <label for="example-text-input" className="col-sm-2 col-form-label">Country*</label>
                        <div className="col-sm-5">
                          <select className="form-control" value={countryid} onChange={(e) => {
                            this.setState({ countryid: e.target.value, cityid: '' });
                            this.getCities(e.target.value);
                          }}>
                            <option value=''>Select country</option>
                            {
                              countries.map((country) => (
                                <option value={country.countryid}>{country.countryname_eng}</option>
                              ))
                            }
                          </select>
                          <span id="err">{countryErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label for="example-text-input" className="col-sm-2 col-form-label">City*</label>
                        <div className="col-sm-5">
                          <select className="form-control" value={cityid} onChange={(e) => {
                            this.setState({ cityid: e.target.value });
                            // this.getCities(e.target.value);
                          }}>
                            <option value=''>Select city</option>
                            {
                              cities.map((city) => (
                                <option value={city.cityid}>{city.cityname_eng}</option>
                              ))
                            }
                          </select>
                          <span id="err">{cityErr}</span>
                        </div>
                      </div>
                      {/* <div className="form-group row">
                        <label for="example-text-input" className="col-sm-2 col-form-label">Currency*</label>
                        <div className="col-sm-5">
                          <select className="form-control" value={currency} onChange={(e) => {
                            this.setState({ currency: e.target.value });
                            // this.getCities(e.target.value);
                          }}>
                            {
                              currencies.map((cur) => (
                                <option value={cur}>{cur}</option>
                              ))
                            }
                          </select>
                        </div>
                      </div> */}
                      <div className="form-group row">
                        <label for="example-text-customer-charges" className="col-sm-2 col-form-label">Customer delivery charges*</label>
                        <div className="col-sm-5">
                          <div className="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text" id="basic-addon1">$</span>
                            </div>
                            <input className="form-control" value={customercharges} type="text" placeholder="50" id="example-text-customer-charges" onChange={(e) => {
                              if (customFunctions.validateAmount(e.target.value) || e.target.value == '') {
                                this.setState({ customercharges: e.target.value })
                              }
                            }} />
                          </div>
                          <span id="err">{customerchargesErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label for="example-text-vendor-charges" className="col-sm-2 col-form-label">Vendor delivery charges*</label>
                        <div className="col-sm-5">
                          <div className="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text" id="basic-addon1">$</span>
                            </div>
                            <input className="form-control" value={vendorcharges} type="text" placeholder="50" id="example-text-vendor-charges" onChange={(e) => {
                              if (customFunctions.validateAmount(e.target.value) || e.target.value == '') {
                                this.setState({ vendorcharges: e.target.value })
                              }
                            }} />
                          </div>
                          <span id="err">{vendorchargesErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label for="example-text-kurbi-fee" className="col-sm-2 col-form-label">Kurbi fee*</label>
                        <div className="col-sm-5">
                          <div className="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text" id="basic-addon1">$</span>
                            </div>
                            <input className="form-control" value={kurbiFee} type="text" placeholder="50" id="example-text-kurbi-fee" onChange={(e) => {
                              if (customFunctions.validateAmount(e.target.value) || e.target.value == '') {
                                this.setState({ kurbiFee: e.target.value })
                              }
                            }} />
                          </div>
                          <span id="err">{kurbiFeeErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-sm-3">
                          <button type="submit" className="btn btn-primary waves-effect waves-light">
                            Submit
                        </button>
                          <button type="reset" onClick={() => {
                            this.props.history.push('/delivery-charges');
                          }} className="btn btn-secondary waves-effect m-l-10">
                            Cancel
                        </button>
                        </div>
                      </div>

                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

export default AddCharges;