import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import InputMask from 'react-input-mask';
import MaterialInput from '@material-ui/core/Input';
import customFunctions from '../../../helper/customFunctions';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Select from 'react-select';
import SharedService from '../../../helper/SharedService';


class AddAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: '',
      lastname: '',
      mail: '',
      password: '',
      mobile: '',
      country: null,
      city: null,
      countrycode: '',
      confirmPassword: '',
      fnameErr: '',
      lnameErr: '',
      mailErr: '',
      mobileErr: '',
      countryErr: '',
      cityErr: '',
      passErr: '',
      cpassErr: '',
      roleid: 4,
      adminId: null,
      countries: [],
      cities: [],
      updatePassword: false,
      userid: '',
      selectedCities: [],
      cityOptions: []
    }
  }

  componentDidMount = async () => {
    const adminId = this.props.match.params.adminId;
    this.setState({ adminId });
    this.getCountries();
    if (adminId) {
      this.getAdminData(adminId);
      customFunctions.setTitle('Edit admin');
    } else {
      customFunctions.setTitle('Add admin');
    }

  }

  getCountries = async () => {
    const { data } = await AuthApi.getDataFromServer(Api.getCountries);
    if (data && data.status !== "Fail") {
      this.setState({ countries: data.data });
    }
  }

  getCities = async (countryid) => {
    const { data } = await AuthApi.getDataFromServer(`${Api.getCities}/${countryid}`);
    if (data && data.status !== "Fail") {
      const { adminId, city } = this.state;
      const allCities = data.data;
      const cityOptions = [];
      const selectedCities = []
      var cities = (city) ? city.split(',') : [];
      allCities.map((city) => {
        cityOptions.push({
          label: city.cityname_eng,
          value: city.cityid
        })
        if (adminId) {
          if (cities.indexOf(String(city.cityid)) > -1) {
            selectedCities.push({
              label: city.cityname_eng,
              value: city.cityid
            })
          }
        }
      });
      this.setState({ cities: data.data, cityOptions, selectedCities });
    } else {
      this.setState({ cityOptions: [] });
    }
  }

  getAdminData = async (adminId) => {
    const { data, message } = await AuthApi.getDataFromServer(`${Api.getAdmin}/${adminId}`);
    // const data = await customFunctions.getUserData();
    // const message = 'Something went wrong';
    if (data) {
      var countryid = data.data.countryid;
      this.setState({
        firstname: data.data.first_name,
        lastname: data.data.last_name,
        mail: data.data.email,
        city: data.data.cityid,
        country: data.data.countryid,
        mobile: data.data.mobile,
        countrycode: data.data.countrycode ? data.data.countrycode.toString() : '',
        userid: data.data.userid
      }, () => {
        this.getCities(countryid);
      });

    } else {
      var $this = this;
      NotificationManager.error(message, '', 1000);
      setTimeout(() => {
        $this.props.history.push('/dashboard');
      }, 1000);
    }
  }

  validateForm = async (e) => {
    e.preventDefault();
    const { firstname, lastname, mail, mobile, country, password, confirmPassword, adminId, city, countrycode, selectedCities } = this.state;
    let fnameErr = '', lnameErr = '', mailErr = '', mobileErr = '', passErr = '', cpassErr = '', countryErr = '', cityErr = '';
    let isValid = true;
    let updatePassword = false;
    if (firstname.trim() === '') {
      fnameErr = 'First name is required'; isValid = false
    }

    if (lastname.trim() === '') {
      lnameErr = 'Last name is required'; isValid = false
    }

    if (mail.trim() === '') {
      mailErr = 'Email is required'; isValid = false;
    } else if (!customFunctions.validateEmail(mail)) {
      mailErr = 'Invalid email'
    }


    if (String(mobile).trim() === '') {
      mobileErr = 'Mobile is required'; isValid = false;
    } else if (!customFunctions.validatePhoneNumber(String("+" + countrycode + " " + mobile))) {
      mobileErr = 'Invalid mobile'; isValid = false;
    }

    if (!country) {
      countryErr = 'Country is required'; isValid = false
    }

    if (!selectedCities || selectedCities.length === 0) {
      cityErr = 'City is required'; isValid = false
    }


    if ((!adminId) || (adminId && (password.trim() !== '' || confirmPassword.trim() !== ''))) {
      if (password.trim() === '') {
        passErr = 'Password is required'; isValid = false;
      }

      if (confirmPassword.trim() === '') {
        cpassErr = 'Password confirmation is required'; isValid = false;
      } else if (password !== confirmPassword) {
        cpassErr = 'Password did not match'; isValid = false;
      }
      updatePassword = true;
    }

    await this.setState({ fnameErr, lnameErr, mailErr, mobileErr, passErr, cpassErr, countryErr, cityErr, updatePassword });
    if (isValid) {
      this.updateAdminProfile()
    }
  }

  updateAdminProfile = async () => {
    const { firstname, lastname, mail, mobile, password, roleid, adminId, countrycode, country, city, updatePassword, userid, selectedCities } = this.state;
    const cityIds = [];
    selectedCities.map((city) => { cityIds.push(city.value) });
    const postData = {
      firstname,
      lastname,
      // mail,
      countrycode: parseInt(countrycode),
      countryid: country,
      cityid: cityIds,
      // password,
      mobile: mobile.toString(),
      // adminid: adminId
      // roleid
    };
    if (adminId) {
      postData.adminid = adminId
    } else {
      postData.password = password;
      postData.mail = mail;
      postData.roleid = roleid;
      postData.sendemail = 0
    }

    const callback = adminId ? AuthApi.putDataToServer : AuthApi.postDataToServer;
    const endPoint = adminId ? Api.updateAdmin : Api.addAdmin;

    const { data, message } = await callback(endPoint, postData);

    if (data && data.status !== "Fail") {
      if (adminId) {
        if (updatePassword) {
          const payload = {
            userid,
            password
          };
          const passData = await AuthApi.putDataToServer(Api.updatePassword, payload);
        }
        SharedService.reloadAdminCities.next(true);
        var $this = this;
        NotificationManager.success('Admin updated', '', 1000);
        setTimeout(() => {
          $this.props.history.push('/admins');
        }, 1000);
        return;
      }
      NotificationManager.success('Admin added', '', 1000);
      this.resetData();
    } else {
      NotificationManager.error((data && data.message) || message || 'Please try again later');
    }
  }

  handleCityChange = (selectedCities) => {
    this.setState({ selectedCities });
  }

  resetData = () => {
    this.setState({
      firstname: '', lastname: '', mail: '', password: '', mobile: '', countrycode: '', confirmPassword: '', fnameErr: '', lnameErr: '', mailErr: '', mobileErr: '', passErr: '', cpassErr: '', roleid: 1, countrycodeErr: '', city: null, cities: [], cityErr: '', country: null, country: '', selectedCities: [], cityOptions: []
    });
  }

  render() {
    const {
      firstname, lastname, mail, country, password, confirmPassword, fnameErr, lnameErr, mailErr, passErr, cpassErr, adminId, countryErr, cityErr, countries, cities, city, mobile, mobileErr, selectedCities, cityOptions
    } = this.state;
    return (
      <AUX>
        <div className="page-content-wrapper">
          <NotificationContainer />
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <form onSubmit={this.validateForm}>
                      <div className="form-group row">
                        <label for="example-text-input" className="col-sm-2 col-form-label">First Name*</label>
                        <div className="col-sm-5">
                          <input className="form-control" value={firstname} type="text" placeholder="John" id="example-text-input" onChange={(e) => { this.setState({ firstname: e.target.value }) }} />
                          <span id="err">{fnameErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label for="example-search-input" className="col-sm-2 col-form-label">Last Name*</label>
                        <div className="col-sm-5">
                          <input className="form-control" value={lastname} type="text" placeholder="Doe" id="example-search-input" onChange={(e) => { this.setState({ lastname: e.target.value }) }} />
                          <span id="err">{lnameErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label for="example-email-input" className="col-sm-2 col-form-label">Email*</label>
                        <div className="col-sm-5">
                          <input className="form-control" value={mail} type="text" placeholder="user@example.com" id="example-email-input" onChange={(e) => {
                            if (!adminId) this.setState({ mail: e.target.value })
                          }} disabled={adminId} />
                          <span id="err">{mailErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label for="example-text-input" className="col-sm-2 col-form-label">Country*</label>
                        <div className="col-sm-5">
                          <select className="form-control" value={country} onChange={(e) => {
                            const sc = countries.filter((c) => { return Number(c.countryid) === Number(e.target.value) });
                            const scid = (sc && sc.length > 0) ? sc[0].countrycode : '';
                            this.setState({ country: e.target.value, city: null, countrycode: scid });
                            this.getCities(e.target.value);
                          }}>
                            <option value=''>Select country</option>
                            {
                              countries.map((country) => (
                                <option value={country.countryid}>{country.countryname_eng}</option>
                              ))
                            }
                          </select>
                          <span id="err">{countryErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label for="example-text-input" className="col-sm-2 col-form-label">City*</label>
                        <div className="col-sm-5">
                          <Select
                            value={selectedCities}
                            onChange={this.handleCityChange}
                            options={cityOptions}
                            isMulti={true}
                          />
                          {/* <select className="form-control" value={city} onChange={(e) => {
                            this.setState({ city: e.target.value });
                          }}>
                            <option value=''>Select city</option>
                            {
                              cities.map((city) => (
                                <option value={city.cityid}>{city.cityname_eng}</option>
                              ))
                            }
                          </select> */}
                          <span id="err">{cityErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label">Mobile*</label>
                        <div className="col-sm-5">
                          <input type="text" className="form-control" value={mobile} placeholder="9999999999"
                            onChange={(e) => {
                              var val = e.target.value;
                              var phoneformat = /^\d{1,15}$/;
                              if (val === '' || val.match(phoneformat)) {
                                this.setState({ mobile: val })
                              }
                            }}
                          />
                          <span id="err">{mobileErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label for="example-url-input" className="col-sm-2 col-form-label">Password
                        {!adminId && (<React.Fragment>*</React.Fragment>)}</label>
                        <div className="col-sm-5">
                          <input className="form-control" value={password} type="password" placeholder="*****" id="example-url-input" onChange={(e) => { this.setState({ password: e.target.value }) }} />
                          <span id="err">{passErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label for="example-tel-input" className="col-sm-2 col-form-label">Confirm Password
                        {!adminId && (<React.Fragment>*</React.Fragment>)}</label>
                        <div className="col-sm-5">
                          <input className="form-control" value={confirmPassword} type="password" placeholder="*****" id="example-tel-input" onChange={(e) => { this.setState({ confirmPassword: e.target.value }) }} />
                          <span id="err">{cpassErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-sm-3">
                          <button type="submit" className="btn btn-primary waves-effect waves-light">
                            Submit
                        </button>
                          <button type="reset" onClick={() => {
                            this.props.history.push('/customers');
                          }} className="btn btn-secondary waves-effect m-l-10">
                            Cancel
                        </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

export default withRouter(AddAdmin);   