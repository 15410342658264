import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import ReactSelect from '../../../components/ReactSelect/reactSelect';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';

class AddSubMerchant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      merchantsData: [],
      subVendors: [],
      selectedOption: [],
      errorMessage: '',
      superVendorId: null,
      subVendorList: [],
    };
  }

  componentDidMount = () => {
    if (this.props && this.props.vendorId) {
      this.setState({
        superVendorId: this.props.vendorId
      }, () => {
        this.getSubVendorList();
      })
    }
    this.getMerchantList();
  }

  getSubVendorList = async () => {
    const { superVendorId } = this.state;
    const endPoint = `${Api.subVendorList}?vendorid=${superVendorId}`;
    const callback = AuthApi.getDataFromServer;

    const { data, message, status } = await callback(endPoint);
    if (data) {
      this.setState({
        subVendorList: data.data.subVendors,
      }, () => {
        let sampleData = [];
        this.state.subVendorList && this.state.subVendorList.length > 0 && this.state.subVendorList.map((list) => {
          sampleData.push({
            value: list.vendorid,
            label: list.vendorname
          });
          this.setState({
            selectedOption: sampleData
          });
        })
      });
    } else {
      if (status === 401) {
        this.props.history.push('/login');
        return;
      }
      NotificationManager.error(message);
    }
  }

  getMerchantList = async () => {
    const endPoint = `${Api.getMerchants}/${0}/${1000}`;
    const callback = AuthApi.getDataFromServer;

    const { data, message, status } = await callback(endPoint);
    if (data && data.data) {
      const merchant = [];
      const result = data && data.data.filter((item) => {
        return item.vendorid !== Number(this.props.vendorId)
      });

      if (result && result.length > 0) {
        result.forEach((item) => {
          merchant.push({
            value: item.vendorid,
            label: item.vendorname
          });
        })
        this.setState({
          merchantsData: merchant,
        });
      }
    } else {
      if (status === 401) {
        this.props.history.push('/login');
        return;
      }
      NotificationManager.error(message);
    }
  }

  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
  };

  addSubMerchant = async () => {
    const { selectedOption } = this.state;
    let subVendor = [];
    if (selectedOption && selectedOption.length > 0) {
      selectedOption.map((option) => {
        subVendor.push(option.value)
      });
    }
    const endpoint = `${Api.assignSubVendors}`;
    const payload = {
      "supervendorid": Number(this.props.vendorId),
      "vendors": subVendor
    }

    const { data, message } = await AuthApi.postDataToServer(endpoint, payload);
    if (data && data.status == 'success') {
      if (this.props.popupClose) this.props.popupClose();
      if (this.props.callbackfun) this.props.callbackfun();
      NotificationManager.success('Sub vendor added', '', 900);
      return;
    }
    else {
      NotificationManager.error((data && data.message) || message || 'Please try again later');
    }
  }

  render() {
    const { merchantsData, errorMessage, selectedOption } = this.state;
    return (
      <div>
        <Modal
          className={''}
          size="md"
          centered
          onHide={this.props.popupClose}
          show={this.props.subMerchantPopup}
        >
          <div className="">
            <Modal.Header className="col-12">
              Add Sub Merchant
            </Modal.Header>
            <Modal.Body>
              <>
                <div className="form-group">
                  <label>Select Merchants</label>
                  <ReactSelect
                    options={merchantsData}
                    value={selectedOption}
                    isMulti
                    onChange={this.handleChange}
                  />
                  <span id="err">{errorMessage}</span>
                </div>
              </>
            </Modal.Body>
            <Modal.Footer id="md-filder" className="w-100">
              <div className="float-right">
                <Button
                  variant="primary"
                  type="submit"
                  className="saveBtns l-m-10"
                  onClick={this.addSubMerchant}
                >
                  Submit
                </Button>
                <Button
                  variant="secondary"
                  className="saveBtns ml-2"
                  onClick={this.props.popupClose}
                >
                  Back
                </Button>
              </div>
            </Modal.Footer>
          </div>
        </Modal>
      </div>
    );
  }
}

export default AddSubMerchant;
