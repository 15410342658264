import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import customFunctions from '../../../helper/customFunctions';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import cmsPagesData from '../../../helper/sampledata/cms';



class AddCustomer extends Component {
  constructor(props) {
    super(props);
    const html = '';
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      const editorStateArabic = EditorState.createWithContent(contentState);
      this.state = {
        editorState,
        editorStateArabic,
        title: '',
        titleArabic: '',
        content: '',
        contentArabic: '',
        status: 'draft',
        titleErr: '',
        contentErr: '',
        contentArabicErr: '',
        pageId: null,
      }
    }
  }

  componentDidMount = () => {
    const pageId = this.props.match.params.pageId;
    this.setState({ pageId });
    if (pageId) {
      this.getPageData(pageId);
      customFunctions.setTitle('Edit CMS Page');
    } else {
      customFunctions.setTitle('Add CMS Page');
    }
  }

  getPageData = async (pageId) => {
    const { data } = await AuthApi.getDataFromServer(`${Api.getSingleCmsPage}/${pageId}`);
    if (data && data.status !== "Fail") {
      const contentBlock = htmlToDraft(data.data.pagecontent_eng);
      const contentBlockAr = htmlToDraft(data.data.pagecontent_arabic);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const contentStateAr = ContentState.createFromBlockArray(contentBlockAr.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        const editorStateArabic = EditorState.createWithContent(contentStateAr);
        this.setState({
          pageDetails: data.data,
          title: data.data.pagetitle_eng,
          titleArabic: data.data.pagetitle_arabic,
          content: draftToHtml(convertToRaw(editorState.getCurrentContent())),
          contentArabic: draftToHtml(convertToRaw(editorStateArabic.getCurrentContent())),
          status: data.data.status,
          editorState,
          editorStateArabic
        });
      }
    } else {
      this.props.history.push('/cms-pages');
    }
  }

  validateForm = (e) => {
    e.preventDefault();
    const { title, content, titleArabic, contentArabic } = this.state;
    let titleErr = '', contentErr = '', titleArabicErr = '', contentArabicErr = '';
    let isValid = true;

    if (title.trim() === '') {
      titleErr = 'English title is required'; isValid = false
    }

    if (titleArabic.trim() === '') {
      titleArabicErr = 'Arabic title is required'; isValid = false
    }

    if (content.trim() === '' || content.trim() === '<p></p>') {
      contentErr = 'English content is required'; isValid = false
    }

    if (contentArabic.trim() === '' || contentArabic.trim() === '<p></p>') {
      contentArabicErr = 'Arabic content is required'; isValid = false
    }

    this.setState({ titleErr, contentErr, titleArabicErr, contentArabicErr });
    if (isValid) {
      this.addCmsPage()
    }
  }

  addCmsPage = async () => {
    const { title, titleArabic, content, contentArabic, status, pageId } = this.state;
    const postData = {
      pagetitleeng: title,
      pagetitlearabic: titleArabic,
      pagecontenteng: content,
      pagecontentarabic: contentArabic,
      // status,
    };
    const callback = pageId ? AuthApi.putDataToServer : AuthApi.postDataToServer;
    const endPoint = pageId ? Api.updateCmsPage : Api.addCmsPage;
    if (pageId) {
      postData.pageid = parseInt(pageId);
    }
    const { data, message } = await callback(endPoint, postData);
    if (data) {
      var $this = this;
      if (pageId) {
        NotificationManager.success('Page edited', '', 1000);
      } else {
        NotificationManager.success('Page added', '', 1000);
      }
      setTimeout(() => {
        $this.props.history.push('/cms-pages');
      }, 1000);
      // this.resetData();
    } else {
      NotificationManager.error((data && data.message) || message || 'Please try again later');
    }
  }

  resetData = () => {
    this.setState({
      title: '', titleArabic: '', content: '', contentArabic: '', status: 'draft', contentErr: '', titleErr: '', pageId: null, titleArabicErr: '', contentArabicErr: ''
    });
  }

  render() {
    const {
      title, titleArabic, content, status, titleErr, contentErr, editorState, editorStateArabic, titleArabicErr, contentArabic, contentArabicErr
    } = this.state;
    return (
      <AUX>
        <div className="page-content-wrapper">
          <NotificationContainer />
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <form onSubmit={this.validateForm}>
                      <div className="form-group row">
                        <label for="example-text-input" className="col-sm-2 col-form-label">Title in English*</label>
                        <div className="col-sm-8">
                          <input className="form-control" value={title} type="text" placeholder="Page title in English" id="example-text-input" onChange={(e) => { this.setState({ title: e.target.value }) }} />
                          <span id="err">{titleErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label for="example-text-input-arabic" className="col-sm-2 col-form-label">Title in Arabic*</label>
                        <div className="col-sm-8">
                          <input className="form-control" value={titleArabic} type="text" placeholder="Page title in Arabic" id="example-text-input-arabic" onChange={(e) => { this.setState({ titleArabic: e.target.value }) }} />
                          <span id="err">{titleArabicErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label for="example-search-input" className="col-sm-2 col-form-label">Content in English*</label>
                        <div className="col-sm-8">
                          <Editor
                            editorState={editorState}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onChange={(val) => {
                              // console.log('-val-');
                              // console.log(val);
                            }}
                            onEditorStateChange={(editorState) => {
                              this.setState({
                                editorState,
                                content: draftToHtml(convertToRaw(editorState.getCurrentContent()))
                              });
                            }}
                          >
                            <textarea
                              disabled
                              value={content}
                            />
                          </Editor>
                          <span id="err">{contentErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label for="example-search-input" className="col-sm-2 col-form-label">Content in Arabic*</label>
                        <div className="col-sm-8">
                          <Editor
                            editorState={editorStateArabic}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onChange={(val) => {
                              // console.log('-val-');
                              // console.log(val);
                            }}
                            onEditorStateChange={(editorStateArabic) => {
                              this.setState({
                                editorStateArabic,
                                contentArabic: draftToHtml(convertToRaw(editorStateArabic.getCurrentContent()))
                              });
                            }}
                          >
                            <textarea
                              disabled
                              value={contentArabic}
                            />
                          </Editor>
                          <span id="err">{contentArabicErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label for="example-email-input" className="col-sm-2 col-form-label">Status*</label>
                        <div className="col-sm-3">
                          <select className="form-control" value={status} onChange={(e) => {
                            this.setState({ status: e.target.value })
                          }}>
                            <option value="draft">Draft</option>
                            <option value="published">Publish</option>
                          </select>
                        </div>
                      </div>

                      <div className="form-group row">
                        <div className="col-sm-3">
                          <button type="submit" className="btn btn-primary waves-effect waves-light">
                            Submit
                        </button>
                          <button type="reset" onClick={() => {
                            this.props.history.push('/cms-pages');
                          }} className="btn btn-secondary waves-effect m-l-10">
                            Cancel
                        </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

export default withRouter(AddCustomer);   