const menu = [
  {
    title: 'Dashboard',
    path: '/',
    icon: 'mdi-view-dashboard',
    submenu: []
  },
  {
    title: 'Admins',
    path: '#',
    icon: 'mdi mdi-account-multiple',
    submenu: [{
      title: 'All admins',
      path: '/admins',
    }, {
      title: 'Add admin',
      path: '/add-admin'
    }]
  },
  {
    title: 'Customers',
    path: '#',
    icon: 'mdi mdi-account-multiple',
    submenu: [{
      title: 'All customers',
      path: '/customers',
    }, {
      title: 'Add customer',
      path: '/add-customers'
    }]
  },
  {
    title: 'Merchants',
    path: '#',
    icon: 'mdi mdi-store',
    submenu: [{
      title: 'All merchants',
      path: '/merchants',
    }, {
      title: 'Add merchant',
      path: '/add-merchants'
    }, {
      title: 'Orders',
      path: '/vendor-bookings'
    }, {
      title: 'Payment Transaction',
      path: '/vendor-payment-list'
    }, {
      title: 'Add Payment',
      path: '/add-vendor-payment'
    }]
  },
  {
    title: 'Drivers',
    path: '#',
    icon: 'mdi mdi-car-connected',
    submenu: [{
      title: 'All drivers',
      path: '/drivers',
    }, {
      title: 'Add driver',
      path: '/add-drivers'
    }, {
      title: 'Allocate shipment',
      path: '/allocate-shipment'
    }, {
      title: 'Load Transfer',
      path: '/driver-load-transfer'
    }, {
      title: 'Orders',
      path: '/driver-bookings'
    }, {
      title: 'Payment Transaction',
      path: '/driver-payment-list'
    }, {
      title: 'Add Payment',
      path: '/add-driver-payment'
    }
    ]
  },
  {
    title: 'Orders',
    path: '#',
    icon: 'mdi mdi-cart-outline',
    submenu: [{
      title: 'All orders',
      path: '/orders',
    }, {
      title: 'Pending orders',
      path: '/pending-orders'
    }, {
      title: 'Track shipment',
      path: '/track-shipment'
    }]
  },
  {
    title: 'Timeslots',
    path: '#',
    icon: 'mdi mdi-av-timer',
    submenu: [{
      title: 'Available slots',
      path: '/timeslots',
    }, {
      title: 'Add time slot',
      path: '/add-timeslot'
    }]
  },
  {
    title: 'Country',
    path: '#',
    icon: 'mdi mdi-city',
    submenu: [{
      title: 'All Country',
      path: '/country-list',
    }, {
      title: 'Add Country',
      path: '/add-country'
    }]
  },
  {
    title: 'City',
    path: '#',
    icon: 'mdi mdi-city',
    submenu: [{
      title: 'All City',
      path: '/city-list',
    }, {
      title: 'Add City',
      path: '/add-city'
    }]
  },
  {
    title: 'Transactions',
    path: '/',
    icon: 'mdi-view-dashboard',
    submenu: []
  },
  {
    title: 'Driver commission',
    path: '#',
    icon: 'mdi mdi-currency-usd',
    submenu: [{
      title: 'Commissions',
      path: '/driver-commission',
    }, {
      title: 'Add driver commission',
      path: '/driver-commission/add'
    }]
  },

  {
    title: 'Delivery charges',
    path: '#',
    icon: 'mdi mdi-currency-usd',
    submenu: [{
      title: 'Charges',
      path: '/delivery-charges',
    }, {
      title: 'Add delivery charge',
      path: '/delivery-charges/add'
    }]
  },
  {
    title: 'Task Fee',
    path: '#',
    icon: 'mdi mdi-currency-usd',
    submenu: [{
      title: 'All task fees',
      path: '/taskfee',
    }, {
      title: 'Add task fee',
      path: '/add-taskfee'
    }]
  },
  {
    title: 'Settings',
    path: '/settings',
    icon: 'mdi mdi-settings',
    submenu: []
  },
  {
    title: 'CMS pages',
    path: '#',
    icon: 'mdi-view-dashboard',
    submenu: [{
      title: 'All pages',
      path: '/cms-pages',
    }, {
      title: 'Add page',
      path: '/add-page'
    }]
  },
];

export default menu;
