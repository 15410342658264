import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import customFunctions from '../../../helper/customFunctions';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';


class Timeslots extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countries: [],
      cities: [],
      countryid: '',
      cityid: '',
      slot: {
        starttime: '',
        endtime: ''
      }
    }
  }

  componentDidMount = async () => {
    customFunctions.setTitle('Edit slot');
    const slotId = this.props.match.params.slotId;
    if (!slotId) {
      this.props.history.push('/timeslots');
      return;
    }
    await this.getCountries();
    this.getTimeSlotDetails(slotId);
  }

  getTimeSlotDetails = async (slotId) => {
    const { data } = await AuthApi.getDataFromServer(`${Api.getTimeSlotById}${slotId}`);
    if (data && data.status !== "Fail") {
      const slotData = data.data[0];
      this.getCities(slotData.countryid)
      this.setState({
        slot: slotData,
        countryid: slotData.countryid,
        cityid: slotData.cityid,
      })
    }
  }

  getCountries = async () => {
    const { data } = await AuthApi.getDataFromServer(Api.getCountries);
    if (data && data.status !== "Fail") {
      this.setState({ countries: data.data });
    }
  }

  getCities = async (countryid) => {
    const { data } = await AuthApi.getDataFromServer(`${Api.getCities}/${countryid}`);
    if (data && data.status !== "Fail") {
      this.setState({ cities: data.data });
    }
  }

  validateForm = (e) => {
    e.preventDefault();
    const { cityid, countryid, slot } = this.state;
    let cityErr = '', countryErr = '', count = 0;
    let isValid = true;

    if (cityid.toString().trim() === '') {
      cityErr = 'City is required'; isValid = false;
    }

    if (countryid.toString().trim() === '') {
      countryErr = 'Country is required'; isValid = false;
    }

    if (slot.starttime.toString().trim() === '') {
      slot.startTimeErr = 'This field required';
      count++;
    } else if (!customFunctions.validateNumber(slot.starttime) || Number(slot.starttime) > 24) {
      slot.startTimeErr = 'Invalid time';
      count++;
    } else {
      slot.startTimeErr = '';
    }
    if (slot.endtime.toString().trim() === '') {
      slot.endTimeErr = 'This field required';
      count++;
    } else if (!customFunctions.validateNumber(slot.endtime) || Number(slot.endtime) > 24) {
      slot.endTimeErr = 'Invalid time';
      count++;
    } else {
      slot.endTimeErr = '';
    }


    this.setState({ cityErr, countryErr, slot });
    if (isValid && count === 0) {
      this.updateTimeSlot()
    }
  }

  updateTimeSlot = async () => {
    const { slot, cityid, countryid } = this.state;
    const payload = {
      slotid: slot.id,
      cityid,
      startTime: slot.starttime,
      endTime: slot.endtime,
      isactive: slot.isactive
    }
    const { data, message } = await AuthApi.putDataToServer(Api.updateTimeSlots, payload);
    if (data && data.status !== "Fail") {
      NotificationManager.success('Slot updated', '', 1000);
      setTimeout(() => {
        this.props.history.push('/timeslots');
      }, 1000)
      this.resetData();
    } else {
      NotificationManager.error((data && data.message) || message || 'Please try again later');
    }
  }

  resetData = () => {
    this.setState({
      cityid: '',
      countryid: '',
      cities: [],
      slots: [{
        startTime: '',
        endTime: '',
      }]
    })
  }

  addSlot = () => {
    const { slots } = this.state;
    const slot = {
      startTime: '',
      endTime: ''
    };
    slots.push(slot);
    this.setState({ slots });
  }

  removeSlot = (arrIndex) => {
    const { slots } = this.state;
    slots.splice(1, 1);
    this.setState({ slots });
  }

  render() {
    const { countries, countryid, countryErr, cities, cityid, cityErr, slot } = this.state;
    return (
      <AUX>
        <div className="page-content-wrapper">
          <NotificationContainer />
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <form onSubmit={this.validateForm}>
                      <div className="form-group row">
                        <label for="example-text-input" className="col-sm-2 col-form-label">Country*</label>
                        <div className="col-sm-5">
                          <select className="form-control" value={countryid} onChange={(e) => {
                            this.setState({ countryid: e.target.value, cityid: '' });
                            this.getCities(e.target.value);
                          }}>
                            <option value=''>Select country</option>
                            {
                              countries.map((country) => (
                                <option value={country.countryid}>{country.countryname_eng}</option>
                              ))
                            }
                          </select>
                          <span id="err">{countryErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label for="example-text-input" className="col-sm-2 col-form-label">City*</label>
                        <div className="col-sm-5">
                          <select className="form-control" value={cityid} onChange={(e) => {
                            this.setState({ cityid: e.target.value });
                            // this.getCities(e.target.value);
                          }}>
                            <option value=''>Select city</option>
                            {
                              cities.map((city) => (
                                <option value={city.cityid}>{city.cityname_eng}</option>
                              ))
                            }
                          </select>
                          <span id="err">{cityErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label for="example-text-input" className="col-sm-2 col-form-label">Slots*</label>
                        <div className="col-sm-5">

                          <div className="row">
                            <div className="col-sm-6">
                              <input className="form-control" placeholder="Start time" value={slot.starttime} onChange={(e) => {
                                slot.starttime = e.target.value;
                                this.setState({ slot });
                              }} />
                              <span id="err">{slot.startTimeErr ? slot.startTimeErr : ''}</span>
                            </div>
                            <div className="col-sm-6">
                              <input className="form-control" placeholder="End time" value={slot.endtime} onChange={(e) => {
                                slot.endtime = e.target.value;
                                this.setState({ slot });
                              }} />
                              <span id="err">{slot.endTimeErr ? slot.endTimeErr : ''}</span>
                            </div>
                          </div>

                        </div>
                      </div>
                      <div className="form-group row">
                        <label for="example-text-input" className="col-sm-2 col-form-label">Status*</label>
                        <div className="col-sm-5">
                          <input type="checkbox" id={`switch`} switch="info" checked={slot.isactive ? 'checked' : ''} onChange={() => {
                            slot.isactive = slot.isactive ? 0 : 1
                            this.setState({ slot });
                            // this.changeCustomerStatus(customer);
                          }} />
                          <label for={`switch`} data-on-label="" data-off-label=""></label>
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-sm-3">
                          <button type="submit" className="btn btn-primary waves-effect waves-light">
                            Submit
                        </button>
                          <button type="reset" onClick={() => {
                            this.props.history.push('/timeslots');
                          }} className="btn btn-secondary waves-effect m-l-10">
                            Cancel
                        </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

export default Timeslots;