import React, { Component } from 'react';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import $ from 'jquery';
import cookie from 'react-cookies';
var jwtDecode = require('jwt-decode');

class CustomFunctions extends Component {

  jsonParse = (jsonString) => {
    try {
      return JSON.parse(jsonString);
    } catch (error) {
      return jsonString;
    }
  }

  validateEmail = (email) => {
    var emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailReg.test(email);
  }

  validatePhone = (phone) => {
    var phoneformat = /^\d{10}$/; // eslint-disable-line
    return phone.match(phoneformat);
  }

  validatePhoneNum = (phone) => {
    var phoneformat = /^\+?([0-9]{3,4})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/; // eslint-disable-line
    return phone.match(phoneformat);
  }

  validatePrice = (price) => {
    var priceformat = /^\d{1,8}(\.\d{0,2})?$/g; // eslint-disable-line
    return price.match(priceformat);
  }

  loadScript = async (src) => {
    const ele = document.getElementById(src);
    if (ele) {
      ele.remove();
    }
    var script = document.createElement("script");
    script.id = src;
    script.src = src;
    script.type = "text/javascript";
    script.async = false;
    // script.type = "text/babel";
    // script.type = "text/jsx";
    //  document.getElementsByClassName("wrapper")[0].appendChild(script);
    await document.body.appendChild(script);
  }

  setTitle = (title) => {
    $('#now_routing').empty();
    $('#now_routing').append(title);
  }

  getUserData = async () => {
    try {
      const userdata = await cookie.load('userdata');
      const decodedData = jwtDecode(userdata);
      return decodedData;
    } catch (err) {
      return null;
    }
  }

  validateAmount = (amount) => {
    var amountFormat = /^[1-9]\d{0,8}(((,\d{3}){1})?(\.\d{0,2})?)$/;
    return amountFormat.test(amount)
  }

  validateNumber = (number) => {
    var digitFormat = /^\d{1,9}$/;
    return digitFormat.test(number);
  }

validateFloatNumber = (number) => {
    var digitFormat = /^([0-9]*[.])?[0-9]+$/;
    return digitFormat.test(number);
  }

  getShipmentStatus = (status) => {
    const objStatus = {
      status: '',
      description: ''
    };

    switch (String(status).toLowerCase()) {
      case 'bookingpending':
        objStatus.status = 'Booking pending';
        objStatus.description = 'Booking is pending';
        break;
      case 'bookingdone':
        objStatus.status = 'Booking done';
        objStatus.description = 'Booking is done';
        break;
      case 'ofp':
        objStatus.status = 'Out for pickup';
        objStatus.description = 'Order is out for pickup';
        break;
      case 'ofd':
        objStatus.status = 'Out for delivery';
        objStatus.description = 'Order is out for delivery';
        break;
      case 'delivered':
        objStatus.status = 'Delivered';
        objStatus.description = 'Order delivered';
        break;
      case 'undelivered':
        objStatus.status = 'Undelivered';
        objStatus.description = 'Order not delivered';
        break;

      default:
        objStatus.status = 'Booking pending';
        objStatus.description = 'Booking is pending';
    }

    return objStatus;

  }

  validatePhoneNumber(phoneNumber) {
    /*
    Phone number validation using google-libphonenumber
    */
    let valid = false;
    try {
      const phoneUtil = PhoneNumberUtil.getInstance();
      valid = phoneUtil.isValidNumber(phoneUtil.parse(phoneNumber));
    } catch (e) {
      valid = false;
    }
    return valid;
    // if(valid) {
    //   this.setState({
    //     message:'Phone number '+this.getValidNumber(phoneNumber)+' is valid',
    //     color:'green'
    //   });
    // } else {
    //   this.setState({
    //     message:'Phone number '+phoneNumber+' is not valid',
    //     color:'red'
    //   });
    // }
  }

  getValidNumber(phoneNumber) {
    const phoneUtil = PhoneNumberUtil.getInstance();
    const parsedNumber = phoneUtil.parse(phoneNumber);
    return phoneUtil.format(parsedNumber, PhoneNumberFormat.INTERNATIONAL)
  }

  getFormatedDate(unixDate) {
    if (!unixDate) return '';
    const dt = new Date(Number(unixDate));
    const date = dt.getDate();
    const month = dt.getMonth() + 1;
    const year = dt.getFullYear();
    const hr = dt.getHours();
    const min = dt.getMinutes();
    const sec = dt.getSeconds();
    const strDateTime = `${date}-${month}-${year} ${hr}:${min}:${sec}`;
    return strDateTime;
  }
}

export default new CustomFunctions();
